import { config } from '../../functions/config';
import { ROUTES } from '../routes';
import { getFieldsItem, getFields } from '../../functions/fieldProcessing';

export const FIELDS = {
    "name": {
        key: 'name',
        label: 'Name',
        type: 'text',
        required: true,
        headerRequired: true,
        disabled: false,
        width: '3'
    }
};

export const ITEM = getFieldsItem([
    {
        section_name: "General",
        hideSectionName: true,
        fields: [
            FIELDS.name
        ]
    }]);

export const QUICK_FIELDS = getFields([
    FIELDS.name
]);

export const TITLE_FIELDS = [
    FIELDS.name
];

export const PAGE = {
    'list-url': ROUTES.PROMO_CODE_TYPES,
    'item-url': ROUTES.PROMO_CODE_TYPE,
    'new-url': ROUTES.PROMO_CODE_TYPE_NEW,
    'endpoint': config.promoCodeAPI,
    'item-view': ITEM,
    'quick-fields': QUICK_FIELDS,
    'type': 'promoCodeType',
    'list-page': {
        'action': '/types',
        'title': 'Promo Code Types',
        'showAcitveFlag': true
    },
    'item-page': {
        'expansions': 'promocodes',
        'action': '/types'
    },
    'new-page': {
        'title': 'New Promo Code',
        'action': '/types/create'
    }
};