import { css } from 'lit-element';
    export const htPandoraFormItemHistoryStyles = css `.icon{
  height:2.4rem;
  width:2.4rem; }

.icon .icon--stroke{
  fill:none; }

.icon .icon--fill{
  stroke:none; }

.icon,
.icon circle,
.icon line,
.icon g,
.icon path{
  -webkit-transition:stroke .4s, fill .4s;
  transition:stroke .4s, fill .4s; }

.icon--small{
  height:1.6rem;
  width:1.6rem; }

.icon--medium{
  height:2rem;
  width:2rem; }

.icon--large{
  height:3.2rem;
  width:3.2rem; }

.icon--xlarge{
  height:4.2rem;
  width:4.2rem; }
main{
  display:block; }

.main-content{
  min-height:100vh;
  background:#17171c;
  overflow:visible; }
  .main-content--is-loading{
    visibility:hidden; }

.page{
  display:none;
  height:auto;
  padding:14px 10px; }
  .page__profile{
    padding:0; }

.page[active]{
  display:block; }

button,
svg{
  outline:none; }
  button:active,
  svg:active{
    color:#ffbd00;
    fill:#ffbd00;
    stroke:#ffbd00; }
  @media (min-width: 768px){
    button:focus,
    svg:focus{
      color:#ffbd00;
      fill:#ffbd00;
      stroke:#ffbd00; } }

.floating-wrapper{
  -webkit-box-shadow:0 0 5px 0 rgba(0, 0, 0, 0.5);
          box-shadow:0 0 5px 0 rgba(0, 0, 0, 0.5);
  overflow:auto;
  background-color:#fff;
  position:relative;
  height:100%; }
  .floating-wrapper-form{
    background-color:#fafafa;
    position:relative; }

@media (min-width: 1200px){
  .page{
    padding:20px 30px; }
  .floating-wrapper{
    max-width:calc(100vw - 170px);
    margin-left:-170px; } }
html{
  font-size:62.5%; }

html,
*,
*:before,
*:after{
  -webkit-box-sizing:border-box;
          box-sizing:border-box; }

html{
  -webkit-text-size-adjust:100%;
     -moz-text-size-adjust:100%;
      -ms-text-size-adjust:100%;
          text-size-adjust:100%; }

body{
  font-family:"Hometree", "Arial Black", "sans serif";
  font-size:1.6rem;
  color:#fff;
  font-weight:400;
  text-rendering:optimizelegibility;
  -webkit-font-smoothing:antialiased;
  -moz-font-smoothing:antialiased; }

h1{
  font-size:3.2rem;
  line-height:4rem; }

p{
  font-family:"Hometree", "Arial Black", "sans serif";
  font-size:1.6rem;
  margin-top:1.6rem;
  margin-bottom:0; }

input, p, a, span, li, div, textarea, select{
  font-family:"Hometree", "Arial Black", "sans serif";
  font-size:1.6rem;
  line-height:2.4rem; }
h1,
h2,
h3,
h4,
h5,
h6,
.alpha,
.beta,
.gamma,
.delta,
.epsilon{
  margin:0;
  font-weight:500;
  margin-bottom:0; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small,
  .alpha small,
  .beta small,
  .gamma small,
  .delta small,
  .epsilon small{
    font-weight:normal; }
.text-centre,
.text-center{
  text-align:center; }

.text-left{
  text-align:left; }

.text-right{
  text-align:right; }

.loader{
  position:relative; }
  .loader:after{
    content:'';
    position:absolute;
    top:calc(50% - 2rem);
    left:calc(50% - 2rem);
    border-radius:100%;
    border-style:solid;
    border-width:.3rem;
    width:4rem;
    height:4rem;
    border-color:transparent #ffbd00 #ffbd00 transparent;
    -webkit-animation:spin 1s linear infinite;
            animation:spin 1s linear infinite; }
  .loader--fullscreen{
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background-color:rgba(255, 255, 255, 0.9);
    z-index:2; }

@-webkit-keyframes spin{
  from{
    -webkit-transform:rotate(0deg);
            transform:rotate(0deg); }
  to{
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg); } }

@keyframes spin{
  from{
    -webkit-transform:rotate(0deg);
            transform:rotate(0deg); }
  to{
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg); } }

:host{
  color:#fff; }

:host([mode='active']) .history-label{
  left:-14px;
  top:10px; }

:host([mode='checkbox']) .history-label{
  top:10px;
  left:18px; }

.history{
  margin-top:6px; }
  .history-list{
    list-style:none;
    padding:0;
    margin:0;
    display:none;
    background-color:#363b44; }
  .history-item{
    margin-bottom:12px;
    border-bottom:1px solid #dbdbdb;
    padding:12px 20px; }
    .history-item:last-of-type{
      border:none; }
    .history-item-current{
      background-color:#ffbd00;
      color:#363b44; }
  .history-header{
    display:-webkit-box;
    display:-webkit-flex;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:justify;
    -webkit-justify-content:space-between;
        -ms-flex-pack:justify;
            justify-content:space-between; }
  .history-author, .history-time{
    margin:0;
    font-size:1.4rem; }
  .history-time{
    font-weight:600; }
  .history-value{
    margin:0;
    font-size:1.6rem; }
  .history-checkbox{
    display:none; }
    .history-checkbox:checked ~ .history-list{
      display:block; }
    .history-checkbox:checked + .history-label .icon{
      fill:#ffbd00; }
  .history-label{
    position:absolute;
    cursor:pointer;
    top:0;
    left:5px; }
    .history-label .icon{
      fill:#b3b3b3;
      width:18px; }
`;
