export const FIELDS = [
    {
        key: 'status',
        label: 'Status',
        type: 'text'
    },
    {
        key: 'amount',
        label: 'Amount',
        type: 'price'
    },
    {
        key: 'interval_unit',
        label: 'Interval Unit',
        type: 'text'
    },
    {
        key: 'start_date',
        label: 'Start Date',
        type: 'date'
    }
];