import { LitElement, css, html } from 'lit-element';

import Cookies from 'js-cookie';
import { ROUTES } from '../constants/routes';
import { getClaimsLoginPageUrl } from '../functions/changePage';
import { getCookieDomain } from '../functions/getCookieDomain';
import { exportIcon } from './htPandoraIcons';
import { htPandoraNavigationStyles } from './styles/ht-pandora-navigation-styles';

class htPandoraNavigation extends LitElement {
    static get properties() {
        return {
            page: { type: String },
            loggedIn: { type: Boolean },
            open: { type: Boolean },
            _links: { type: Array },
        };
    }

    constructor() {
        super();

        this.open = false;
        this._links = [
            {
                name: 'Search',
                link: ROUTES.SEARCH,
                active: [ROUTES.SEARCH],
                newLink: null,
                loggedInOnly: true,
                icon: 'search',
                subLinks: null,
            },
            {
                name: 'Accounts',
                link: ROUTES.ACCOUNTS,
                active: [ROUTES.ACCOUNTS, ROUTES.ACCOUNT, ROUTES.ACCOUNT_NEW],
                newLink: ROUTES.ACCOUNT_NEW,
                loggedInOnly: true,
                icon: 'account',
                subLinks: [
                    {
                        name: 'Referrals',
                        link: ROUTES.REFERRALS,
                        active: [ROUTES.REFERRALS, ROUTES.REFERRAL, ROUTES.REFERRAL_NEW],
                        newLink: ROUTES.REFERRAL_NEW,
                    },
                ],
            },
            {
                name: 'Properties',
                link: ROUTES.PROPERTIES,
                active: [ROUTES.PROPERTIES, ROUTES.PROPERTY, ROUTES.PROPERTY_NEW],
                newLink: ROUTES.PROPERTY_NEW,
                loggedInOnly: true,
                icon: 'property',
                subLinks: null,
            },
            {
                name: 'Contracts',
                link: ROUTES.CONTRACTS,
                active: [ROUTES.CONTRACTS, ROUTES.CONTRACT, ROUTES.CONTRACT_NEW],
                newLink: ROUTES.CONTRACT_NEW,
                loggedInOnly: true,
                icon: 'contract',
                subLinks: null,
                subLinks: [
                    {
                        name: 'Renewals',
                        link: ROUTES.RENEWALS,
                        active: [ROUTES.RENEWALS, ROUTES.RENEWAL, ROUTES.RENEWAL_NEW],
                        newLink: ROUTES.RENEWAL_NEW,
                    },
                ],
            },
            {
                name: 'Analytics',
                link: ROUTES.ANALYTICS,
                active: [ROUTES.ANALYTICS],
                newLink: null,
                loggedInOnly: true,
                icon: 'analytics',
                subLinks: null,
            },
            {
                name: 'Notes',
                link: ROUTES.NOTES,
                active: [ROUTES.NOTES, ROUTES.NOTE, ROUTES.NOTE_NEW],
                newLink: ROUTES.NOTE_NEW,
                loggedInOnly: true,
                icon: 'note',
                subLinks: null,
            },
            {
                name: 'Packages',
                link: ROUTES.PRODUCT_PACKAGES,
                active: [
                    ROUTES.PRODUCT_PACKAGES,
                    ROUTES.PRODUCT_PACKAGE,
                    ROUTES.PRODUCT_PACKAGE_NEW,
                ],
                newLink: ROUTES.PRODUCT_PACKAGE_NEW,
                loggedInOnly: true,
                icon: 'product',
                subLinks: [
                    {
                        name: 'Products',
                        link: ROUTES.PRODUCTS,
                        active: [ROUTES.PRODUCTS, ROUTES.PRODUCT, ROUTES.PRODUCT_NEW],
                        newLink: ROUTES.PRODUCT_NEW,
                    },
                ],
            },
            {
                name: 'Pricebooks',
                link: ROUTES.PRICEBOOKS,
                active: [ROUTES.PRICEBOOKS, ROUTES.PRICEBOOK, ROUTES.PRICEBOOK_NEW],
                newLink: ROUTES.PRICEBOOK_NEW,
                loggedInOnly: true,
                icon: 'pricebook',
            },
            {
                name: 'Promo Codes',
                link: ROUTES.PROMO_CODES,
                active: [ROUTES.PROMO_CODES, ROUTES.PROMO_CODE, ROUTES.PROMO_CODE_NEW],
                newLink: ROUTES.PROMO_CODE_NEW,
                loggedInOnly: true,
                icon: 'promo_code',
                subLinks: [
                    {
                        name: 'Families',
                        link: ROUTES.PROMO_CODE_FAMILIES,
                        active: [
                            ROUTES.PROMO_CODE_FAMILIES,
                            ROUTES.PROMO_CODE_FAMILY,
                            ROUTES.PROMO_CODE_FAMILY_NEW,
                        ],
                        newLink: ROUTES.PROMO_CODE_FAMILY_NEW,
                    },
                    {
                        name: 'Types',
                        link: ROUTES.PROMO_CODE_TYPES,
                        active: [
                            ROUTES.PROMO_CODE_TYPES,
                            ROUTES.PROMO_CODE_TYPE,
                            ROUTES.PROMO_CODE_TYPE_NEW,
                        ],
                        newLink: ROUTES.PROMO_CODE_TYPE_NEW,
                    },
                    {
                        name: 'Products',
                        link: ROUTES.PRODUCT_TO_PROMOCODES,
                        active: [
                            ROUTES.PRODUCT_TO_PROMOCODES,
                            ROUTES.PRODUCT_TO_PROMOCODE,
                            ROUTES.PRODUCT_TO_PROMOCODE_NEW,
                        ],
                        newLink: ROUTES.PRODUCT_TO_PROMOCODE_NEW,
                        hide: true,
                    },
                    {
                        name: 'Packages',
                        link: ROUTES.PACKAGE_TO_PROMOCODES,
                        active: [
                            ROUTES.PACKAGE_TO_PROMOCODES,
                            ROUTES.PACKAGE_TO_PROMOCODE,
                            ROUTES.PACKAGE_TO_PROMOCODE_NEW,
                        ],
                        newLink: ROUTES.PACKAGE_TO_PROMOCODE_NEW,
                        hide: true,
                    },
                ],
            },
            {
                name: 'Controller',
                link: ROUTES.CONTROLLER,
                active: [ROUTES.CONTROLLER],
                newLink: null,
                loggedInOnly: true,
                icon: 'controller',
                subLinks: null,
            },
        ];
    }

    _logOut() {
        const domain = getCookieDomain(process.env.ENVIRONMENT);
        Cookies.set('isLoggingOut', true, { domain });
        const claimsLoginUrl = getClaimsLoginPageUrl();
        window.location.assign(claimsLoginUrl);
    }

    _renderLink(linkObject) {
        let activeUrls = linkObject.active;
        if (linkObject.subLinks) {
            linkObject.subLinks.forEach((subLink) => {
                activeUrls = activeUrls.concat(subLink.active);
            });
        }

        if (linkObject.hide) return;

        let navLink = linkObject.link;

        if (linkObject.link === ROUTES.PRICEBOOKS) {
            navLink = `https://claims.${window.location.hostname}/${ROUTES.PRICEBOOKS}`;

            if (process.env.ENVIRONMENT === 'local') {
                navLink = `http://localhost:3000/${ROUTES.PRICEBOOKS}`;
            }
        }

        if (linkObject.link === ROUTES.CONTRACTS) {
            navLink = `https://claims.${window.location.hostname}/${ROUTES.CONTRACTS}`;

            if (process.env.ENVIRONMENT === 'local') {
                navLink = `http://localhost:3000/${ROUTES.CONTRACTS}`;
            }
        }

        return html`
            <li
                class="navigation__item ${linkObject.subLinks
                    ? 'navigation__item--has-sublinks'
                    : ''} ${activeUrls.includes(this.page) ? 'active' : ''}"
                @click="${() => (this.open ? (this.open = !this.open) : null)}"
            >
                <a class="navigation__link" href="${navLink}">
                    ${linkObject.icon ? exportIcon(linkObject.icon) : ''}
                    <p>${linkObject.name}</p>
                </a>
                ${linkObject.newLink
                    ? html`
                          <a class="navigation__link__plus" href="${linkObject.newLink}">
                              ${exportIcon('plus')}
                          </a>
                      `
                    : ''}
                ${linkObject.subLinks
                    ? html`
                          <ul class="navigation__list">
                              ${linkObject.subLinks.map((subLinkObject) =>
                                  this._renderLink(subLinkObject)
                              )}
                          </ul>
                      `
                    : ''}
            </li>
        `;
    }

    static get styles() {
        return css`
            ${htPandoraNavigationStyles}
        `;
    }

    render() {
        return html`
            <nav class="navigation ${this.open ? 'open' : ''}">
                <div class="navigation-toggle" @click="${() => (this.open = !this.open)}">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div
                    class="navigation__logo"
                    @click="${() => (this.open ? (this.open = !this.open) : null)}"
                >
                    <a
                        class="navigation__link navigation__link--home ${this.page === ROUTES.HOME
                            ? 'active'
                            : ''}"
                        href="${ROUTES.HOME}"
                    >
                        <p>Pandora</p>
                    </a>
                </div>
                <ul class="navigation__list scroll-none">
                    ${this.loggedIn
                        ? this._links.map((linkObject) => this._renderLink(linkObject))
                        : ''}
                    <li
                        class="navigation__item ${this.page === ROUTES.LOGIN ? 'active' : ''}"
                        @click="${() => (this.open ? (this.open = !this.open) : null)}"
                    >
                        <button class="navigation__link" @click="${() => this._logOut()}">
                            ${exportIcon('lock')}
                            <p>${this.loggedIn ? 'Logout' : 'Login'}</p>
                        </button>
                    </li>
                </ul>
                <div class="nav-version">Version: <i>Tulkun 1.0.1</i></div>
            </nav>
        `;
    }
}

window.customElements.define('ht-pandora-navigation', htPandoraNavigation);
