import { config } from '../../functions/config';
import { ROUTES } from '../routes';
import { getFieldsItem, getFields } from '../../functions/fieldProcessing';

export const FIELDS = {
    "product_id": {
        key: 'product_id',
        label: 'Product',
        type: 'product_id',
        required: true,
        disabled: false,
        width: '12',
        linkedItem: 'product',
        linkedItemFieldsReference: 'PRODUCT',
        linkUrl: ROUTES.PRODUCT
    },
    "package_id": {
        key: 'package_id',
        label: 'Package',
        type: 'package',
        required: true,
        disabled: false,
        width: '12',
        linkedItem: 'package',
        linkedItemFieldsReference: 'PRODUCT_PACKAGE',
        linkUrl: ROUTES.PRODUCT_PACKAGE
    },
    "position": {
        key: 'position',
        label: 'Position',
        type: 'number',
        required: true,
        disabled: false,
        width: '4'
    }
};

export const ITEM = getFieldsItem([
    {
        section_name: "General",
        hideSectionName: true,
        fields: [
            FIELDS.product_id,
            FIELDS.package_id,
            FIELDS.position
        ]
    }
]);

export const RELATION = [
    {
        link: true,
        linkUrl: ROUTES.PRODUCT_TO_PACKAGE,
        key: 'product_to_package_id',
        label: 'Relation',
        icon: 'link',
        type: 'text',
        headerRequired: true
    }
];

export const QUICK_FIELDS = getFields([
    FIELDS.product_id,
    FIELDS.package_id,
    FIELDS.position
]);

export const TITLE_FIELDS = [];

export const PAGE = {
    'item-url': ROUTES.PRODUCT_TO_PACKAGE,
    'new-url': ROUTES.PRODUCT_TO_PACKAGE_NEW,
    'endpoint': config.productAPI,
    'item-view': ITEM,
    'quick-fields': QUICK_FIELDS,
    'type': 'product_to_package',
    'item-page': {
        'action': '/packages/attachments',
        'expansions': 'package,product'
    },
    'new-page': {
        'title': 'New Product to Package',
        'action': '/packages/attachments/create'
    }
};