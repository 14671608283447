export const FIELDS = [
    {
        key: 'id',
        label: 'Id',
        type: 'text'
    },
    {
        key: 'status',
        label: 'Status',
        type: 'text'
    },
    {
        key: 'amount',
        label: 'Amount',
        type: 'price'
    },
    {
        key: 'amount_refunded',
        label: 'Amount Refunded',
        type: 'price'
    },
    {
        key: 'charge_date',
        label: 'Charge Date',
        type: 'date'
    },
    {
        key: 'created_at',
        label: 'Created Date',
        type: 'date'
    }
];