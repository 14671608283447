import { LitElement, html, css } from 'lit-element'

// These are the shared styles needed by this element.
import { htPandoraEditableStyles } from './styles/ht-pandora-editable-styles'
import { config } from '../functions/config'
import { postDataFactory } from '../functions/postDataFactory'

import './htPandoraFormWrapper'

class htPandoraEditableRenewalReinstate extends LitElement {
    static get properties() {
        return {
            renewal: { type: Object },
        }
    }

    constructor() {
        super()
        this.postData = postDataFactory({
            fetch: window.fetch,
            apiEndPoint: `${config.renewalAPI}`,
        })
    }

    _submitRenewalEdit() {
        const sendUpdate = new CustomEvent('send-update', {
            detail: {
                changes: {
                    id: this.renewal.id,
                    active: 1,
                    status: 1,
                    contract_id: this.renewal.contract_id,
                    account_id: this.renewal.contract.account_id,
                },
                table: 'renewal',
                post: this.postData,
                element: this,
            },
        })

        this.dispatchEvent(sendUpdate)
    }

    shouldUpdate() {
        if (this.renewal !== null) return true
        return false
    }

    _cancelEdits() {
        this.shadowRoot.querySelector('ht-pandora-form-wrapper').setAttribute('formdata', '{}')
    }

    _closeReinstate() {
        const closeEdit = new CustomEvent('close-edit', {
            detail: {
                element: this,
            },
        })
        this.dispatchEvent(closeEdit)
    }

    static get styles() {
        return css`
            ${htPandoraEditableStyles}
        `
    }

    render() {
        return html`
            <div class="form-section-title">
                <h2>Reinstating renewal: ${this.renewal.id}</h2>
            </div>
            <p style="color:black">
                Are you sure you wish to reinstate this renewal? This action will have the following
                effects:
            </p>
            <ul style="color:black">
                <li>Set this renewal's status to 'Pending'</li>
                <li>Set this renewal's contract to 'Will renew automatically'</li>
                <li>Set this renewal's contract's account to 'Active'</li>
            </ul>
            <br />
            <form @submit="${(e) => e.preventDefault()}">
                <ht-pandora-form-wrapper
                    @form-complete="${true}"
                    @form-data="${{}}"
                    fields=""
                    item=""
                ></ht-pandora-form-wrapper>
                <div class="buttons">
                    <button
                        class="ht-button ht-button-secondary"
                        type="button"
                        @click="${() => this._closeReinstate()}"
                    >
                        Back
                    </button>
                    <button
                        class="ht-button"
                        type="button"
                        @click="${() => this._submitRenewalEdit()}"
                    >
                        Reinstate
                    </button>
                </div>
            </form>
        `
    }
}

window.customElements.define(
    'ht-pandora-editable-renewal-reinstate',
    htPandoraEditableRenewalReinstate
)
