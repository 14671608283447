import { config } from '../../functions/config';
import { ROUTES } from '../routes';
import { getFieldsItem, getFields } from '../../functions/fieldProcessing';

export const FIELDS = {
    "product_id": {
        key: 'product_id',
        label: 'Product',
        type: 'product_id',
        required: true,
        disabled: false,
        width: '12',
        linkedItem: 'product',
        linkedItemFieldsReference: 'PRODUCT',
        linkUrl: ROUTES.PRODUCT
    },
    "promocode_id": {
        key: 'promocode_id',
        label: 'Promocode',
        type: 'promo_code_id',
        required: true,
        disabled: false,
        width: '12',
        linkedItem: 'promocode',
        linkedItemFieldsReference: 'PROMO_CODE',
        linkUrl: ROUTES.PROMO_CODE
    }
};

export const ITEM = getFieldsItem([
    {
        section_name: "General",
        hideSectionName: true,
        fields: [
            FIELDS.product_id,
            FIELDS.promocode_id
        ]
    }]);

export const QUICK_FIELDS = getFields([
    FIELDS.product_id,
    FIELDS.promocode_id
]);

export const TITLE_FIELDS = [];

export const PAGE = {
    'list-url': ROUTES.PRODUCT_TO_PROMOCODES,
    'item-url': ROUTES.PRODUCT_TO_PROMOCODE,
    'new-url': ROUTES.PRODUCT_TO_PROMOCODE_NEW,
    'endpoint': config.productAPI,
    'item-view': ITEM,
    'quick-fields': QUICK_FIELDS,
    'type': 'product_to_promocode',
    'list-page': {
        'action': '/promocodes/attachments',
        'title': 'Promocode Products',
        'showAcitveFlag': true
    },
    'item-page': {
        'action': '/promocodes/attachments',
        'expansions': 'promocode,product'
    },
    'new-page': {
        'title': 'New Product to Package',
        'action': '/promocodes/attachments/create'
    }
};