export const sound = (name) => {
    if(localStorage.getItem('sound') && localStorage.getItem('sound') === 'mario') {
        switch (name) {
            case 'powerup':
                playSound('dist/sounds/smb_powerup.wav')
                break;
            case 'gameover':
                playSound('dist/sounds/smb_gameover.wav')
                break;
            case 'plus':
                playSound('dist/sounds/smb_coin.wav')
                break;
            default:
                break;
        }
    }
};

const playSound = (file) => {
    if(localStorage.getItem('sound'))
    var soundItem = new Audio(file);
    soundItem.play();
};