export const FIELDS = [
    {
        key: 'given_name',
        label: 'First Name',
        type: 'text'
    },
    {
        key: 'family_name',
        label: 'Last Name',
        type: 'text'
    },
    {
        key: 'address_line1',
        label: 'Address Line 1',
        type: 'text'
    },
    {
        key: 'city',
        label: 'City',
        type: 'text'
    },
    {
        key: 'postal_code',
        label: 'Postal Code',
        type: 'text'
    },
    {
        key: 'email',
        label: 'Email',
        type: 'text'
    },
    {
        key: 'phone_number',
        label: 'Phone',
        type: 'text'
    }
];