import { html, css, LitElement } from 'lit-element';

// These are the shared styles needed by this element.
import { htPandoraQuickViewStyles } from './styles/ht-pandora-quick-view-styles';


class htPandoraQuickView extends LitElement {
    static get properties() {
        return {
            fields: { type: Array },
            title: { type: String},
            valueObject: { type: Object }
        };
    }

    constructor() {
        super();
        this.fields = [];
        this.valueObject = {};
    }

    static get styles() {
        return css`${htPandoraQuickViewStyles}`;
    }

    render() {
        return html`
            ${this.title && this.title !== '' ? html`<h2>${this.title}</h2>` : ``}
            ${this.fields.map(item => {
                if(this.valueObject[item.key] === null || typeof this.valueObject[item.key] === "undefined") return;
                return html`
                    <div class="item">
                        <p class="item-title">${item.label}</p>
                        <p class="item-value">${this.valueObject[item.key]}</p>
                    </div>
                `;
            })}
        `;
    }
}

window.customElements.define('ht-pandora-quick-view', htPandoraQuickView);
