export const setURLParameterByName = (key, value) => {
    const uri = window.location.href;
    const re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    const separator = uri.indexOf('?') !== -1 ? "&" : "?";
    let newURL = uri;
    if (uri.match(re)) {
        newURL = uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
        newURL = uri + separator + key + "=" + value;
    }

    window.history.replaceState({ path: newURL }, '', newURL);
};