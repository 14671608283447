import { css } from 'lit-element';
    export const htPandoraTableListStyles = css `.icon{
  height:2.4rem;
  width:2.4rem; }

.icon .icon--stroke{
  fill:none; }

.icon .icon--fill{
  stroke:none; }

.icon,
.icon circle,
.icon line,
.icon g,
.icon path{
  -webkit-transition:stroke .4s, fill .4s;
  transition:stroke .4s, fill .4s; }

.icon--small{
  height:1.6rem;
  width:1.6rem; }

.icon--medium{
  height:2rem;
  width:2rem; }

.icon--large{
  height:3.2rem;
  width:3.2rem; }

.icon--xlarge{
  height:4.2rem;
  width:4.2rem; }
main{
  display:block; }

.main-content{
  min-height:100vh;
  background:#17171c;
  overflow:visible; }
  .main-content--is-loading{
    visibility:hidden; }

.page{
  display:none;
  height:auto;
  padding:14px 10px; }
  .page__profile{
    padding:0; }

.page[active]{
  display:block; }

button,
svg{
  outline:none; }
  button:active,
  svg:active{
    color:#ffbd00;
    fill:#ffbd00;
    stroke:#ffbd00; }
  @media (min-width: 768px){
    button:focus,
    svg:focus{
      color:#ffbd00;
      fill:#ffbd00;
      stroke:#ffbd00; } }

.floating-wrapper{
  -webkit-box-shadow:0 0 5px 0 rgba(0, 0, 0, 0.5);
          box-shadow:0 0 5px 0 rgba(0, 0, 0, 0.5);
  overflow:auto;
  background-color:#fff;
  position:relative;
  height:100%; }
  .floating-wrapper-form{
    background-color:#fafafa;
    position:relative; }

@media (min-width: 1200px){
  .page{
    padding:20px 30px; }
  .floating-wrapper{
    max-width:calc(100vw - 170px);
    margin-left:-170px; } }
html{
  font-size:62.5%; }

html,
*,
*:before,
*:after{
  -webkit-box-sizing:border-box;
          box-sizing:border-box; }

html{
  -webkit-text-size-adjust:100%;
     -moz-text-size-adjust:100%;
      -ms-text-size-adjust:100%;
          text-size-adjust:100%; }

body{
  font-family:"Hometree", "Arial Black", "sans serif";
  font-size:1.6rem;
  color:#fff;
  font-weight:400;
  text-rendering:optimizelegibility;
  -webkit-font-smoothing:antialiased;
  -moz-font-smoothing:antialiased; }

h1{
  font-size:3.2rem;
  line-height:4rem; }

p{
  font-family:"Hometree", "Arial Black", "sans serif";
  font-size:1.6rem;
  margin-top:1.6rem;
  margin-bottom:0; }

input, p, a, span, li, div, textarea, select{
  font-family:"Hometree", "Arial Black", "sans serif";
  font-size:1.6rem;
  line-height:2.4rem; }
h1,
h2,
h3,
h4,
h5,
h6,
.alpha,
.beta,
.gamma,
.delta,
.epsilon{
  margin:0;
  font-weight:500;
  margin-bottom:0; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small,
  .alpha small,
  .beta small,
  .gamma small,
  .delta small,
  .epsilon small{
    font-weight:normal; }
.text-centre,
.text-center{
  text-align:center; }

.text-left{
  text-align:left; }

.text-right{
  text-align:right; }

.loader{
  position:relative; }
  .loader:after{
    content:'';
    position:absolute;
    top:calc(50% - 2rem);
    left:calc(50% - 2rem);
    border-radius:100%;
    border-style:solid;
    border-width:.3rem;
    width:4rem;
    height:4rem;
    border-color:transparent #ffbd00 #ffbd00 transparent;
    -webkit-animation:spin 1s linear infinite;
            animation:spin 1s linear infinite; }
  .loader--fullscreen{
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background-color:rgba(255, 255, 255, 0.9);
    z-index:2; }

@-webkit-keyframes spin{
  from{
    -webkit-transform:rotate(0deg);
            transform:rotate(0deg); }
  to{
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg); } }

@keyframes spin{
  from{
    -webkit-transform:rotate(0deg);
            transform:rotate(0deg); }
  to{
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg); } }

:host{
  margin:0;
  display:block;
  overflow:auto; }

:host([slimheaders]) .table-header{
  padding:12px;
  font-size:1.4rem; }
  :host([slimheaders]) .table-header:hover{
    background-color:transparent; }

:host([readonly]) .table-row:hover{
  background-color:transparent;
  cursor:default; }

:host([readonly]) .table-header:hover{
  background:#f7f7f8;
  cursor:default; }

:host([islistselector]) .table-row:hover{
  background-color:#ffbd00; }

table{
  width:100%;
  text-align:left;
  border-collapse:collapse;
  background-color:#fff; }

.table-wrapper{
  overflow:auto;
  display:block;
  max-height:calc(100vh - 115px); }

.pagination + .table-wrapper{
  max-height:calc(100vh - 160px); }

h2{
  margin-bottom:10px; }

.table-header,
td{
  white-space:nowrap;
  padding:0;
  margin:0;
  border-top:1px solid #dbdbdb;
  color:#444;
  max-width:300px;
  overflow:auto; }
  .table-header:last-of-type,
  td:last-of-type{
    border-right:1px solid #dbdbdb; }
  .table-header:first-of-type,
  td:first-of-type{
    border-left:1px solid #dbdbdb; }
  .table-header .icon,
  td .icon{
    stroke:#363b44;
    margin:auto;
    display:block; }
  .table-header .icon--cross,
  td .icon--cross{
    stroke:red; }
  .table-header .icon--tick,
  td .icon--tick{
    stroke:green; }
  .table-header p,
  .table-header a,
  td p,
  td a{
    margin:0;
    display:block;
    padding:13px 10px;
    overflow:auto; }
  .table-header .has-link,
  td .has-link{
    text-decoration:underline; }
    .table-header .has-link:hover,
    td .has-link:hover{
      background-color:#dbdbdb; }

td{
  font-size:1.5rem;
  line-height:2rem;
  letter-spacing:.3px; }

tr:last-of-type td{
  border-bottom:1px solid #dbdbdb; }

.table-header{
  background:#f7f7f8;
  cursor:pointer;
  padding:27px 20px 27px 10px;
  -webkit-transition:background-color .4s;
  transition:background-color .4s;
  font-size:1.8rem;
  line-height:2.4rem;
  letter-spacing:.36px;
  font-weight:500;
  position:-webkit-sticky;
  position:sticky;
  top:-1px; }
  .table-header:hover{
    background-color:#dbdbdb; }
  .table-header .icon{
    position:absolute;
    top:68%;
    left:12px;
    width:16px;
    height:16px;
    stroke:#b73490;
    display:none;
    -webkit-transform:rotate(0);
            transform:rotate(0); }
  .table-header.ordering-active{
    padding:27px 30px 27px 10px;
    font-weight:800; }
    .table-header.ordering-active .icon{
      display:block; }
  .table-header.ordering-ascending .icon{
    -webkit-transform:rotate(180deg);
            transform:rotate(180deg); }
  .table-header.active{
    color:#b73490; }
    .table-header.active .icon{
      display:block; }

.table-top{
  display:-webkit-box;
  display:-webkit-flex;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:justify;
  -webkit-justify-content:space-between;
      -ms-flex-pack:justify;
          justify-content:space-between;
  padding:10px 0 19px; }
  .table-top-right{
    display:-webkit-box;
    display:-webkit-flex;
    display:-ms-flexbox;
    display:flex; }
  .table-top-left{
    display:-webkit-box;
    display:-webkit-flex;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -webkit-align-items:center;
        -ms-flex-align:center;
            align-items:center; }

.icon{
  stroke:#fff;
  fill:#fff; }

.table-top-item{
  display:-webkit-box;
  display:-webkit-flex;
  display:-ms-flexbox;
  display:flex;
  margin:auto 20px;
  cursor:pointer; }

.highlight{
  background-color:#ffbd00; }

.not-active{
  background-color:#dbdbdb;
  opacity:.4; }

.table-row{
  cursor:pointer; }
  .table-row:hover{
    background-color:#ededed; }
  .table-row.selected{
    background-color:#ffad00; }

.pagination{
  background-color:#17171c;
  display:-webkit-box;
  display:-webkit-flex;
  display:-ms-flexbox;
  display:flex; }
  .pagination-page{
    min-width:42px;
    text-align:center;
    cursor:pointer;
    margin:0;
    padding:10px;
    background-color:#4270aa;
    border-right:1px solid #3b6498;
    color:#fff;
    -webkit-transition:background .4s;
    transition:background .4s; }
    .pagination-page:last-of-type{
      border-right:none; }
    .pagination-page:nth-child(2){
      background-color:#345886; }
    .pagination-page:nth-last-child(2){
      background-color:#345886; }
    .pagination-page:hover{
      background-color:#3b6498; }
    .pagination-page.active{
      background-color:#ffbd00; }
    .pagination-page[disabled]{
      -webkit-filter:brightness(0.5);
              filter:brightness(0.5); }
  .pagination .count{
    margin:0 20px; }
`;
