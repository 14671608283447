import { getFieldsItem, getFields } from '../../functions/fieldProcessing'

const hiddenFields = ['author', 'date_modified']

export const FIELDS = {
    pmsid: {
        key: 'pmsid',
        label: 'PMS ID',
        type: 'number',
        required: false,
        disabled: false,
        width: '3',
    },
    pms_trade: {
        key: 'pms_trade',
        label: 'PMS Trade',
        type: 'text',
        required: false,
        disabled: false,
        width: '3',
    },
    job_type: {
        key: 'job_type',
        label: 'Job Type',
        type: 'text',
        required: false,
        disabled: false,
        width: '3',
    },
    claim_start_date: {
        key: 'claim_start_date',
        label: 'Claim Start Date',
        type: 'date',
        required: false,
        disabled: false,
        width: '3',
    },
    date_created: {
        key: 'date_created',
        label: 'Created Date',
        type: 'date',
        required: false,
        disabled: false,
        width: '3',
    },
    charge_final: {
        key: 'charge_final',
        label: 'Cost to Hometree',
        type: 'price',
        required: false,
        disabled: false,
        width: '3',
    },
    summary: {
        key: 'summary',
        label: 'Summary',
        type: 'text',
        required: false,
        disabled: false,
        width: '3',
    },
}

// Further fields that can be added:

// "ht_claim": "null",
// "charge_band": "Normal",
// "charge_vat": 1500,
// "charge_total": 7500,
// "date_created": "2020-02-14 17:27:38.000000",
// "quote_income": 0,
// "charge_labour": 7500,
// "hours_charged": "",
// "supplement_m25": 0,
// "charge_handling": 0,
// "charge_material": 0,
// "ht_repair_visit": "Repair Visit",
// "ht_service_visit": "null",
// "payment_due_date": "0000-00-00 00:00:00.000000",
// "total_cost_of_claim": 7500,
// "charge_fixed_fee_asv": 0,
// "charge_fixed_fee_isv": 0,
// "customer_contribution": 0,
// "additional_fire_checks": 0,
// "additional_quoted_work": 0,
// "asv_breakdown_combined": 0,
// "supplement_m25_holiday": 0,
// "claim_asv_isv_validated": 0,
// "charge_fixed_fee_abortcall": 0,
// "contribution_fee_collected": "NA",
// "additional_cooker_hob_check": 0,
// "charge_fixed_fee_land_check": null,
// "money_collected_contribution": 0,
// "charge_fixed_fee_asv_breakdown": 0,
// "additional_landlord_appliance_check": 0

export const QUICK_FIELDS = getFields(
    Object.keys(FIELDS).map((fieldName) => FIELDS[fieldName]),
    false
).filter((field) => !hiddenFields.includes(field.key))

export const TITLE_FIELDS = []
