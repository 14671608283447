export const parseJwtToken = (token) => {
    if (!token) {
        return null;
    }

    const tokenSplit = token.split('.');
    if (tokenSplit.length <= 1) {
        return null;
    }

    const base64Url = tokenSplit[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

    return JSON.parse(window.atob(base64));
};
