const PANDORA_API_BASE_URL = process.env.PANDORA_API_BASE_URL
const CHECKOUT_API_BASE_URL = process.env.CHECKOUT_API_BASE_URL

export const config = {
    gcMandateURL: process.env.GC_MANDATE_URL,
    gcSubscriptionURL: process.env.GC_SUBSCRIPTION_URL,
    gcCustomerURL: process.env.GC_CUSTOMER_URL,
    gcPaymentURL: process.env.GC_PAYMENT_URL,
    cbSubscriptionURL: process.env.CB_SUBSCRIPTION_URL,
    cbCustomerURL: process.env.CB_CUSTOMER_URL,
    pmsPolicyURL: process.env.PMS_POLICY_URL,
    pmsJobURL: process.env.PMS_JOB_URL,
    pmsListURL: process.env.PMS_LIST_URL,
    //format is BASE_URL / CUSTOM_DOMAIN_BASE_PATH / LAMBDA_HANDLER_PATH
    productAPI: `${PANDORA_API_BASE_URL}/products/products`,
    packageAPI: `${PANDORA_API_BASE_URL}/packages/packages`,
    propertyAPI: `${PANDORA_API_BASE_URL}/properties/properties`,
    accountAPI: `${PANDORA_API_BASE_URL}/accounts/accounts`,
    contractAPI: `${PANDORA_API_BASE_URL}/contracts/contracts`,
    renewalAPI: `${PANDORA_API_BASE_URL}/renewals/renewals`,
    searchAPI: `${PANDORA_API_BASE_URL}/search/search`,
    priceBookAPI: `${PANDORA_API_BASE_URL}/pricebooks/model/pricebooks`,
    promoCodeAPI: `${CHECKOUT_API_BASE_URL}/promocodes-public/promoCode`,
    controllerAPI: `${PANDORA_API_BASE_URL}/controller/controller`,
    awsCognitoUserPoolId: process.env.AWS_COGNITO_USER_POOL_ID,
    awsCognitoClientId: process.env.AWS_COGNITO_CLIENT_ID,
    awsCognitoRegion: process.env.AWS_COGNITO_REGION,
    salesforceURL: process.env.SALESFORCE_URL,
    apiEndpoints: process.env.API_ENDPOINTS,
    environment: process.env.ENVIRONMENT,
}
