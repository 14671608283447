import { LitElement, css, html } from 'lit-element';

/* Styles */
import { connect } from 'pwa-helpers';
import { htPandoraFormItemStyles } from './styles/ht-pandora-form-item-styles';

/* Functions */
import { FIELDS, QUICK_FIELDS } from '../constants/fields';
import { ROUTES } from '../constants/routes';
import { changePageToReact } from '../functions/changePage';
import { config } from '../functions/config';
import { formatDate } from '../functions/formatDate';
import { exportIcon } from './htPandoraIcons';

import { getOptionField } from '../actions/app';
import { store } from '../store';

import './htPandoraFormItemHistory';
import './htPandoraFormSearchItem';
import './htPandoraLoading';
import './htPandoraQuickView';
import './htPandoraTableList';

class htPandoraFormItem extends connect(store)(LitElement) {
    static get properties() {
        return {
            label: { type: String },
            checkedLabel: { type: String },
            uncheckedLabel: { type: String },
            type: { type: String },
            name: { type: String },
            fieldId: { type: String },
            required: { type: Boolean },
            pattern: { type: String },
            value: { type: String },
            originalValue: { type: String },
            errorMessage: { type: String },
            error: {
                type: Boolean,
                reflect: true,
            },
            missingMessage: { type: String },
            missing: {
                type: Boolean,
                reflect: true,
            },
            options: { type: Array },
            forceCheck: { type: Boolean },
            width: {
                type: String,
                reflect: true,
            },
            disabled: {
                type: Boolean,
                reflect: true,
            },
            autoComplete: { type: String },
            forceDisabled: { type: Boolean },
            history: { type: Array },
            linkedItem: { type: Object },
            linkedTitle: { type: String },
            linkedFields: { type: Array },
            _storeOptions: { type: Object },
            loading: { type: Boolean },
            openSearch: { type: Boolean },
            mode: {
                type: String,
                reflect: true,
            },
            documentation: { type: String },
            min: { type: Number },
            max: { type: Number },
        };
    }

    constructor() {
        super();

        this.value = '';
        this.missing = false;
        this.error = false;
        this.pattern = '';
        this.options = [];
        this.forceCheck = false;
        this.disabled = false;
        this.autoComplete = '';
        this.forceDisabled = false;
        this.history = [];
        this.linkedItem = {};
        this.linkedTitle = '';
        this.linkedFields = [];
        this._storeOptions = {};
        this.loading = false;
        this.openSearch = false;
        this.mode = 'light';
        this._intialLoadComplete = false; // fixing initial load process input bug which showed required errors on inital load
        this.min = null;
        this.max = null;
    }

    _stateChanged(state) {
        this._storeOptions = state.app.fieldOptions;
    }

    firstUpdated() {
        this._onFinishRenderingInput();
        this.requestUpdate();
    }

    updated(changedProperties) {
        if (this.input === null || changedProperties.has('fieldId')) {
            this._onFinishRenderingInput();
        } else {
            if (
                this.type === 'checkbox' &&
                (this.value === '' ||
                    this.value === null ||
                    this.originalValue === '' ||
                    typeof this.originalValue === 'undefined')
            ) {
                // if checkbox and its value or original value isnt present then blur to force formdata in form wrapper to get value
                this.input.dispatchEvent(new Event('blur'));
            }

            if (
                (changedProperties.get('forceCheck') && this.input) ||
                (changedProperties.has('originalValue') &&
                    changedProperties.has('value') &&
                    this.input &&
                    this._intialLoadComplete)
            ) {
                this._processValue();
            }
        }

        this._intialLoadComplete = true;
    }

    _onFinishRenderingInput() {
        this.input = this.shadowRoot.getElementById(this.fieldId);
        if (this.pattern !== '') {
            this.input.setAttribute('pattern', this.pattern);
        }

        if (this.autoComplete !== '') {
            this.input.setAttribute('auto-complete', this.autoComplete);
        }

        if (this.type === 'select') {
            this.input.value = this.value;
        }

        if (this.type === 'checkbox') {
            this._processValue();
        }
    }

    _getTypedValue() {
        const input = this.input;

        let type = this.input.type;
        if (this.type === 'price') type = 'price';
        switch (type) {
            case 'checkbox':
                return input.checked;
            case 'number':
            case 'select':
            case 'select-one':
                return input.value && input.value !== ''
                    ? isNaN(input.value)
                        ? input.value
                        : parseFloat(input.value)
                    : null;
            case 'price':
                return input.value * 100;
            default:
                return input.value;
        }
    }

    _dispatchValueChangeEvent(isValid) {
        const value = this._getTypedValue();
        const valueChangeEvent = new CustomEvent('value-change', {
            detail: {
                value,
                field: this.name,
                valid: value !== null ? isValid : false,
            },
        });
        this.dispatchEvent(valueChangeEvent);
    }

    _doubleClicked() {
        const makeEditableEvent = new CustomEvent('make-editable', {
            composed: true,
            bubbles: true,
        });
        this.dispatchEvent(makeEditableEvent);
    }

    _updateInputValue() {
        if (this.type !== 'date') this._processValue(); // dont process dates continuously
    }

    _processValue() {
        this.removeAttribute('missing');
        this.removeAttribute('error');
        const valid = this.input.checkValidity();
        this._dispatchValueChangeEvent(valid);
    }

    _toggleSearch() {
        this.openSearch = !this.openSearch;
    }

    _showErrors() {
        if (this.value === '') {
            this.setAttribute('missing', '');
        } else {
            this.setAttribute('error', '');
        }
    }

    _selectedSearchItem(e) {
        const item = e.detail.value;
        this.linkedItem = item;
        this.input.value = item.id;
        this.input.dispatchEvent(new Event('blur'));
    }

    _updateRadioValue(e) {
        const value = e.target.value;
        this.input.value = value;
        this.input.dispatchEvent(new Event('input'));
    }

    _renderField(type, value) {
        if (type === 'select') {
            return this._renderFieldSelect(value);
        } else if (type === 'checkbox') {
            return this._renderFieldCheckbox(value);
        } else if (type === 'number' || type === 'price') {
            return this._renderFieldNumber(value);
        } else if (type === 'radio') {
            return this._renderFieldRadio(value);
        } else if (type === 'textarea') {
            return this._renderFieldTextarea(value);
        } else if (type === 'property_id') {
            return this._renderLinkedId(
                value,
                'property',
                ROUTES.PROPERTY,
                ROUTES.PROPERTY_NEW,
                QUICK_FIELDS.PROPERTY
            );
        } else if (type === 'account_id') {
            return this._renderLinkedId(
                value,
                'account',
                ROUTES.ACCOUNT,
                ROUTES.ACCOUNT_NEW,
                QUICK_FIELDS.ACCOUNT
            );
        } else if (type === 'contract_id') {
            return this._renderLinkedId(
                value,
                'contract',
                ROUTES.CONTRACT,
                ROUTES.CONTRACT_NEW,
                QUICK_FIELDS.CONTRACT,
                ROUTES.CLAIM_REACT
            );
        } else if (type === 'package_id') {
            return this._renderLinkedId(
                value,
                'package',
                ROUTES.PRODUCT_PACKAGE,
                ROUTES.PRODUCT_PACKAGE_NEW,
                QUICK_FIELDS.PRODUCT_PACKAGE
            );
        } else if (type === 'pricebook_id') {
            return this._renderLinkedId(
                value,
                'pricebook',
                ROUTES.PRICEBOOK,
                ROUTES.PRICEBOOK_NEW,
                QUICK_FIELDS.PRICEBOOK
            );
        } else if (type === 'promo_code_id') {
            return this._renderLinkedId(
                value,
                'promocode',
                ROUTES.PROMO_CODE,
                ROUTES.PROMO_CODE_NEW,
                QUICK_FIELDS.PROMOCODE
            );
        } else if (type === 'gc_mandate') {
            return this._renderFieldWithQuickLink(value, config.gcMandateURL);
        } else if (type === 'gc_subscription') {
            return this._renderFieldWithQuickLink(value, config.gcSubscriptionURL, {
                externalLink: true,
                linkItem: config.gcSubscriptionURL,
                fieldsQuick: FIELDS.GC_SUBSCRIPTION,
            });
        } else if (type === 'gc_customer') {
            return this._renderFieldWithQuickLink(value, config.gcCustomerURL, {
                externalLink: true,
                linkItem: config.gcCustomerURL,
                fieldsQuick: FIELDS.GC_CUSTOMER,
            });
        } else if (type === 'cb_subscription') {
            return this._renderFieldWithQuickLink(value, config.cbSubscriptionURL, {
                externalLink: true,
                linkItem: config.cbCustomerURL,
                fieldsQuick: FIELDS.CB_SUBSCRIPTION,
            });
        } else if (type === 'cb_customer') {
            return this._renderFieldWithQuickLink(value, config.cbCustomerURL, {
                externalLink: true,
                linkItem: config.cbCustomerURL,
                fieldsQuick: FIELDS.CB_CUSTOMER,
            });
        } else if (type === 'gc_payment') {
            return this._renderTableList({
                title: 'Payments',
                externalLink: true,
                linkItem: config.gcPaymentURL,
                fieldsQuick: FIELDS.GC_PAYMENT,
            });
        } else if (type === 'referral_id') {
            return this._renderTableList({
                title: 'Referral',
                externalLink: false,
                linkItem: ROUTES.REFERRAL,
                fieldsQuick: QUICK_FIELDS.REFERRAL,
            });
        } else if (type === 'contract_customer_facing_id') {
            return this._renderFieldWithQuickLink(value, config.pmsJobURL);
        } else if (type === 'sf_account_id') {
            return this._renderFieldWithQuickLink(value, config.salesforceURL);
        } else if (type === 'pms_id') {
            return this._renderFieldPMSId(value);
        } else if (
            type === 'contract_status' ||
            type === 'contract_type' ||
            type === 'renewal_status' ||
            type === 'cancellation_type' ||
            type === 'cancellation_status' ||
            type === 'property_type' ||
            type === 'property_fueltype' ||
            type === 'property_fluepositions' ||
            type === 'boiler_make' ||
            type === 'boiler_model' ||
            type === 'account_to_property_relation' ||
            type === 'promocode_family' ||
            type === 'contract_billing' ||
            type === 'promocode_type'
        ) {
            return this._renderFieldStoreSelect(type, value, this._renderFieldSelect.bind(this));
        } else if (type === 'package') {
            return this._renderFieldStoreSelect(type, value, this._renderPackagesRadio.bind(this));
        } else if (type === 'payment_status') {
            return this._renderFieldPaymentStatus(value);
        } else if (type === 'third_party_relation') {
            return this._renderThirdPartyRelation(value);
        } else if (type === 'is_read_only') {
            return this._renderReadOnlyBanner();
        }

        return this._renderFieldInput(value);
    }

    _renderFieldStoreSelect(type, value, fieldFunction) {
        if (this._storeOptions[type] && Array.isArray(this._storeOptions[type])) {
            this.loading = false;
            this.options = this._storeOptions[type].filter((option) => option.active !== 0);
            return fieldFunction(value);
        } else if (this._storeOptions[type] && this._storeOptions[type] === 'pending') {
            this.loading = true;
            return this._renderFieldLabel();
        }
        this.loading = true;
        store.dispatch(getOptionField(type));
        return this._renderFieldLabel();
    }

    _renderFieldLabel() {
        return html` <label class="ht-form-label" for="${this.fieldId}">${this.label}</label> `;
    }

    _renderFieldWithQuickLink(value, link, tableListOptions) {
        return html`
            ${this._renderFieldInput(
                value,
                'text',
                html`<a
                    class="quick-link"
                    target="_blank"
                    href="${value && value !== null ? `${link}${value}` : link}"
                    >${exportIcon('newTab')}</a
                >`,
                tableListOptions
            )}
        `;
    }

    _renderFieldPMSId(value) {
        return html`
            ${this._renderFieldInput(
                value,
                'number',
                html`<a
                    class="quick-link"
                    target="_blank"
                    href="${value && value !== null
                        ? `${config.pmsPolicyURL}${value}`
                        : config.pmsListURL}"
                    >${exportIcon('newTab')}</a
                >`
            )}
        `;
    }

    _renderLinkedId(value, type, linkItem, linkNew, fieldsQuick, reactPath) {
        return html`
            <label class="ht-form-label ht-form-label-centered" for="${this.fieldId}">
                ${this.label} ${value}
                <div class="linked-item__controls">
                    ${value && !reactPath
                        ? html`<a href="${linkItem}/${value}" class="linked-item--icon">
                              ${exportIcon(type)}
                          </a>`
                        : html`<a
                              href="${changePageToReact(
                                  reactPath,
                                  value,
                                  config.environment,
                                  `${linkItem}/`
                              )}"
                              class="linked-item--icon"
                          >
                              ${exportIcon(type)}
                          </a>`}
                    ${!this.forceDisabled && !this.disabled
                        ? html`
                              <div
                                  @click="${this._toggleSearch}"
                                  class="linked-item--icon linked-item-search-toggle"
                              >
                                  ${exportIcon('search')}
                              </div>
                          `
                        : ''}
                </div>
            </label>
            <div class="linked-item">
                <div class="linked-title">
                    <input
                        @invalid="${this._showErrors}"
                        @blur="${this._processValue}"
                        @input="${this._updateInputValue}"
                        class="ht-form-input"
                        type="number"
                        id="${this.fieldId}"
                        name="${this.name}"
                        value="${value}"
                        ?required="${this.required}"
                        ?readonly="${this.forceDisabled}"
                    />
                </div>
            </div>
            <!-- TODO below probably should change it to destory the linked item if value is not present -->
            ${this.linkedItem && value && value !== ''
                ? html`
                      <ht-pandora-table-list
                          slimheaders
                          link="${linkItem}"
                          linkreact="${reactPath || ''}"
                          environment="${config.environment}"
                          list="${JSON.stringify([this.linkedItem])}"
                          tableheaders="${JSON.stringify(fieldsQuick)}"
                          pagesize="5"
                      ></ht-pandora-table-list>
                  `
                : html`<p class="item-present">-</p>`}
            ${!this.forceDisabled && this.openSearch
                ? html`
                      <ht-pandora-form-search-item
                          @selected-search-item="${this._selectedSearchItem}"
                          fields="${JSON.stringify(fieldsQuick)}"
                          type="${type}"
                      ></ht-pandora-form-search-item>
                  `
                : ''}
        `;
    }

    _renderFieldTextarea(value) {
        return html`
            <label class="ht-form-label" for="${this.fieldId}">${this.label}</label>
            <textarea
                @invalid="${this._showErrors}"
                @blur="${this._processValue}"
                @input="${this._updateInputValue}"
                class="ht-form-input ht-form-input-textarea"
                type="${this.type}"
                id="${this.fieldId}"
                name="${this.name}"
                value="${value}"
                ?required="${this.required}"
                ?readonly="${this.forceDisabled}"
                ?disabled="${this.disabled}"
                rows="4"
            ></textarea>
        `;
    }

    _renderFieldNumber(value, type = this.type, labelLinks = null, _tableListOptions = null) {
        return html`
            <label class="ht-form-label" for="${this.fieldId}">
                ${this.label} ${labelLinks || ''}
            </label>
            <input
                @invalid="${this._showErrors}"
                @blur="${this._processValue}"
                @input="${this._updateInputValue}"
                class="ht-form-input"
                min="${this.min !== null ? this.min : null}"
                max="${this.max !== null ? this.max : null}"
                step="${type === 'price' ? 0.01 : 1}"
                type="number"
                id="${this.fieldId}"
                name="${this.name}"
                value="${type === 'price' ? parseInt(value, 10).toFixed(2) : value}"
                ?required="${this.required}"
                ?readonly="${this.forceDisabled}"
                ?disabled="${this.disabled}"
            />
        `;
    }

    _renderFieldInput(value, type = this.type, labelLinks = null, tableListOptions = null) {
        return html`
            <label class="ht-form-label" for="${this.fieldId}">
                ${this.label} ${labelLinks || ''}
            </label>
            <input
                @invalid="${this._showErrors}"
                @blur="${this._processValue}"
                @input="${this._updateInputValue}"
                class="ht-form-input"
                type="${type}"
                id="${this.fieldId}"
                name="${this.name}"
                value="${value}"
                ?required="${this.required}"
                ?readonly="${this.forceDisabled}"
                ?disabled="${this.disabled}"
            />
            ${tableListOptions ? this._renderTableList(tableListOptions) : ''}
        `;
    }

    _renderTableList(tableListOptions) {
        return html`
            ${tableListOptions &&
            this.linkedItem &&
            (!this.linkedItem.hasOwnProperty('length') ||
                (this.linkedItem.length && this.linkedItem.length > 0))
                ? html`
                      ${tableListOptions.title ? html`<h4>${tableListOptions.title}</h4>` : ''}
                      <ht-pandora-table-list
                          ?externalrowclick="${tableListOptions.externalLink}"
                          slimheaders
                          link="${tableListOptions.linkItem}"
                          list="${JSON.stringify(
                              Array.isArray(this.linkedItem) ? this.linkedItem : [this.linkedItem]
                          )}"
                          tableheaders="${JSON.stringify(tableListOptions.fieldsQuick)}"
                          pagesize="5"
                      ></ht-pandora-table-list>
                  `
                : ''}
        `;
    }

    _renderFieldSelect() {
        return html`
            <label class="ht-form-label" for="${this.fieldId}">${this.label}</label>
            <select
                @invalid="${this._showErrors}"
                @blur="${this._processValue}"
                @input="${this._updateInputValue}"
                @change="${this._updateInputValue}"
                class="ht-form-input ht-select"
                type="${this.type}"
                id="${this.fieldId}"
                name="${this.name}"
                ?required="${this.required}"
                ?readonly="${this.forceDisabled}"
                ?disabled="${this.disabled || this.forceDisabled}"
            >
                <option value="">Please select...</option>
                ${this.options.map(
                    (option) => html`
                        <option
                            value="${option.id
                                ? option.id
                                : option.key
                                ? option.key
                                : option.value}"
                        >
                            ${option.name ? option.name : option.label ? option.label : option.text}
                        </option>
                    `
                )}
            </select>
        `;
    }

    _renderPackagesRadio(value) {
        const selectedPackage = this.options.find((option) => option.id.toString() === value);

        const customerTypeId = selectedPackage && selectedPackage.type_id;
        const packageTypeId = selectedPackage && selectedPackage.is_insurance;
        const customerType = customerTypeId === 2 ? 'Homeowner' : 'Landlord';
        const packageType = packageTypeId === 1 ? 'Insurance' : 'Maintenance';

        return html`
            <div class="ht-packages-section">
            <div>
            <label class="ht-form-label" for="customer-type">Customer Type</label>
            <input
                class="ht-form-input ht-select ht-package-filter"
                id="customer-type"
                name="customer-type"
                value=${customerType}
                ?required=${false}
                ?readonly="${true}"
                ?disabled="${true}"
            ></input>
            </div>
            <div>
            <label class="ht-form-label" for="package-type">Package Type</label>
            <input
                class="ht-form-input ht-select ht-package-filter"
                id="package-type"
                name="package-type"
                value=${packageType}
                ?required=${false}
                ?readonly="${true}"
                ?disabled="${true}"
            ></input>
            </div>
            <div>
            ${this._renderFieldRadio(value)}
            </div>
            </div>
        `;
    }

    _renderFieldRadio(value) {
        const localOptions = this.options.map((option) => {
            if (!option.value && !option.label) {
                option.value = option.id.toString();
                option.label = option.name;
            }
            return option;
        });

        const areOptionsNumbers = this.options.every(
            (option) => !Number.isNaN(Number(option.value))
        );

        return html`
            <label class="ht-form-label" for="${this.fieldId}">${this.label}</label>
            <input
                class="ht-form-toggle-hidden"
                type="${areOptionsNumbers ? 'number' : 'text'}"
                name="${this.fieldId}"
                id="${this.fieldId}"
                value="${value}"
                @input="${this._updateInputValue}"
            />
            <div class="ht-form-toggles">
                ${localOptions
                    .filter((option) => (this.disabled ? option.value === value : true))
                    .map(
                        (option, index) => html`
                            <div class="ht-form-toggle">
                                <input
                                    @input="${this._updateRadioValue}"
                                    class="ht-form-toggle-input"
                                    type="radio"
                                    id="${this.fieldId}-${index}"
                                    name="${this.fieldId}"
                                    value="${option.value}"
                                    ?checked="${option.value === value}"
                                    ?readonly="${this.forceDisabled}"
                                    ?disabled="${this.disabled || this.forceDisabled}"
                                />
                                <label class="ht-form-toggle-label" for="${this.fieldId}-${index}"
                                    >${option.label}</label
                                >
                                ${option.route && option.id
                                    ? html`<a
                                          class="ht-form-toggle-link"
                                          href="/${option.route}/${option.id}"
                                          >${exportIcon('eye')}</a
                                      >`
                                    : ''}
                            </div>
                        `
                    )}
            </div>
        `;
    }

    _renderFieldCheckbox(value) {
        return html`
            <label class="ht-form-label" for="${this.fieldId}">${this.label}</label>
            <input
                @invalid="${this._showErrors}"
                @blur="${this._processValue}"
                @input="${this._updateInputValue}"
                class="ht-checkbox"
                type="${this.type}"
                id="${this.fieldId}"
                name="${this.name}"
                ?checked="${value === '1' || value === 'true'}"
                ?required="${this.required}"
                ?readonly="${this.forceDisabled}"
                ?disabled="${this.disabled || this.forceDisabled}"
            />
            <label
                class="ht-checkbox-label ${value === '1' || value === 'true'
                    ? 'ht-checkbox-label-on'
                    : ''}"
                for="${this.fieldId}"
                tabindex="0"
            >
                ${value === '1' || value === 'true'
                    ? html`${exportIcon('tick')} ${this.checkedLabel}`
                    : html`${exportIcon('cross')} ${this.uncheckedLabel}`}
            </label>
        `;
    }

    _renderFieldPaymentStatus(value) {
        const paymentStatusObj = JSON.parse(value);
        // Clean history to make history icon invisible
        this.history = [];
        return html`
            <div
                class="ht-payment-status"
                id="${this.fieldId}"
                name="${this.name}"
                data-paymentstatus="${paymentStatusObj.id}"
            >
                <p>${paymentStatusObj.message}</p>
            </div>
        `;
    }

    _getThirdPartyId(typeMappings) {
        const relationHistory = this.history.find((i) => !!i.value);
        const relations = relationHistory?.value ?? [];
        const types = Object.keys(typeMappings);
        const relation = relations.find((r) => types.includes(r.third_party_type));
        return relation;
    }

    _renderThirdPartyRelation() {
        const typeMappings = {
            smart_cover_customer_id: 'SmartCover',
            openrent_customer_id: '',
        };
        let body = '';
        const relation = this._getThirdPartyId(typeMappings);
        body = html`
            <ul class="third-party-relations">
                <li class="third-party-relation">
                    <span class="third-party-value">${relation?.third_party_id || '--'}</span>
                    <span class="third-party-label"
                        >${typeMappings[relation?.third_party_type] ?? 'None'}</span
                    >
                </li>
            </ul>
        `;
        return html`
            <label class="ht-form-label" for="${this.fieldId}">${this.label}</label>
            ${body}
        `;
    }

    _renderReadOnlyBanner() {
        const relationHistory = this.history.find((i) => !!i.value);
        const relations = relationHistory?.value ?? [];
        const contracts = relations.contracts;
        const isReadOnlyContract =
            contracts && contracts.filter((contract) => contract.is_read_only === 1);

        return html`${isReadOnlyContract && isReadOnlyContract.length > 0
            ? html`<div class="ht_read_only_banner">
                  <p>
                      <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                          <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
                              fill="#FFCCCC"
                          />
                          <rect x="8" y="4" width="2" height="6" rx="1" fill="#DC1515" />
                          <circle cx="9" cy="12" r="1" fill="#DC1515" />
                      </svg>

                      OPENRENT ALERT: Do not edit or cancel anything on this page. Customer must
                      contact Openrent for any changes.
                      <strong> Claims can be submitted as long as the contract is Live. </strong>
                  </p>
              </div>`
            : html``}`;
    }

    _getInputValue(value, type) {
        if (type === 'date') {
            return formatDate(value, 'YYYY-MM-DD');
        }

        return value;
    }

    static get styles() {
        return css`
            ${htPandoraFormItemStyles}
        `;
    }

    render() {
        if (this.input) {
            this.input.value = this._getInputValue(this.value, this.type);
        }

        return html`
            <div
                class="item-wrapper ${this.value !== this.originalValue
                    ? 'item-wrapper-changed'
                    : ''}"
                @dblclick="${this._doubleClicked}"
            >
                ${this._renderField(this.type, this.value)}
                <p class="ht-form-input-error ht-form-input-error-message">${this.errorMessage}</p>
                <p class="ht-form-input-error ht-form-input-missing-message">
                    ${this.missingMessage}
                </p>
                ${this.value
                    ? html`<ht-pandora-form-item-history
                          mode="${this.mode !== '' ? this.mode : this.type}"
                          options="${this.options.length > 0 ? JSON.stringify(this.options) : null}"
                          history="${JSON.stringify(this.history)}"
                      ></ht-pandora-form-item-history>`
                    : html``}
                ${this.documentation && this.documentation !== ''
                    ? html`<a href="${this.documentation}" target="_blank" class="documentation"
                          >${exportIcon('info')}</a
                      >`
                    : ''}
                <ht-pandora-loading
                    small
                    transparent
                    ?active="${this.loading}"
                ></ht-pandora-loading>
            </div>
        `;
    }
}

window.customElements.define('ht-pandora-form-item', htPandoraFormItem);
