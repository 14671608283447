import { MISC } from '../constants/misc';

const weekDayLongname = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const _formatLongDate = (datetime) => {
    let dayAfterText;
    if ([11, 12, 13].indexOf(datetime.getDate()) !== -1) {
        dayAfterText = 'th';
    } else {
        switch (datetime.getDate() % 10) {
            case 1:
                dayAfterText = 'st';
                break;

            case 2:
                dayAfterText = 'nd';
                break;

            case 3:
                dayAfterText = 'rd';
                break;

            default:
                dayAfterText = 'th';
                break;
        }
    }

    return `${weekDayLongname[datetime.getDay()]} ${datetime.getDate()}${dayAfterText} ${MISC.MONTHS[datetime.getMonth()]} ${datetime.getUTCFullYear()}`;
};

export const formatJobDate = (dateSelected) => {
    let jobDateFrom = new Date(dateSelected);

    jobDateFrom = _formatLongDate(jobDateFrom);
    return jobDateFrom;
};

export const formatDate = (date, format) => {
    const dateobj = Object.prototype.toString.call(date) === "[object Date]" ? date : new Date(date);
    const year = _getYear(dateobj);
    const month = _getMonth(dateobj);
    const day = _getDay(dateobj);
    const hour = _getHour(dateobj);
    const minutes = _getMinutes(dateobj);
    const seconds = _getSeconds(dateobj);
    let dateString;

    switch (format) {
        case 'YYYY-MM-DD':
            dateString = `${year}-${month}-${day}`;
            break;
        case 'dateTime':
            dateString = `${day}-${month}-${year} ${hour}:${minutes}`;
            break;
        case 'DD-MM-YY':
            dateString = `${day}-${month}-${year}`;
            break;
        case 'DD-MM-YY HH:MM:SS':
            dateString = `${day}-${month}-${year} ${hour}:${minutes}:${seconds}`;
            break;
        case 'text':
            let dayAfterText;
            if ([11, 12, 13].indexOf(dateobj.getDate()) !== -1) {
                dayAfterText = 'th';
            } else {
                switch (dateobj.getDate() % 10) {
                    case 1:
                        dayAfterText = 'st';
                        break;

                    case 2:
                        dayAfterText = 'nd';
                        break;

                    case 3:
                        dayAfterText = 'rd';
                        break;

                    default:
                        dayAfterText = 'th';
                        break;
                }
            }
            dateString = `${weekDayLongname[dateobj.getDay()]} ${dateobj.getDate()}${dayAfterText} ${MISC.MONTHS[dateobj.getMonth()]} ${dateobj.getUTCFullYear()}`;
            break;
        default:
            dateString = '';
            break;
    }

    return dateString;
};

export const sameDate = (date1, date2) => {
    const dateobj1 = new Date(date1);
    const dateobj2 = new Date(date2);
    return dateobj1.getTime() === dateobj2.getTime();
};

export const formatLastServiceDate = (date) => {
    const lastServiceDate = new Date(date);
    const lastServiceLabel = lastServiceDate.toLocaleString('default', { month: 'long', year: 'numeric' });
    return lastServiceLabel;
}

export const isDate = (date) => {
    return !!date && (new Date(date) !== "Invalid Date") && !isNaN(new Date(date))
}

const _getYear = (dateobj) => {
    return dateobj.getFullYear();
};

const _getMonth = (dateobj) => {
    let month = (dateobj.getMonth() + 1).toString();
    if (month.length === 1) {
        month = `0${month}`;
    }
    return month;
};

const _getDay = (dateobj) => {
    let day = dateobj.getDate().toString();
    if (day.length === 1) {
        day = `0${day}`;
    }
    return day;
};

const _getHour = (dateobj) => {
    let hour = dateobj.getHours().toString();
    if (hour.length === 1) {
        hour = `0${hour}`;
    }
    return hour;
};

const _getMinutes = (dateobj) => {
    let minutes = dateobj.getMinutes().toString();
    if (minutes.length === 1) {
        minutes = `0${minutes}`;
    }
    return minutes;
};

const _getSeconds = (dateobj) => {
    let seconds = dateobj.getSeconds().toString();
    if (seconds.length === 1) {
        seconds = `0${seconds}`;
    }
    return seconds;
};