import { LitElement, css, html } from 'lit-element';
import { connect } from 'pwa-helpers';
import { exportIcon } from './htPandoraIcons';

import { getOptionField } from '../actions/app';
import { store } from '../store';

// These are the shared styles needed by this element.
import { htPandoraTableListStyles } from './styles/ht-pandora-table-list-styles';

import { formatDate, formatLastServiceDate } from '../functions/formatDate';

import { changePage, changePageToReact } from '../functions/changePage';
import { getTableTitle } from '../functions/getTableTitle';
import './htPandoraLoading';

class htPandoraTableList extends connect(store)(LitElement) {
    static get properties() {
        return {
            list: { type: Array },
            tableHeaders: { type: Array },
            loading: { type: Boolean },
            link: { type: String },
            linkreact: { type: String },
            _storeOptions: { type: Object },
            fireEventOnClick: { type: Boolean },
            externalRowClick: { type: Boolean },
            blockActiveFlag: { type: Boolean },
            paginationPage: { type: Number },
            pageSize: { type: Number },
            orderingKey: { type: String },
            orderingAscending: { type: Boolean },
            hideFePagination: { type: Boolean },
            isListSelector: { type: Boolean },
            selectedItem: { type: Number },
            readOnly: { type: Boolean },
            environment: { type: String },
        };
    }

    constructor() {
        super();

        this.link = '/';
        this.linkreact = '';
        this.list = [];
        this.tableHeaders = [];
        this.loading = false;
        this._storeOptions = {};
        this.fireEventOnClick = false;
        this.externalRowClick = false;
        this.blockActiveFlag = false;
        this.pageSize = null;
        this.paginationPage = 1;
        this.orderingAscending = false;
        this.orderingKey = null;
        this.hideFePagination = false;
        this.orderingKey = null;
        this.isListSelector = false;
        this.selectedItem = null;
        this.readOnly = false;
        this.environment;
    }

    _stateChanged(state) {
        this._storeOptions = state.app.fieldOptions;
    }

    _getFieldOptionValue(type, value) {
        if (this._storeOptions[type] && Array.isArray(this._storeOptions[type])) {
            const options = this._storeOptions[type];
            if (!options || options.length === 0) {
                return value;
            }
            const valueObject = options.filter((item) => item.id === value);
            if (valueObject.length > 0) {
                return valueObject[0].name;
            }
            return value;
        } else if (this._storeOptions[type] && this._storeOptions[type] === 'pending') {
            return value;
        }
        store.dispatch(getOptionField(type));
        return value;
    }

    _clickTableRow(item, index) {
        if (this.readOnly) return;
        if (this.isListSelector) {
            this.selectedItem = index;
            this.dispatchEvent(new CustomEvent('select-item', { detail: { value: item, index } }));
        } else if (this.fireEventOnClick) {
            const selectRowEvent = new CustomEvent('select-item', { detail: { value: item } });
            this.dispatchEvent(selectRowEvent);
        } else if (this.externalRowClick) {
            const win = window.open(`${this.link}${item.id}`, '_blank');
            win.focus();
        } else if (this.linkreact) {
            const url = changePageToReact(
                this.linkreact,
                item.id,
                this.environment,
                this.link === 'contract' ? 'contract/' : ''
            );
            // eslint-disable-next-line no-restricted-globals
            location.href = url;
        } else {
            changePage(`${this.link}/${item.id}`);
        }
    }

    _clickTableItem(link, id, linkreact) {
        if (linkreact && link === 'contract') {
            const url = changePageToReact(linkreact, id, this.environment, `${link}/`);
            // eslint-disable-next-line no-restricted-globals
            location.href = url;
        }

        changePage(`${link}/${id}`);
    }

    _getLinkedTitle(fields, item) {
        let text = '';
        fields.forEach((field) => {
            text = `${text !== '' ? `${text},` : ''} ${item[field.key]}`;
        });
        return text;
    }

    _getValueKey(key, item) {
        if (item[key]) {
            return item[key];
        }
        return '';
    }

    _renderTD(text, header, item) {
        let htmlOutput = text;
        let link = text;

        if (header.valueKey) {
            link = this._getValueKey(header.valueKey, item);
        }

        if (header.type === 'checkbox') {
            htmlOutput = html`${text === 1 ? exportIcon('tick') : exportIcon('cross')}`;
        } else if (header.type === 'date') {
            if (text.indexOf('T00:00:00.000Z') === -1) {
                htmlOutput = formatDate(text, 'DD-MM-YY HH:MM:SS');
            } else {
                htmlOutput = formatDate(text, 'DD-MM-YY');
            }
        } else if (header.icon) {
            htmlOutput = exportIcon(header.icon);
        } else if (header.type === 'price') {
            htmlOutput = `£${(text / 100).toFixed(2)}`;
        } else if (header.type === 'last_service_date') {
            htmlOutput =
                text !== null ? formatLastServiceDate(text) : 'Pretty sure it was over a year ago';
        }

        if (
            header.type === 'contract_status' ||
            header.type === 'contract_type' ||
            header.type === 'renewal_status' ||
            header.type === 'contract_billing' ||
            header.type === 'package' ||
            header.type === 'cancellation_type' ||
            header.type === 'cancellation_status' ||
            header.type === 'property_type' ||
            header.type === 'property_fueltype' ||
            header.type === 'property_fluepositions' ||
            header.type === 'boiler_make' ||
            header.type === 'boiler_model' ||
            header.type === 'promocode_family' ||
            header.type === 'promocode_type' ||
            header.type === 'account_to_property_relation'
        ) {
            htmlOutput = this._getFieldOptionValue(header.type, text);
        }

        if (header.linkedItem && header.linkedItemFieldsReference && item[header.linkedItem]) {
            htmlOutput = getTableTitle(header.linkedItemFieldsReference, item[header.linkedItem]);
        }

        if (!this.fireEventOnClick && !this.isListSelector && !this.readOnly) {
            if (header.linkUrl && header.linkUrlExternal) {
                return html`<a
                    target="_blank"
                    class="has-link"
                    @click="${(e) => {
                        e.stopPropagation();
                    }}"
                    href="${header.linkUrl}${link}"
                    >${htmlOutput}</a
                >`;
            } else if (header.linkUrl && header.linkreact) {
                return html`<p
                    class="has-link"
                    @click="${(e) => {
                        e.stopPropagation();
                        this._clickTableItem(header.linkUrl, link, header.linkreact);
                    }}"
                >
                    ${htmlOutput}
                </p>`;
            } else if (header.linkUrl) {
                return html`<p
                    class="has-link"
                    @click="${(e) => {
                        e.stopPropagation();
                        this._clickTableItem(header.linkUrl, link, header.linkreact);
                    }}"
                >
                    ${htmlOutput}
                </p>`;
            }
        }

        return html`<p>${htmlOutput}</p>`;
    }

    _getPaginationList(list) {
        if (this.pageSize === null) return list;
        const startSlice = (this.paginationPage - 1) * this.pageSize;
        return list.slice(startSlice, startSlice + this.pageSize);
    }

    _changePage(number) {
        const pageCount = this.list.length / this.pageSize;
        let newPageNumber = number;
        if (newPageNumber < 1) newPageNumber = 1;
        if (newPageNumber > pageCount + 1) newPageNumber = Math.floor(pageCount + 1);
        this.paginationPage = newPageNumber;
    }

    _renderPagination(list) {
        const count = list.length;
        const pageCount = Math.ceil(count / this.pageSize);
        const pageArrayToMap = [];
        const lowestRange = 3 - this.paginationPage < 0 ? 3 : 7 - this.paginationPage;
        const highestRange =
            pageCount - this.paginationPage > 3 ? 3 : this.paginationPage - pageCount + 6;
        for (let i = 1; i <= pageCount; i++) {
            // eslint-disable-next-line no-mixed-operators
            const show =
                i === 1 ||
                i === pageCount ||
                (this.paginationPage - i > -lowestRange && this.paginationPage - i < highestRange);
            if (show) pageArrayToMap.push(i);
        }

        return html`
            ${pageArrayToMap.length > 1
                ? html`
                      <div class="pagination">
                          <p
                              class="pagination-page pagination-previous"
                              ?disabled="${this.paginationPage === 1}"
                              @click="${() => this._changePage(this.paginationPage - 1)}"
                          >
                              <<
                          </p>
                          ${pageArrayToMap.map(
                              (item) =>
                                  html`<p
                                      @click="${() => this._changePage(item)}"
                                      class="pagination-page ${item === this.paginationPage
                                          ? 'active'
                                          : ''}"
                                  >
                                      ${item}
                                  </p>`
                          )}
                          <p
                              class="pagination-page pagination-next"
                              ?disabled="${this.paginationPage === pageCount}"
                              @click="${() => this._changePage(this.paginationPage + 1)}"
                          >
                              >>
                          </p>
                      </div>
                  `
                : ''}
        `;
    }

    _order(key) {
        if (this.isListSelector) return; // block listselectors being reordered
        if (key !== this.orderingKey) {
            this.orderingKey = key;
            this.orderingAscending = true;
        } else {
            this.orderingAscending = !this.orderingAscending;
        }

        const orderingAscending = this.orderingAscending;

        const newList = [...this.list];
        newList.sort((a, b) => {
            const valueA = a[key];
            const valueB = b[key];

            if (orderingAscending) {
                if (valueA < valueB) {
                    return -1;
                }
                if (valueA > valueB) {
                    return 1;
                }
            } else {
                if (valueA > valueB) {
                    return -1;
                }
                if (valueA < valueB) {
                    return 1;
                }
            }

            // values are equal
            return 0;
        });

        this.list = newList;
    }

    updated(changedProps) {
        if (changedProps.has('list') && typeof changedProps.get('list') !== 'undefined') {
            if (this.list.length !== changedProps.get('list').length) {
                this.paginationPage = 1;
            }
        }
    }

    static get styles() {
        return css`
            ${htPandoraTableListStyles}
        `;
    }

    render() {
        return html`
            ${!this.hideFePagination && this.pageSize !== null && this.list.length > 0
                ? this._renderPagination(this.list)
                : ''}
            <div class="table-wrapper">
                <table>
                    <thead>
                        <tr>
                            ${this.tableHeaders.map(
                                (item) => html`<th
                                    class="table-header table-header-${item.key} ${this
                                        .orderingKey === item.key
                                        ? `ordering-active ${
                                              this.orderingAscending
                                                  ? 'ordering-ascending'
                                                  : 'ordering-descending'
                                          }`
                                        : ''}"
                                    @click="${() => this._order(item.key)}"
                                >
                                    ${item.label} ${exportIcon('up')}
                                </th>`
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        ${this._getPaginationList(this.list).map(
                            (item, index) => html`
                                <tr
                                    @click="${() => this._clickTableRow(item, index)}"
                                    class="table-row${!this.blockActiveFlag && item.active === 0
                                        ? ' not-active'
                                        : ''}${this.isListSelector && this.selectedItem === index
                                        ? ' selected'
                                        : ''}"
                                >
                                    ${this.tableHeaders.map(
                                        (header) =>
                                            html`<td class="table-data table-data-${header.key}">
                                                ${typeof item[header.key] !== 'undefined' &&
                                                (item[header.key] !== null ||
                                                    header.type === 'last_service_date')
                                                    ? this._renderTD(item[header.key], header, item)
                                                    : html`<p>-</p>`}
                                            </td>`
                                    )}
                                </tr>
                            `
                        )}
                    </tbody>
                </table>
            </div>

            <ht-pandora-loading ?active="${this.loading}"></ht-pandora-loading>
        `;
    }
}

window.customElements.define('ht-pandora-table-list', htPandoraTableList);
