import Cookies from 'js-cookie'
import { sound } from './sound'
import { store } from '../store'
import { login, logout } from '../actions/app'
import { getClaimsLoginPageUrl } from './changePage'
import { parseJwtToken } from './parseJwtToken'
import { getCookieDomain } from './getCookieDomain'

const postCognitoLoggedIn = (email) => {
    sound('powerup')
    store.dispatch(login(email))
}

const postCognitoLoggedOut = () => {
    sound('gameover')
    store.dispatch(logout())
    const domain = getCookieDomain(process.env.ENVIRONMENT)
    Cookies.remove('last-visited', { domain })
}

export const handleLogOutRedirect = () => {
    const domain = getCookieDomain(process.env.ENVIRONMENT)
    Cookies.set('last-visited', window.location.href, { domain })
    const url = getClaimsLoginPageUrl()
    window.location.assign(url)
}

export const checkIfLoggedIn = () => {
    const idToken = Cookies.get('amplify_id_token')
    const accessToken = Cookies.get('amplify_access_token')

    const parsedIdToken = parseJwtToken(idToken)
    const parsedAccessToken = parseJwtToken(accessToken)

    // eslint-disable-next-line arrow-parens
    const isTokenExpired = (expiryDate) => expiryDate && expiryDate < Math.floor(Date.now() / 1000)

    // eslint-disable-next-line arrow-parens
    const isIdTokenValid = (token) => token && token.email && !isTokenExpired(token.exp)

    // eslint-disable-next-line arrow-parens
    const isAccessTokenValid = (token) => token && !isTokenExpired(token.exp)

    if (!isIdTokenValid(parsedIdToken) || !isAccessTokenValid(parsedAccessToken)) {
        postCognitoLoggedOut()
        handleLogOutRedirect()
    }

    postCognitoLoggedIn(parsedIdToken.email)
}

export const getLoggedInUserTokensFromCookie = () => {
    const idToken = Cookies.get('amplify_id_token')
    const accessToken = Cookies.get('amplify_access_token')
    if (
        !idToken ||
        !accessToken ||
        parseJwtToken(idToken).exp < Math.floor(Date.now() / 1000) ||
        parseJwtToken(accessToken).exp < Math.floor(Date.now() / 1000)
    ) {
        return { idToken: null, accessToken: null }
    }
    return { idToken, accessToken }
}
