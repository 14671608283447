import { CONSTANT_FIELDS_ITEM, CONSTANT_FIELDS } from '../constants/tables/_constant_fields';

const getAllFields = (allFields = [], currentFields) => {
    return [...allFields, ...currentFields];
};

export const getFieldsItem = (fields, showPreFields = true) => {
    if(!showPreFields) return [...fields, ...CONSTANT_FIELDS_ITEM.POST_TABLE];
    return [...CONSTANT_FIELDS_ITEM.PRE_TABLE, ...fields, ...CONSTANT_FIELDS_ITEM.POST_TABLE];
};

export const getFields = (fields, showPreFields = true) => {
    if(!showPreFields) return [...fields, ...CONSTANT_FIELDS.POST_TABLE];
    return [...CONSTANT_FIELDS.PRE_TABLE, ...fields, ...CONSTANT_FIELDS.POST_TABLE];
};

export const getActionFields = (fields, actionName) => {
    return fields.map(actionField => {
        const newActionField = { ...actionField };
        delete newActionField.show_field;
        delete newActionField.show_field_value;
        newActionField.disabled = false;
        return newActionField;
    });
};

export const getTitleText = (fields, item) => {
    let text = "";
    fields.forEach(field => {
        text = `${text !== "" ? `${text},` : ''} ${item[field.key]}`;
    });
    return text;
};