import { getLoggedInUserTokensFromCookie, handleLogOutRedirect } from '../functions/awsCognito'

export const getRequestHeaders = () => {
    const tokens = getLoggedInUserTokensFromCookie()

    if (
        tokens.idToken === null ||
        typeof tokens.idToken === 'undefined' ||
        tokens.accessToken === null ||
        typeof tokens.accessToken === 'undefined'
    ) {
        handleLogOutRedirect()
    }

    return {
        Authorization: tokens.idToken,
        'Content-Type': 'application/json',
    }
}
