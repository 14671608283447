import { html, css, LitElement } from 'lit-element';

// These are the shared styles needed by this element.
import { htPandoraSearchStyles } from './styles/ht-pandora-search-styles';

import { getDataFactory } from '../functions/getDataFactory';
import { getUrlParameterByName } from '../functions/getUrlParameterByName';
import { setURLParameterByName } from '../functions/setURLParameterByName';
import { config } from '../functions/config';
import { exportIcon } from './htPandoraIcons';

import './htPandoraTableList';

import { createNotificationObj } from '../functions/notificationFactory';
import { connect } from 'pwa-helpers';
import { store } from '../store.js';
import {
    addNotification,
} from '../actions/app.js';

import './htPandoraLoading';
import { ROUTES } from '../constants/routes';

class htPandoraSearch extends connect(store)(LitElement) {
    static get properties() {
        return {
            _searchTerm: { type: String },
            _results: { type: Array },
            _loading: { type: Boolean },
            action: { type: String },
            useUrlParameters: { type: Boolean },
            linkToMainSearch: { type: Boolean },
            focus: { type: Boolean },
            activeStatus: { type: String }
        };
    }

    constructor() {
        super();

        this.action = "";
        this._loading = false;
        this._results = [];
        this._searchTerm = "";
        this.useUrlParameters = false;
        this.linkToMainSearch = false;
        this.focus = false;
    }

    firstUpdated() {
        this.getData = getDataFactory({ fetch: window.fetch, apiEndPoint: `${config.searchAPI}/${this.action}?q=` });
        // Define the debounced function
        var debouncedSearchInput = this._debounceSearch(this._onSearchInput, 400);
        // Call the debounced function on every search input
        this.searchInput = this.shadowRoot.getElementById('search');
        this.searchInput.addEventListener('input', debouncedSearchInput);

        if(this.focus) {
            this.searchInput.focus();
        }

        if(this.useUrlParameters) {
            this._checkUrlParameter();
        }
    }

    _checkUrlParameter() {
        const urlQuery = getUrlParameterByName('q');
        if(urlQuery) {
            this.searchInput.value = urlQuery;
            this.searchInput.dispatchEvent(new Event('input'));
        }
    }

    _stateChanged(state) {

    }

    _debounceSearch(func, wait, immediate) {
        // 'private' variable for instance
        // The returned function will be able to reference this due to closure.
        // Each call to the returned function will share this common timer.
        var timeout,
            context = this;

        // Calling debounce returns a new anonymous function
        return function() {
            // reference the context and args for the setTimeout function
            this._loading = true;
            var args = arguments;

            // Should the function be called now? If immediate is true
            //   and not already in a timeout then the answer is: Yes
            var callNow = immediate && !timeout;

            // This is the basic debounce behaviour where you can call this
            //   function several times, but it will only execute once
            //   [before or after imposing a delay].
            //   Each time the returned function is called, the timer starts over.
            clearTimeout(timeout);

            // Set the new timeout
            timeout = setTimeout(function() {

                // Inside the timeout function, clear the timeout variable
                // which will let the next execution run when in 'immediate' mode
                timeout = null;

                // Check if the function already ran with the immediate flag
                if (!immediate) {
                    // Call the original function with apply
                    // apply lets you define the 'this' object as well as the arguments
                    //    (both captured before setTimeout)
                    func.apply(context, args);
                }
            }, wait);

            // Immediate mode and no wait timer? Execute the function..
            if (callNow) func.apply(context, args);
        }
    }


    _onSearchInput() {
        this._loading = true;

        const searchValue = this.searchInput.value;

        this._searchTerm = searchValue;
        if (this.useUrlParameters) setURLParameterByName('q', this._searchTerm);

        // Bail early if input has no value.
        if (searchValue === '' || searchValue.length < 0) {
            this._updateResults([])
            this._loading = false;
            return;
        }
        const getSearchResults = this.getData(`${searchValue}${this.activeStatus ? `&active=${this.activeStatus}` : ''}`);

        getSearchResults.then(response => response.json()).then((data) => {
            if (data.data.errorMessage) {
                this._loading = false;
                this._updateResults([])
                store.dispatch(addNotification(createNotificationObj(`Error: ${data.data.errorMessage}`, 'error', true, false)));
            } else {
                this._updateResults(data.data);
                this._loading = false;
            }
        }).catch((error) => {
            this._loading = false;
            this._updateResults([])
            store.dispatch(addNotification(createNotificationObj(`Get ${this.title}: ${error}`, 'error', true, false)));
        });
    }

    _updateResults(newResults) {
        this._results = newResults;
        const resultsEvent = new CustomEvent('results', { detail: { value: newResults }});
        this.dispatchEvent(resultsEvent);
    }

    _dismiss() {
        this.searchInput.value = "";
        this.searchInput.dispatchEvent(new Event('input'));
    }

    _focus() {
        this.searchInput.focus();
    }

    updated(changedProps) {
        if(typeof changedProps.get("activeStatus") !== 'undefined' && this._results.length > 0) { // if a new item has been selected remove all form data information
            this.searchInput.dispatchEvent(new Event('input'));
        }
    }

    static get styles() {
        return css`${htPandoraSearchStyles}`;
    }

    render() {
        return html`
            <div class="search-wrapper">
                <input class="search" id="search" type="text" placeholder="search..."/>
                ${this._searchTerm.length > 0 ? html`<div @click="${this._dismiss}" class="dismiss">${exportIcon('cross')}</div>` : ''}
            </div>
            ${this._results.length === 0 && !this._loading && this._searchTerm.length > 0 ? html`<p class="no-results">No results ${exportIcon('thumbsDown')}${this.linkToMainSearch ? html` <a class="global-search-link" href="${ROUTES.SEARCH}?q=${this._searchTerm}">Global Search?</a>` : ''}</p>` : ''}
            <div class="loader-wrapper">
                <ht-pandora-loading ?active="${this._loading}"></ht-pandora-loading>
            </div>
        `;
    }
}

window.customElements.define('ht-pandora-search', htPandoraSearch);
