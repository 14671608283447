import { config } from '../../functions/config';
import { ROUTES } from '../routes';
import { getFieldsItem, getFields } from '../../functions/fieldProcessing';

export const FIELDS = {
    "account_id": {
        key: 'account_id',
        label: 'Account',
        type: 'account_id',
        required: true,
        disabled: false,
        width: '12',
        linkedItem: 'account',
        linkedItemFieldsReference: 'ACCOUNT'
    },
    "property_id": {
        key: 'property_id',
        label: 'Linked Property',
        type: 'property_id',
        required: false,
        disabled: false,
        width: '12',
        linkedItem: 'property',
        linkedItemFieldsReference: 'PROPERTY'
    },
    "relation_type": {
        key: 'relation_type',
        label: 'Relation Type',
        type: 'account_to_property_relation',
        required: true,
        disabled: false,
        width: '8'
    }
}

export const ITEM = getFieldsItem([
    {
        section_name: "Account",
        hideSectionName: true,
        fields: [
            FIELDS.account_id
        ]
    },
    {
        section_name: "Property",
        hideSectionName: true,
        fields: [
            FIELDS.property_id
        ]
    },
    {
        section_name: "Relation Type",
        hideSectionName: true,
        fields: [
            FIELDS.relation_type
        ]
    }
]);

export const RELATION = [
    {
        link: true,
        linkUrl: ROUTES.ACCOUNT_TO_PROPERTY,
        key: 'relation_type',
        label: 'Relation',
        type: 'text',
        valueKey: 'account_to_property_id'
    }
];

export const QUICK_FIELDS = getFields([
    FIELDS.account_id,
    FIELDS.property_id,
    FIELDS.relation_type
]);

export const TITLE_FIELDS = [
    FIELDS.account_id
];

export const PAGE = {
    'item-url': ROUTES.ACCOUNT_TO_PROPERTY,
    'new-url': ROUTES.ACCOUNT_TO_PROPERTY_NEW,
    'endpoint': config.accountAPI,
    'item-view': ITEM,
    'quick-fields': QUICK_FIELDS,
    'type': 'account',
    'item-page': {
        'action': '/attach/property',
        'expansions': 'property,account'
    },
    'new-page': {
        'title': 'Account to Property Link',
        'action': '/attach/property'
    }
};