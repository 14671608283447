import { LitElement, html, css } from 'lit-element';

import { htPandoraNotificationControllerStyles } from './styles/ht-pandora-notification-controller-styles';
import './htPandoraNotifications';

import { connect } from 'pwa-helpers';
import { store } from '../store.js';
import {
    removeNotification,
} from '../actions/app.js';

class htPandoraNotificationController extends connect(store)(LitElement) {
    static get properties() {
        return {
            notifications: { type: Array }
        };
    }

    constructor() {
        super();

        this.notifications = [];
    }

    _stateChanged(state) {
        this.notifications = state.app.notifications;
    }

    _removeNotification(e) {
        const index = e.detail.id;
        store.dispatch(removeNotification(index));
    }

    static get styles() {
        return css`${htPandoraNotificationControllerStyles}`;
    }

    render() {
        return html`
        ${this.notifications.map((item, index) => html`
            <ht-pandora-notification
                @remove="${this._removeNotification}"
                notificationid=${index}
                text=${item.text}
                ?persistence=${item.persistence}
                state=${item.state}
                button=${item.button ? JSON.stringify(item.button) : '{}'}
                ?dismissbutton=${item.dismissButton}>
            </ht-pandora-notification>`)}
        `;
    }
}

window.customElements.define('ht-pandora-notification-controller', htPandoraNotificationController);
