import { addEditableItem } from '../../actions/app';
import { config } from '../../functions/config';
import { getActionFields, getFields, getFieldsItem } from '../../functions/fieldProcessing';
import { store } from '../../store';
import { LINKS } from '../links';
import { OPTIONS } from '../options';
import { ROUTES } from '../routes';
import { UPDATE_WARNINGS } from '../updateWarnings';

const ACTION_DATE_CHANGE = 'date_change';
const ACTION_CANCELLATION = 'cancellation';

export const FIELDS = {
    payment_status: {
        key: 'payment_status',
        label: 'Payment Status',
        type: 'payment_status',
        required: false,
        checkedLabel: 'Payment Status',
        uncheckedLabel: 'Payment Status',
        headerRequired: true,
        disabled: true,
        width: '12',
        mode: 'active',
    },
    type: {
        key: 'type',
        label: 'Type',
        type: 'contract_type',
        required: false,
        disabled: true,
        width: '3',
    },
    status: {
        key: 'status',
        label: 'Status',
        type: 'contract_status',
        required: true,
        disabled: true,
        width: '3',
        updateWarning: UPDATE_WARNINGS.CONTRACT.CONTRACT_STATUS,
    },
    cancellation_status: {
        show_field: 'status',
        show_field_value: [
            OPTIONS.CONTRACT_STATUS.CANCELLED,
            OPTIONS.CONTRACT_STATUS.EXPIRED,
            OPTIONS.CONTRACT_STATUS.EXPIRED_DUE_TO_PAYMENT_ISSUES,
        ],
        key: 'cancellation_status',
        label: 'Cancellation Status',
        type: 'cancellation_status',
        required: true,
        disabled: false,
        width: '4',
        actions: [ACTION_CANCELLATION],
    },
    cancellation_type: {
        show_field: 'status',
        show_field_value: [
            OPTIONS.CONTRACT_STATUS.CANCELLED,
            OPTIONS.CONTRACT_STATUS.EXPIRED,
            OPTIONS.CONTRACT_STATUS.EXPIRED_DUE_TO_PAYMENT_ISSUES,
        ],
        key: 'cancellation_type',
        label: 'Cancellation Reason',
        type: 'cancellation_type',
        required: true,
        disabled: false,
        width: '4',
        actions: [ACTION_CANCELLATION],
    },
    cancellation_description: {
        show_field: 'status',
        show_field_value: [
            OPTIONS.CONTRACT_STATUS.CANCELLED,
            OPTIONS.CONTRACT_STATUS.EXPIRED,
            OPTIONS.CONTRACT_STATUS.EXPIRED_DUE_TO_PAYMENT_ISSUES,
        ],
        key: 'cancellation_description',
        label: 'Cancellation Description',
        type: 'textarea',
        required: true,
        disabled: false,
        width: '12',
        actions: [ACTION_CANCELLATION],
    },
    customer_facing_id: {
        key: 'customer_facing_id',
        label: 'Contract Number',
        type: 'contract_customer_facing_id',
        required: false,
        disabled: true,
        width: '3',
    },
    start_date: {
        key: 'start_date',
        label: 'Start Date',
        type: 'date',
        required: true,
        disabled: true,
        width: '3',
        actions: [ACTION_DATE_CHANGE],
    },
    end_date: {
        key: 'end_date',
        label: 'End Date',
        type: 'date',
        required: true,
        disabled: true,
        width: '3',
        actions: [ACTION_DATE_CHANGE],
    },
    automatically_renew: {
        key: 'automatically_renew',
        label: '',
        checkedLabel: 'Will Automatically Renew',
        uncheckedLabel: "Won't Automatically Renew",
        type: 'checkbox',
        required: false,
        disabled: false,
        width: '3',
    },
    account_id: {
        key: 'account_id',
        label: 'Linked Account',
        type: 'account_id',
        required: true,
        disabled: false,
        width: '12',
        linkedItem: 'account',
        linkedItemFieldsReference: 'ACCOUNT',
        linkUrl: ROUTES.ACCOUNT,
    },
    property_id: {
        key: 'property_id',
        label: 'Linked Property',
        type: 'property_id',
        required: true,
        disabled: false,
        width: '12',
        linkedItem: 'property',
        linkedItemFieldsReference: 'PROPERTY',
        linkUrl: ROUTES.PROPERTY,
    },
    '3rd_party_id': {
        key: '3rd_party_id',
        label: 'PMS Id',
        type: 'pms_id',
        required: false,
        disabled: false,
        width: '3',
        linkUrl: config.pmsPolicyURL,
        linkUrlExternal: true,
    },
    go_cardless_mandate_id: {
        key: 'go_cardless_mandate_id',
        label: 'GC Mandate',
        type: 'gc_mandate',
        required: false,
        disabled: false,
        width: '4',
        linkUrl: config.gcMandateURL,
        linkUrlExternal: true,
    },
    go_cardless_subscription_id: {
        key: 'go_cardless_subscription_id',
        label: 'GC Subscription',
        type: 'gc_subscription',
        required: false,
        disabled: false,
        width: '12',
        linkUrl: config.gcSubscriptionURL,
        linkUrlExternal: true,
        linkedItem: 'go_cardless_subscription',
    },
    go_cardless_customer_id: {
        key: 'go_cardless_customer_id',
        label: 'GC Customer',
        type: 'gc_customer',
        required: false,
        disabled: false,
        width: '12',
        linkUrl: config.gcCustomerURL,
        linkUrlExternal: true,
        linkedItem: 'go_cardless_customer',
    },
    go_cardless_payment_id: {
        key: 'go_cardless_payment_id',
        label: 'GC Payments',
        type: 'gc_payment',
        required: false,
        disabled: true,
        width: '12',
        linkUrl: config.gcPaymentURL,
        linkUrlExternal: true,
        linkedItem: 'go_cardless_payments',
    },
    cb_subscription_id: {
        key: 'cb_subscription_id',
        label: 'Chargebee Subscription',
        type: 'cb_subscription',
        required: false,
        disabled: false,
        width: '16',
        linkUrl: config.cbSubscriptionURL,
        linkUrlExternal: true,
    },
    cb_customer_id: {
        key: 'cb_customer_id',
        label: 'Chargebee Customer',
        type: 'cb_customer',
        required: false,
        disabled: false,
        width: '16',
        linkUrl: config.cbCustomerURL,
        linkUrlExternal: true,
    },
    package_id: {
        key: 'package_id',
        label: 'Package',
        type: 'package',
        required: false,
        disabled: true,
        width: '12',
        linkedItem: 'package',
        linkedItemFieldsReference: 'PRODUCT_PACKAGE',
        linkUrl: ROUTES.PRODUCT_PACKAGE,
    },
    billing_type: {
        key: 'billing_type',
        label: 'Billing Type',
        type: 'contract_billing',
        required: false,
        disabled: true,
        width: '3',
    },
    contribution: {
        key: 'contribution',
        label: 'Call-out fee',
        type: 'radio',
        required: false,
        disabled: false,
        width: '3',
        options: OPTIONS.CONTRIBUTION,
    },
    payment_amount: {
        key: 'payment_amount',
        label: 'Current Payment Amount',
        type: 'price',
        required: false,
        disabled: false,
        width: '3',
    },
    pricebook_id: {
        key: 'pricebook_id',
        label: 'Pricebook',
        type: 'pricebook_id',
        required: true,
        disabled: true,
        width: '3',
        linkedItemFieldsReference: 'PRICEBOOK',
        linkUrl: ROUTES.PRICEBOOK,
    },
    promo_code_id: {
        key: 'promo_code_id',
        label: 'Promo Code',
        type: 'promo_code_id',
        required: false,
        disabled: false,
        width: '12',
        linkedItem: 'promocode',
        linkedItemFieldsReference: 'PROMOCODE',
        linkUrl: ROUTES.PROMO_CODE,
    },
    referral: {
        key: 'referral',
        label: 'Referral',
        type: 'referral_id',
        required: false,
        disabled: true,
        width: '12',
        linkedItem: 'referral',
        linkedItemFieldsReference: 'REFERRAL',
        linkUrl: ROUTES.REFERRAL,
    },
    landingpage: {
        key: 'landingpage',
        label: 'Landing Page',
        type: 'text',
        required: false,
        disabled: false,
        width: '3',
    },
    http_referer: {
        key: 'http_referer',
        label: 'HTTP Referer',
        type: 'text',
        required: false,
        disabled: false,
        width: '3',
    },
    ga_cid: {
        key: 'ga_cid',
        label: 'GA CID',
        type: 'text',
        required: false,
        disabled: false,
        width: '3',
    },
    querystring: {
        key: 'querystring',
        label: 'Query String',
        type: 'text',
        required: false,
        disabled: false,
        width: '12',
    },
    utm_campaign: {
        key: 'utm_campaign',
        label: 'Campaign',
        type: 'text',
        required: false,
        disabled: false,
        width: '3',
    },
    utm_medium: {
        key: 'utm_medium',
        label: 'Medium',
        type: 'text',
        required: false,
        disabled: false,
        width: '3',
    },
    utm_source: {
        key: 'utm_source',
        label: 'Source',
        type: 'text',
        required: false,
        disabled: false,
        width: '3',
    },
    utm_term: {
        key: 'utm_term',
        label: 'Term',
        type: 'text',
        required: false,
        disabled: false,
        width: '3',
    },
    utm_content: {
        key: 'utm_content',
        label: 'Content',
        type: 'text',
        required: false,
        disabled: false,
        width: '3',
    },
    cancel_gocardless: {
        show_field: 'go_cardless_mandate_id',
        show_field_exists: 'go_cardless_mandate_id',
        key: 'cancel_gocardless',
        label: 'Cancel GoCardless mandate?',
        type: 'radio',
        required: false,
        disabled: false,
        options: OPTIONS.CANCEL_GOCARDLESS,
        width: '4',
    },
    cancel_chargebee: {
        show_field: 'cb_subscription_id',
        show_field_exists: 'cb_subscription_id',
        key: 'cancel_chargebee',
        label: 'Cancel chargebee subscription?',
        type: 'radio',
        required: false,
        disabled: false,
        options: OPTIONS.CANCEL_CHARGEBEE,
        width: '4',
    },
    sales_attribute: {
        key: 'sales_agent',
        label: 'Sales Agent',
        type: 'text',
        required: false,
        disabled: true,
        width: '3',
    },
    alternative_accommodation: {
        key: 'alternative_accommodation',
        label: '',
        checkedLabel: 'Alternative accommodation',
        uncheckedLabel: 'Alternative accommodation',
        type: 'checkbox',
        required: false,
        disabled: true,
        width: '3',
    },
};

export const ITEM = getFieldsItem(
    [
        {
            section_name: 'Payment status',
            hideSectionName: true,
            fields: [FIELDS.payment_status],
        },
        {
            section_name: 'General',
            hideSectionName: true,
            fields: [
                FIELDS.type,
                FIELDS.status,
                FIELDS.cancellation_status,
                FIELDS.cancellation_type,
                FIELDS.cancellation_description,
                FIELDS.customer_facing_id,
                FIELDS.start_date,
                FIELDS.end_date,
                FIELDS.automatically_renew,
                FIELDS.account_id,
                FIELDS.property_id,
            ],
        },
        {
            section_name: 'Current Package and Pricing',
            type: 'package',
            fields: [
                FIELDS.package_id,
                FIELDS.billing_type,
                FIELDS.contribution,
                FIELDS.payment_amount,
                FIELDS.pricebook_id,
                FIELDS.promo_code_id,
                FIELDS.referral,
                FIELDS.alternative_accommodation,
            ],
        },
        {
            section_name: 'Go Cardless',
            type: 'accordion',
            hideSectionName: true,
            links: LINKS.GO_CARDLESS_LINKS,
            fields: [
                FIELDS.go_cardless_mandate_id,
                FIELDS.go_cardless_subscription_id,
                FIELDS.go_cardless_customer_id,
                FIELDS.go_cardless_payment_id,
            ],
        },
        {
            section_name: 'Chargebee',
            type: 'accordion',
            fields: [FIELDS.cb_customer_id, FIELDS.cb_subscription_id],
        },
        {
            section_name: 'Preferred Management Solutions (PMS) Details',
            type: 'accordion',
            hideSectionName: true,
            links: LINKS.PMS_LINKS,
            fields: [FIELDS['3rd_party_id']],
        },
        {
            section_name: 'Marketing',
            hideSectionName: true,
            type: 'accordion',
            fields: [
                FIELDS.landingpage,
                FIELDS.http_referer,
                FIELDS.ga_cid,
                FIELDS.querystring,
                FIELDS.utm_campaign,
                FIELDS.utm_medium,
                FIELDS.utm_source,
                FIELDS.utm_term,
                FIELDS.utm_content,
                FIELDS.sales_attribute,
            ],
        },
    ],
    false
);

export const QUICK_FIELDS = getFields(
    [
        FIELDS.type,
        FIELDS.status,
        FIELDS.customer_facing_id,
        FIELDS.start_date,
        FIELDS.end_date,
        FIELDS.automatically_renew,
        FIELDS.account_id,
        FIELDS.property_id,
        FIELDS['3rd_party_id'],
        FIELDS.go_cardless_mandate_id,
        FIELDS.go_cardless_subscription_id,
        FIELDS.go_cardless_customer_id,
        FIELDS.package_id,
        FIELDS.billing_type,
        FIELDS.contribution,
        FIELDS.payment_amount,
        FIELDS.pricebook_id,
        FIELDS.promo_code_id,
        FIELDS.referral,
        FIELDS.landingpage,
        FIELDS.http_referer,
        FIELDS.ga_cid,
        FIELDS.querystring,
        FIELDS.utm_campaign,
        FIELDS.utm_medium,
        FIELDS.utm_source,
        FIELDS.utm_term,
        FIELDS.utm_content,
    ],
    false
);
export const TITLE_FIELDS = [FIELDS.customer_facing_id];

const ACTIONS = [
    {
        name: 'Activate Contract',
        showAction: {
            key: 'status',
            values: [OPTIONS.CONTRACT_STATUS.PENDING],
        },
        actionType: 'positive',
        callFunction: (context) => {
            // eslint-disable-next-line no-restricted-globals, no-alert
            const confirmed = confirm(
                "Are you sure you want to activate this contract to be 'Live'? Please note that this will send a welcome email specifically for imported customers."
            );
            if (confirmed) {
                const itemComponent = document
                    .querySelector('ht-pandora-app')
                    .shadowRoot.querySelector('ht-pandora-item[type="contract"]');
                const contract = context.item;
                const date = new Date(Date.now());
                // Date string required to be in format "yyyy-mm-dd HH:mm:ss" for API and we don't have date-fns in this repo :(
                const dateString = `${date.toISOString().split('T')[0]} ${
                    date.toTimeString().split(' ')[0]
                }`;
                const postData = {
                    id: contract.id,
                    status:
                        new Date(contract.start_date) <= new Date(Date.now())
                            ? OPTIONS.CONTRACT_STATUS.LIVE
                            : OPTIONS.CONTRACT_STATUS.FUTURE,
                    triggerConfirmationEmail: true,
                    date_created: dateString,
                    can_raise_claim: true,
                };
                itemComponent._sendSaveItem(postData);
            }
        },
    },
    {
        name: 'Cancel Contract',
        showAction: {
            key: 'status',
            values: [
                OPTIONS.CONTRACT_STATUS.LIVE,
                OPTIONS.CONTRACT_STATUS.FUTURE,
                OPTIONS.CONTRACT_STATUS.WEBSITE_PENDING,
                OPTIONS.CONTRACT_STATUS.SIGNATURE_REQUIRED,
                OPTIONS.CONTRACT_STATUS.EXPIRED_DUE_TO_PAYMENT_ISSUES,
                OPTIONS.CONTRACT_STATUS.LAPSED,
                OPTIONS.CONTRACT_STATUS.UNCONTACTABLE,
            ],
        },
        actionType: 'danger',
        callFunction: (context) => {
            store.dispatch(addEditableItem(context.item, 'contract', ACTION_CANCELLATION));
        },
    },
    {
        name: 'Reinstate Contract',
        showAction: {
            key: 'status',
            values: [
                OPTIONS.CONTRACT_STATUS.CANCELLED,
                OPTIONS.CONTRACT_STATUS.EXPIRED,
                OPTIONS.CONTRACT_STATUS.EXPIRED_DUE_TO_PAYMENT_ISSUES,
                OPTIONS.CONTRACT_STATUS.LAPSED,
                OPTIONS.CONTRACT_STATUS.UNCONTACTABLE,
            ],
        },
        actionType: 'danger',
        callFunction: (context) => {
            // eslint-disable-next-line no-restricted-globals, no-alert
            const confirmed = confirm(
                "Are you sure you want to convert this Contract to be 'Live'? P.S. Please note that if the account is not active yet, you should update it accordingly."
            );
            if (confirmed) {
                const itemComponent = document
                    .querySelector('ht-pandora-app')
                    .shadowRoot.querySelector('ht-pandora-item[type="contract"]');
                const contract = context.item;
                const postData = {
                    id: contract.id,
                    status: OPTIONS.CONTRACT_STATUS.LIVE,
                    triggerConfirmationEmail: false,
                    cancellation_type: null,
                    cancellation_description: null,
                };
                itemComponent._sendSaveItem(postData);
            }
        },
    },
    {
        name: 'Close Abandoned',
        showAction: {
            key: 'status',
            values: [OPTIONS.CONTRACT_STATUS.ABANDONED],
        },
        actionType: 'danger',
        callFunction: (context) => {
            // eslint-disable-next-line no-restricted-globals, no-alert
            const confirmed = confirm(
                "Are you sure you want to convert this Contract to be 'Closed Abandoned'. This will remove the linked accounts email address from the Abandoned Basket emailing process."
            );
            if (confirmed) {
                const itemComponent = document
                    .querySelector('ht-pandora-app')
                    .shadowRoot.querySelector('ht-pandora-item[type="contract"]');
                const contract = context.item;
                const postData = {
                    id: contract.id,
                    status: OPTIONS.CONTRACT_STATUS.ABANDONED_CLOSED,
                };
                itemComponent._sendSaveItem(postData);
            }
        },
    },
    {
        name: 'Change Dates',
        showAction: {
            key: 'status',
            values: [
                OPTIONS.CONTRACT_STATUS.LIVE,
                OPTIONS.CONTRACT_STATUS.FUTURE,
                OPTIONS.CONTRACT_STATUS.WEBSITE_PENDING,
                OPTIONS.CONTRACT_STATUS.SIGNATURE_REQUIRED,
            ],
        },
        actionType: 'info',
        callFunction: (context) => {
            store.dispatch(addEditableItem(context.item, 'contract', ACTION_DATE_CHANGE));
        },
    },
];

export const ACTION_FIELDS = {};
ACTION_FIELDS[ACTION_DATE_CHANGE] = getActionFields([FIELDS.start_date, FIELDS.end_date]);

ACTION_FIELDS[ACTION_CANCELLATION] = getActionFields([
    FIELDS.cancellation_status,
    FIELDS.cancellation_type,
    FIELDS.cancellation_description,
    FIELDS.cancel_gocardless,
    FIELDS.cancel_chargebee,
]);

export const PAGE = {
    'list-url': ROUTES.CONTRACTS,
    'item-url': ROUTES.CONTRACT,
    'new-url': ROUTES.CONTRACT_NEW,
    endpoint: config.contractAPI,
    'item-view': ITEM,
    'quick-fields': QUICK_FIELDS,
    type: 'contract',
    actions: ACTIONS,
    'list-page': {
        title: 'Contracts',
        showAcitveFlag: false,
    },
    'item-page': {
        expansions:
            'property,account,package,pricebook,notes,promocode,referral,product_to_contract,renewals,claims,claimdb_claims',
        paymentStatus: 'true',
        claimSource: 'BDX',
        preRelations: ['renewals', 'claimdb_claims', 'claims'],
    },
    'new-page': {
        title: 'New Contract',
        action: '/create',
        documentation:
            'https://app.nuclino.com/Hometree/General/Contract-c2ae2cc7-11b7-46d7-8c59-c2a40010d99c',
    },
    linkreact: ROUTES.CLAIM_REACT,
};
