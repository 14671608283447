import { LitElement, html, css } from 'lit-element';

// These are the shared styles needed by this element.
import { htPandoraEditableStyles } from './styles/ht-pandora-editable-styles';
import { UPDATE_WARNINGS } from '../constants/updateWarnings';
import { OPTIONS } from '../constants/options';
import { config } from '../functions/config';
import { postDataFactory } from '../functions/postDataFactory';

import './htPandoraFormWrapper';

class htPandoraEditableRenewalCancel extends LitElement {
    static get properties() {
        return {
            renewal: { type: Object },
            fields: { type: Array },
            _complete: { type: Boolean }
        };
    }

    constructor() {
        super();
        this.postData = postDataFactory({ fetch: window.fetch, apiEndPoint: `${config.renewalAPI}` });
        this._complete = false;
        this.fields = [];
    }

    _submitRenewalEdit() {
        const formData = JSON.parse(this.shadowRoot.querySelector('ht-pandora-form-wrapper').getAttribute('formdata'));
        formData.id = this.renewal.id;

        // eslint-disable-next-line no-restricted-globals, no-alert
        const confirmWarningsAreOkay = confirm(`Are you sure you want to cancel this renewal. You cannot 'uncancel' (you would need to create a brand new renewal). ${UPDATE_WARNINGS.RENEWAL.RENEWAL_STATUS}`);

        if (!confirmWarningsAreOkay) {
            return;
        }

        formData.status = OPTIONS.RENEWAL_STATUS.CANCELLED;

        const sendUpdate = new CustomEvent('send-update', {
            detail: {
                changes: formData,
                table: 'renewal',
                post: this.postData,
                element: this
            }
        });

        this.dispatchEvent(sendUpdate);
    }

    _formCompleteToggle(e) {
        this._complete = e.detail.complete;
    }

    shouldUpdate() {
        if (this.renewal !== null) return true;
        return false;
    }

    _cancelEdits() {
        this.shadowRoot.querySelector('ht-pandora-form-wrapper').setAttribute('formdata', '{}');
    }

    _closeCancellation() {
        const closeEdit = new CustomEvent('close-edit', {
            detail: {
                element: this
            }
        });
        this.dispatchEvent(closeEdit);
    }

    static get styles() {
        return css`${htPandoraEditableStyles}`;
    }

    render() {
        return html`
            <div class="form-section-title">
                <h2>Cancelling renewal: ${this.renewal.id}</h2>
            </div>
            <form @submit="${e => e.preventDefault()}">
                <ht-pandora-form-wrapper
                    @form-complete="${this._formCompleteToggle}"
                    @form-data="${this._formDataUpdated}"
                    fields="${JSON.stringify(this.fields)}"
                    item="${JSON.stringify(this.renewal)}"
                ></ht-pandora-form-wrapper>
                <div class="buttons">
                    <button class="ht-button ht-button-secondary" type="button" @click="${() => this._closeCancellation()}">Back</button>
                    <button ?disabled="${!this._complete}" class="ht-button" type="button" @click="${() => this._submitRenewalEdit()}">Submit</button>
                </div>
            </form>
        `;
    }
}

window.customElements.define('ht-pandora-editable-renewal-cancel', htPandoraEditableRenewalCancel);
