import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { setPassiveTouchGestures } from '@polymer/polymer/lib/utils/settings';
import { LitElement, css, html } from 'lit-element';
import { connect } from 'pwa-helpers';
import { updateMetadata } from 'pwa-helpers/metadata';
import { installOfflineWatcher } from 'pwa-helpers/network';
import { installRouter } from 'pwa-helpers/router';
import { checkIfLoggedIn } from '../functions/awsCognito';

import { navigate, updateOffline } from '../actions/app.js';
import { PAGES } from '../constants/pages';
import { config } from '../functions/config';
import { store } from '../store.js';

// These are the elements needed by this element.
import { exportIcon } from './htPandoraIcons';
import './htPandoraNotificationController';

// Shared styles
import { htPandoraAppStyles } from './styles/ht-pandora-app-styles';

// Data
import { QUICK_FIELDS } from '../constants/fields';
import { ROUTES } from '../constants/routes';

import './htPandoraEditableItem';
import './htPandoraNavigation';
import './htPandoraWarningItems';

class htPandoraApp extends connect(store)(LitElement) {
    static get properties() {
        return {
            _offline: { type: Boolean },
            _page: {
                type: String,
                reflect: true,
            },
            isLoading: { type: Boolean },
            notifications: { type: Array },
            state: { type: Object },
            selectedItem: { type: Object },
            loggedIn: { type: Boolean },
            editableItem: { type: Object },
            editableRenewal: { type: Object },
            environment: { type: String },
        };
    }

    constructor() {
        super();

        checkIfLoggedIn();
        this.environment = config.environment;

        this.itemPages = PAGES;

        // See https://www.polymer-project.org/2.0/docs/devguide/gesture-events#use-passive-gesture-listeners
        setPassiveTouchGestures(true);
    }

    _stateChanged(state) {
        this.selectedItem = state.app.selectedItem;
        this._offline = state.app.offline;
        this._page = state.app.page;
        this.appTitle = state.app.appTitle;
        this.isLoading = state.app.isLoading;
        this.customers = state.app.customers;
        this.recentlyViewedCustomers = state.app.recentlyViewedCustomers;
        this.loggedIn = state.app.loggedIn;
        this.editableItem = state.app.editableItem;
        this.editableRenewal = state.app.editableRenewal;
    }

    firstUpdated() {
        installOfflineWatcher((offline) => store.dispatch(updateOffline(offline)));
        installRouter((location) =>
            store.dispatch(navigate(decodeURIComponent(location.pathname), false))
        );

        this._respondingToJob = false;
        this.shadowRoot
            .querySelector('.main-content')
            .addEventListener('ht-pandora-open-accordion-list-modal', (e) =>
                this._openImageSliderModal(e)
            );

        // Redirect user to login screen if their token has expired / are not logged in
        // TODO add in authetication fo logins
        // if (!this._isAuthenticated() && window.location.pathname !== ROUTES.LOGIN) {
        //     this._handleAuthRedirect();
        // }

        if (this._offline) {
            // this._addNotification(this.staticData.notifications.find(d => d.notificationId === 'offline'), 'not-offline'); //TODO offline notifications?
        }

        const backToTopButton = this.shadowRoot.querySelector('.back-to-top');
        if (backToTopButton) {
            window.onscroll = () => {
                this._setupScrollTopButton(backToTopButton);
            };
        }
    }

    updated(changedProps) {
        super.updated(changedProps);

        if (changedProps === null) {
            return;
        }

        if ('_page' in changedProps) {
            const pageTitle = `${props.appTitle} - ${changedProps._page}`;
            updateMetadata({
                title: pageTitle,
                description: pageTitle,
            });
        }
    }

    _setupScrollTopButton(backToTopButton) {
        if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
            backToTopButton.classList.add('active');
        } else {
            backToTopButton.classList.remove('active');
        }
    }

    _renderListPage(item) {
        return html`
            <ht-pandora-list-page
                class="page"
                ?active="${this._page === item['list-url']}"
                endpoint="${item.endpoint}"
                link="${item['item-url']}"
                linkreact="${item.linkreact || ''}"
                environment="${this.environment}"
                tableHeaders="${JSON.stringify(item['quick-fields'])}"
                action="${item['list-page'].action ? item['list-page'].action : ''}"
                expansions="${item['list-page'].expansions ? item['list-page'].expansions : ''}"
                addnewhref="${item['new-url']}"
                title="${item['list-page'].title}"
                type="${item.type}"
                ?showacitveflag="${item['list-page'].showAcitveFlag}"
            >
            </ht-pandora-list-page>
        `;
    }

    _renderItemPage(item) {
        return html`
            <ht-pandora-item
                class="page"
                type="${item.type}"
                title="${item['item-page'].title ? item['item-page'].title : ''}"
                selected="${this.selectedItem[item['item-url']]}"
                itemview="${JSON.stringify(item['item-view'])}"
                redirecturl="${item['list-url'] ? item['list-url'] : ''}"
                newredirecturl="${item['new-url'] ? item['new-url'] : ''}"
                action="${item['item-page'].action ? item['item-page'].action : ''}"
                expansions="${item['item-page'].expansions ? item['item-page'].expansions : ''}"
                paymentstatus="${item['item-page'].paymentStatus
                    ? item['item-page'].paymentStatus
                    : ''}"
                claimsource="${item['item-page'].claimSource ? item['item-page'].claimSource : ''}"
                readonlyexpansions="${item['item-page']['readonly-expansions']
                    ? item['item-page']['readonly-expansions']
                    : ''}"
                prerelations="${item['item-page'].preRelations
                    ? JSON.stringify(item['item-page'].preRelations)
                    : '[]'}"
                ?active="${this._page === item['item-url']}"
                pageurl="${item['item-url']}"
                endpoint="${item.endpoint}"
                apiEndpoints="${item.apiEndpoints ? item.apiEndpoints : ''}"
                ?itemeditingallowed="${typeof item['item-page'].itemEditingAllowed !== 'undefined'
                    ? item['item-page'].itemEditingAllowed
                    : true}"
                .actions="${item.actions ? item.actions : []}"
                environment="${this.environment}"
                linkreact="${item.linkreact || ''}"
            >
            </ht-pandora-item>
        `;
    }

    _renderNewPage(item) {
        return html`
            <ht-pandora-new-item
                title="${item['new-page'].title}"
                itemview="${JSON.stringify(item['item-view'])}"
                class="page"
                action="${item['new-page'].action}"
                posttype="${item.type}"
                successurl="${item['item-url']}"
                ?active="${this._page === item['new-url']}"
                pageurl="${item['new-url']}"
                endpoint="${item.endpoint}"
                implicitfields="${item['implicit-fields']
                    ? JSON.stringify(item['implicit-fields'])
                    : JSON.stringify({})}"
                documentation="${item['new-page'].documentation
                    ? item['new-page'].documentation
                    : ''}"
            >
            </ht-pandora-new-item>
        `;
    }

    _backToTop() {
        this.shadowRoot
            .querySelector('.wrapper')
            .scrollIntoView({ block: 'start', behavior: 'smooth', inline: 'end' });
    }

    static get styles() {
        return css`
            ${htPandoraAppStyles}
        `;
    }

    render() {
        return html`
            <ht-pandora-warning-items></ht-pandora-warning-items>
            <div class="wrapper">
                <ht-pandora-navigation
                    environment="${this.environment}"
                    page="${this._page}"
                    loggedin="${this.loggedIn}"
                ></ht-pandora-navigation>
                <div class="main">
                    <!-- this.Notifications Controller -->
                    <ht-pandora-notification-controller
                        class="notification-controller"
                    ></ht-pandora-notification-controller>

                    <main
                        role="main"
                        class="main-content ${this.isLoading ? 'main-content--is-loading' : ''}"
                    >
                        <ht-pandora-home class="page" ?active="${this._page === ROUTES.HOME}">
                        </ht-pandora-home>

                        <!-- SEARCH -->
                        <ht-pandora-search-page
                            class="page"
                            propertyheaders="${JSON.stringify(QUICK_FIELDS.PROPERTY)}"
                            contractheaders="${JSON.stringify(QUICK_FIELDS.CONTRACT)}"
                            accountheaders="${JSON.stringify(QUICK_FIELDS.ACCOUNT)}"
                            ?active="${this._page === ROUTES.SEARCH}"
                        >
                        </ht-pandora-search-page>

                        ${this.itemPages.map(
                            (item) => html`
                                ${item['list-page'] ? this._renderListPage(item) : ''}
                                ${item['item-page'] ? this._renderItemPage(item) : ''}
                                ${item['new-page'] ? this._renderNewPage(item) : ''}
                            `
                        )}

                        <ht-pandora-analytics
                            class="page"
                            ?active="${this._page === ROUTES.ANALYTICS}"
                        ></ht-pandora-analytics>
                        <ht-pandora-404
                            class="page"
                            ?active="${this._page === ROUTES.NOT_FOUND}"
                        ></ht-pandora-404>
                        <ht-pandora-editable-item
                            ?active="${this.editableItem !== null && this.editableItem !== false}"
                        ></ht-pandora-editable-item>
                    </main>

                    ${this.isLoading ? html`<div class="loader loader--fullscreen"></div>` : null}
                </div>
            </div>
            <div class="back-to-top" @click="${() => this._backToTop()}">
                ${exportIcon('up')} Top
            </div>
        `;
    }
}

window.customElements.define('ht-pandora-app', htPandoraApp);
