import { config } from '../functions/config'
export const LINKS = {
    PMS_LINKS: [
        {
            name: 'Policy',
            show_field: '3rd_party_id',
            url: config.pmsPolicyURL,
            no_value_url: config.pmsListURL,
            no_value_name: 'Find Policy',
        },
        {
            name: 'Job',
            show_field: 'customer_facing_id',
            url: config.pmsJobURL,
            no_value_url: config.pmsJobURL,
            no_value_name: 'Find Job',
        },
    ],
    GO_CARDLESS_LINKS: [
        {
            name: 'Mandate',
            show_field: 'go_cardless_mandate_id',
            url: config.gcMandateURL,
            no_value_url: config.gcMandateURL,
            no_value_name: 'Find Mandate',
        },
        {
            name: 'Subscription',
            show_field: 'go_cardless_subscription_id',
            url: config.gcSubscriptionURL,
            no_value_url: config.gcSubscriptionURL,
            no_value_name: 'Find Subscription',
        },
        {
            name: 'Customer',
            show_field: 'go_cardless_customer_id',
            url: config.gcCustomerURL,
            no_value_url: config.gcCustomerURL,
            no_value_name: 'Find Customer',
        },
    ],
}
