import { getFieldsItem, getFields } from '../../functions/fieldProcessing'

const hiddenFields = ['author', 'date_modified']

export const FIELDS = {
    id: {
        key: 'id',
        label: 'Claim ID',
        type: 'text',
        required: false,
        disabled: false,
        width: '3',
    },
    type: {
        key: 'type',
        label: 'Type',
        type: 'text',
        required: false,
        disabled: false,
        width: '3',
    },
    status: {
        key: 'status',
        label: 'Status',
        type: 'text',
        required: false,
        disabled: false,
        width: '3',
    },
    sk_urgency: {
        key: 'sk_urgency',
        label: 'Urgency',
        type: 'text',
        required: false,
        disabled: false,
        width: '3',
    },
    claim_start_date: {
        key: 'claim_start_date',
        label: 'Start Date',
        type: 'date',
        required: false,
        disabled: false,
        width: '3',
    },
    sk_vulnerable_customer: {
        key: 'sk_vulnerable_customer',
        label: 'Vulnerable Customer',
        type: 'text',
        required: false,
        disabled: false,
        width: '3',
    },
    charge_total_cost: {
        key: 'charge_total_cost',
        label: 'Cost',
        type: 'price',
        required: false,
        disabled: false,
        width: '3',
    },
}

export const ITEM = getFieldsItem(
    [
        {
            section_name: 'General',
            hideSectionName: true,
            fields: Object.keys(FIELDS).map((fieldName) => FIELDS[fieldName]),
        },
    ],
    false
)

export const QUICK_FIELDS = getFields(
    Object.keys(FIELDS).map((fieldName) => FIELDS[fieldName]),
    false
).filter((field) => !hiddenFields.includes(field.key))

export const TITLE_FIELDS = []
