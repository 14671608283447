import { config } from '../../functions/config'
import { ROUTES } from '../routes'
import { getFieldsItem, getFields } from '../../functions/fieldProcessing'
import { LINKS } from '../links'

export const FIELDS = {
    customer_facing_id: {
        key: 'customer_facing_id',
        label: 'Account Number',
        type: 'account_customer_facing_id',
        required: false,
        disabled: true,
        width: '3',
    },
    first_name: {
        key: 'first_name',
        label: 'First Name',
        type: 'text',
        required: true,
        disabled: false,
        width: '3',
    },
    last_name: {
        key: 'last_name',
        label: 'Last Name',
        type: 'text',
        required: true,
        disabled: false,
        width: '3',
    },
    referral_code: {
        key: 'referral_code',
        label: 'Referral Code',
        type: 'text',
        required: false,
        disabled: true,
        width: '3',
    },
    sf_account_id: {
        key: 'sf_account_id',
        label: 'Salesforce Account Id',
        type: 'sf_account_id',
        required: false,
        disabled: false,
        width: '3',
    },
    is_test_account: {
        key: 'test',
        label: 'Test Account',
        checkedLabel: 'Is Test Account',
        uncheckedLabel: 'Is Regular Account',
        type: 'checkbox',
        required: false,
        disabled: false,
        width: '3',
    },
    email: {
        key: 'email',
        label: 'Email',
        type: 'email',
        required: true,
        disabled: false,
        width: '12',
    },
    marketingEmail: {
        key: 'marketing_email',
        label: 'Marketing Preferences',
        checkedLabel: 'Email',
        uncheckedLabel: 'Email',
        type: 'checkbox',
        required: false,
        disabled: false,
        width: '4',
    },
    marketingPhone: {
        key: 'marketing_phone',
        label: '',
        checkedLabel: 'Phone',
        uncheckedLabel: 'Phone',
        type: 'checkbox',
        required: false,
        disabled: false,
        width: '4',
    },
    marketingSms: {
        key: 'marketing_sms',
        label: '',
        checkedLabel: 'SMS',
        uncheckedLabel: 'SMS',
        type: 'checkbox',
        required: false,
        disabled: false,
        width: '4',
    },
    phone1_prefix: {
        key: 'phone1_prefix',
        label: 'Prefix',
        type: 'text', // Change to phone prefix type TODO
        required: false,
        disabled: false,
        width: '1',
    },
    phone1: {
        key: 'phone1',
        label: 'Phone 1',
        type: 'phone',
        required: false,
        disabled: false,
        width: '5',
    },
    phone2_prefix: {
        key: 'phone2_prefix',
        label: 'Prefix',
        type: 'text', // Change to phone prefix type TODO
        required: false,
        disabled: false,
        width: '1',
    },
    phone2: {
        key: 'phone2',
        label: 'Phone 2',
        type: 'phone',
        required: false,
        disabled: false,
        width: '5',
    },
    correspondence_property_id: {
        key: 'correspondence_property_id',
        label: 'Correspondence Property',
        type: 'property_id',
        required: false,
        disabled: false,
        width: '12',
        linkedItem: 'correspondence_property',
        linkedItemFieldsReference: 'PROPERTY',
        linkUrl: ROUTES.PROPERTY,
    },
    third_party_relations: {
        key: 'account_third_parties',
        label: 'Third Parties',
        type: 'third_party_relation',
        required: false,
        disabled: true,
        width: '3',
        height: '2'
    }
}

export const ITEM = getFieldsItem([
    {
        section_name: 'General',
        hideSectionName: true,
        fields: [
            FIELDS.customer_facing_id,
            FIELDS.first_name,
            FIELDS.last_name,
            FIELDS.third_party_relations,
            FIELDS.referral_code,
            FIELDS.sf_account_id,
            FIELDS.is_test_account,
            FIELDS.email,
            FIELDS.marketingEmail,
            FIELDS.marketingPhone,
            FIELDS.marketingSms,
        ],
    },
    {
        section_name: 'Phone 1',
        hideSectionName: true,
        fields: [FIELDS.phone1_prefix, FIELDS.phone1],
    },
    {
        section_name: 'Phone 2',
        hideSectionName: true,
        fields: [FIELDS.phone2_prefix, FIELDS.phone2],
    },
    {
        section_name: 'Correspondence Property',
        hideSectionName: true,
        fields: [FIELDS.correspondence_property_id],
    },
])

export const QUICK_FIELDS = getFields([
    FIELDS.customer_facing_id,
    FIELDS.first_name,
    FIELDS.last_name,
    FIELDS.referral_code,
    FIELDS.email,
    FIELDS.phone1_prefix,
    FIELDS.phone1,
    FIELDS.correspondence_property_id,
    FIELDS.is_test_account,
])

export const TITLE_FIELDS = [FIELDS.first_name, FIELDS.last_name]

export const PAGE = {
    'list-url': ROUTES.ACCOUNTS,
    'item-url': ROUTES.ACCOUNT,
    'new-url': ROUTES.ACCOUNT_NEW,
    endpoint: config.accountAPI,
    'item-view': ITEM,
    'quick-fields': QUICK_FIELDS,
    type: 'account',
    'list-page': {
        expansions: 'correspondence_property',
        title: 'Accounts',
        showAcitveFlag: true,
    },
    'item-page': {
        expansions:
            'correspondence_property,properties,contracts,notes,recruit_referrals,referrer_referrals,account_third_parties',
    },
    'new-page': {
        title: 'New Account',
        action: '/create',
        documentation:
            'https://app.nuclino.com/Hometree/General/Account-25cdd496-d9ee-4b14-a878-e73067f5ecdf',
    },
}
