import {
    FIELDS as ACCOUNT_TO_PROPERTY,
    QUICK_FIELDS as ACCOUNT_TO_PROPERTY_QUICK,
    TITLE_FIELDS as ACCOUNT_TO_PROPERTY_TINY,
} from './tables/account_to_property'
import {
    FIELDS as ACCOUNT,
    QUICK_FIELDS as ACCOUNT_QUICK,
    TITLE_FIELDS as ACCOUNT_TINY,
} from './tables/account'
import {
    FIELDS as CONTRACT,
    QUICK_FIELDS as CONTRACT_QUICK,
    TITLE_FIELDS as CONTRACT_TINY,
    ACTION_FIELDS as CONTRACT_ACTIONS,
} from './tables/contract'
import {
    FIELDS as CONTROLLER,
    QUICK_FIELDS as CONTROLLER_QUICK,
    TITLE_FIELDS as CONTROLLER_TINY,
} from './tables/controller'
import {
    FIELDS as NOTE,
    QUICK_FIELDS as NOTE_QUICK,
    TITLE_FIELDS as NOTE_TINY,
} from './tables/note'
import {
    FIELDS as PRODUCT_PACKAGE,
    QUICK_FIELDS as PRODUCT_PACKAGE_QUICK,
    TITLE_FIELDS as PRODUCT_PACKAGE_TINY,
} from './tables/product_package'
import {
    FIELDS as PRICEBOOK_ENTRY,
    QUICK_FIELDS as PRICEBOOK_ENTRY_QUICK,
    TITLE_FIELDS as PRICEBOOK_ENTRY_TINY,
} from './tables/pricebook_entry'
import {
    FIELDS as PRICEBOOK,
    QUICK_FIELDS as PRICEBOOK_QUICK,
    TITLE_FIELDS as PRICEBOOK_TINY,
} from './tables/pricebook'
import {
    FIELDS as PRODUCT_TO_CONTRACT,
    QUICK_FIELDS as PRODUCT_TO_CONTRACT_QUICK,
    TITLE_FIELDS as PRODUCT_TO_CONTRACT_TINY,
} from './tables/product_to_contract'
import {
    FIELDS as PRODUCT_TO_RENEWAL,
    QUICK_FIELDS as PRODUCT_TO_RENEWAL_QUICK,
    TITLE_FIELDS as PRODUCT_TO_RENEWAL_TINY,
} from './tables/product_to_renewal'
import {
    FIELDS as PRODUCT_TO_PACKAGE,
    QUICK_FIELDS as PRODUCT_TO_PACKAGE_QUICK,
    TITLE_FIELDS as PRODUCT_TO_PACKAGE_TINY,
} from './tables/product_to_package'
import {
    FIELDS as PRODUCT_TO_PROMOCODE,
    QUICK_FIELDS as PRODUCT_TO_PROMOCODE_QUICK,
    TITLE_FIELDS as PRODUCT_TO_PROMOCODE_TINY,
} from './tables/product_to_promocode'
import {
    FIELDS as PACKAGE_TO_PROMOCODE,
    QUICK_FIELDS as PACKAGE_TO_PROMOCODE_QUICK,
    TITLE_FIELDS as PACKAGE_TO_PROMOCODE_TINY,
} from './tables/package_to_promocode'
import {
    FIELDS as PRODUCT,
    QUICK_FIELDS as PRODUCT_QUICK,
    TITLE_FIELDS as PRODUCT_TINY,
} from './tables/product'
import {
    FIELDS as PROMOCODE_FAMILY,
    QUICK_FIELDS as PROMOCODE_FAMILY_QUICK,
    TITLE_FIELDS as PROMOCODE_FAMILY_TINY,
} from './tables/promocode_family'
import {
    FIELDS as PROMOCODE_TYPE,
    QUICK_FIELDS as PROMOCODE_TYPE_QUICK,
    TITLE_FIELDS as PROMOCODE_TYPE_TINY,
} from './tables/promocode_type'
import {
    FIELDS as PROMOCODE,
    QUICK_FIELDS as PROMOCODE_QUICK,
    TITLE_FIELDS as PROMOCODE_TINY,
} from './tables/promocode'
import {
    FIELDS as PROPERTY,
    QUICK_FIELDS as PROPERTY_QUICK,
    TITLE_FIELDS as PROPERTY_TINY,
} from './tables/property'
import {
    FIELDS as REFERRAL,
    QUICK_FIELDS as REFERRAL_QUICK,
    TITLE_FIELDS as REFERRAL_TINY,
} from './tables/referral'
import {
    FIELDS as RENEWAL,
    QUICK_FIELDS as RENEWAL_QUICK,
    TITLE_FIELDS as RENEWAL_TINY,
    ACTION_FIELDS as RENEWAL_ACTIONS,
} from './tables/renewal'
import {
    FIELDS as CLAIM,
    QUICK_FIELDS as CLAIM_QUICK,
    TITLE_FIELDS as CLAIM_TINY,
} from './tables/claim'
import {
    FIELDS as CLAIMDB_CLAIM,
    QUICK_FIELDS as CLAIMDB_CLAIM_QUICK,
    TITLE_FIELDS as CLAIMDB_CLAIM_TINY,
} from './tables/claimdb_claim'
import { FIELDS as GC_SUBSCRIPTION } from './tables/gc_subscription'
import { FIELDS as GC_PAYMENT } from './tables/gc_payment'
import { FIELDS as GC_CUSTOMER } from './tables/gc_customer'
import { FIELDS as CB_CUSTOMER } from './tables/cb_customer'
import { FIELDS as CB_SUBSCRIPTION } from './tables/cb_subscription'

export const FIELDS = {
    ACCOUNT_TO_PROPERTY,
    ACCOUNT,
    CONTRACT,
    CONTROLLER,
    NOTE,
    PRODUCT_PACKAGE,
    PRICEBOOK_ENTRY,
    PRICEBOOK,
    PRODUCT_TO_CONTRACT,
    PRODUCT_TO_RENEWAL,
    PRODUCT_TO_PACKAGE,
    PRODUCT_TO_PROMOCODE,
    PACKAGE_TO_PROMOCODE,
    PRODUCT,
    PROMOCODE_FAMILY,
    PROMOCODE_TYPE,
    PROMOCODE,
    PROPERTY,
    REFERRAL,
    RENEWAL,
    GC_SUBSCRIPTION,
    GC_PAYMENT,
    GC_CUSTOMER,
    CB_CUSTOMER,
    CB_SUBSCRIPTION,
    CLAIM,
    CLAIMDB_CLAIM,
}

export const ACTION_FIELDS = {
    RENEWAL: RENEWAL_ACTIONS,
    CONTRACT: CONTRACT_ACTIONS,
}

export const QUICK_FIELDS = {
    ACCOUNT_TO_PROPERTY: ACCOUNT_TO_PROPERTY_QUICK,
    ACCOUNT: ACCOUNT_QUICK,
    CONTRACT: CONTRACT_QUICK,
    CONTROLLER: CONTROLLER_QUICK,
    NOTE: NOTE_QUICK,
    PRODUCT_PACKAGE: PRODUCT_PACKAGE_QUICK,
    PRICEBOOK_ENTRY: PRICEBOOK_ENTRY_QUICK,
    PRICEBOOK: PRICEBOOK_QUICK,
    PRODUCT_TO_CONTRACT: PRODUCT_TO_CONTRACT_QUICK,
    PRODUCT_TO_RENEWAL: PRODUCT_TO_RENEWAL_QUICK,
    PRODUCT_TO_PACKAGE: PRODUCT_TO_PACKAGE_QUICK,
    PRODUCT_TO_PROMOCODE: PRODUCT_TO_PROMOCODE_QUICK,
    PACKAGE_TO_PROMOCODE: PACKAGE_TO_PROMOCODE_QUICK,
    PRODUCT: PRODUCT_QUICK,
    PROMOCODE_FAMILY: PROMOCODE_FAMILY_QUICK,
    PROMOCODE_TYPE: PROMOCODE_TYPE_QUICK,
    PROMOCODE: PROMOCODE_QUICK,
    PROPERTY: PROPERTY_QUICK,
    REFERRAL: REFERRAL_QUICK,
    RENEWAL: RENEWAL_QUICK,
    CLAIM: CLAIM_QUICK,
    CLAIMDB_CLAIM: CLAIMDB_CLAIM_QUICK,
}

export const TITLE_FIELDS = {
    ACCOUNT_TO_PROPERTY: ACCOUNT_TO_PROPERTY_TINY,
    ACCOUNT: ACCOUNT_TINY,
    CONTRACT: CONTRACT_TINY,
    CONTROLLER: CONTROLLER_TINY,
    NOTE: NOTE_TINY,
    PRODUCT_PACKAGE: PRODUCT_PACKAGE_TINY,
    PRICEBOOK_ENTRY: PRICEBOOK_ENTRY_TINY,
    PRICEBOOK: PRICEBOOK_TINY,
    PRODUCT_TO_CONTRACT: PRODUCT_TO_CONTRACT_TINY,
    PRODUCT_TO_RENEWAL: PRODUCT_TO_RENEWAL_TINY,
    PRODUCT_TO_PACKAGE: PRODUCT_TO_PACKAGE_TINY,
    PRODUCT_TO_PROMOCODE: PRODUCT_TO_PROMOCODE_TINY,
    PACKAGE_TO_PROMOCODE: PACKAGE_TO_PROMOCODE_TINY,
    PRODUCT: PRODUCT_TINY,
    PROMOCODE_FAMILY: PROMOCODE_FAMILY_TINY,
    PROMOCODE_TYPE: PROMOCODE_TYPE_TINY,
    PROMOCODE: PROMOCODE_TINY,
    PROPERTY: PROPERTY_TINY,
    REFERRAL: REFERRAL_TINY,
    RENEWAL: RENEWAL_TINY,
    CLAIM: CLAIM_TINY,
    CLAIMDB_CLAIM: CLAIMDB_CLAIM_TINY,
}
