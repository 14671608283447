import { config } from '../../functions/config';
import { ROUTES } from '../routes';
import { getFieldsItem, getFields } from '../../functions/fieldProcessing';

export const FIELDS = {
    "package_id": {
        key: 'package_id',
        label: 'Package',
        type: 'package',
        required: true,
        disabled: false,
        width: '12',
        linkedItem: 'package',
        linkedItemFieldsReference: 'PACKAGE',
        linkUrl: ROUTES.PACKAGE
    },
    "promocode_id": {
        key: 'promocode_id',
        label: 'Promocode',
        type: 'promo_code_id',
        required: true,
        disabled: false,
        width: '12',
        linkedItem: 'promocode',
        linkedItemFieldsReference: 'PROMO_CODE',
        linkUrl: ROUTES.PROMO_CODE
    }
};

export const ITEM = getFieldsItem([
    {
        section_name: "General",
        hideSectionName: true,
        fields: [
            FIELDS.package_id,
            FIELDS.promocode_id
        ]
    }]);

export const QUICK_FIELDS = getFields([
    FIELDS.package_id,
    FIELDS.promocode_id
]);

export const TITLE_FIELDS = [];

export const PAGE = {
    'list-url': ROUTES.PACKAGE_TO_PROMOCODES,
    'item-url': ROUTES.PACKAGE_TO_PROMOCODE,
    'new-url': ROUTES.PACKAGE_TO_PROMOCODE_NEW,
    'endpoint': config.packageAPI,
    'item-view': ITEM,
    'quick-fields': QUICK_FIELDS,
    'type': 'package_to_promocode',
    'list-page': {
        'action': '/promocodes/attachments',
        'title': 'Promocode Packages',
        'showAcitveFlag': true
    },
    'item-page': {
        'action': '/promocodes/attachments',
    },
    'new-page': {
        'title': 'New Package to Package',
        'action': '/promocodes/attachments/create'
    }
};