import { LitElement, html, css } from 'lit-element'

// These are the shared styles needed by this element.
import { htPandoraEditableStyles } from './styles/ht-pandora-editable-styles'
import { ROUTES } from '../constants/routes'
import { UPDATE_WARNINGS } from '../constants/updateWarnings'
import { LINKS } from '../constants/links'
import { OPTIONS } from '../constants/options'
import { config } from '../functions/config'
import { postDataFactory } from '../functions/postDataFactory'
import { createNotificationObj } from '../functions/notificationFactory'
import { changePage } from '../functions/changePage'
import { getFieldSectionLinks } from '../functions/fieldSectionLink'

import './htPandoraFormWrapper'

class htPandoraEditableContractCancel extends LitElement {
    static get properties() {
        return {
            contract: { type: Object },
            fields: { type: Array },
            _complete: { type: Boolean },
        }
    }

    constructor() {
        super()
        this.postData = postDataFactory({
            fetch: window.fetch,
            apiEndPoint: `${config.contractAPI}`,
        })
        this._complete = false
        this.fields = []
    }

    _submitContractEdit() {
        const formData = JSON.parse(
            this.shadowRoot.querySelector('ht-pandora-form-wrapper').getAttribute('formdata')
        )
        formData.id = this.contract.id

        const confirmWarningsAreOkay = confirm(
            `Are you sure you want to cancel this contract. You cannot 'uncancel' (you would need to create a brand new contract). ${UPDATE_WARNINGS.CONTRACT.CONTRACT_STATUS}`
        )
        if (!confirmWarningsAreOkay) {
            return
        }

        const sendCancel = new CustomEvent('send-cancel', {
            detail: {
                changes: formData,
                table: 'contract',
                post: this.postData,
                element: this,
            },
        })
        this.dispatchEvent(sendCancel)
    }

    _formCompleteToggle(e) {
        this._complete = e.detail.complete
    }

    shouldUpdate() {
        if (this.contract !== null) return true
        return false
    }

    _cancelEdits() {
        this.shadowRoot.querySelector('ht-pandora-form-wrapper').setAttribute('formdata', '{}')
    }

    _closeCancellation() {
        const closeEdit = new CustomEvent('close-edit', {
            detail: {
                element: this,
            },
        })
        this.dispatchEvent(closeEdit)
    }

    static get styles() {
        return css`
            ${htPandoraEditableStyles}
        `
    }

    render() {
        return html`
            <div class="form-section-title">
                <h2>Cancelling: ${this.contract.customer_facing_id}</h2>
                ${getFieldSectionLinks(
                    [...LINKS.PMS_LINKS, ...LINKS.GO_CARDLESS_LINKS],
                    this.contract
                )}
            </div>

            <form @submit="${(e) => e.preventDefault()}">
                <ht-pandora-form-wrapper
                    @form-complete="${this._formCompleteToggle}"
                    @form-data="${this._formDataUpdated}"
                    fields="${JSON.stringify(this.fields)}"
                    item="${JSON.stringify(this.contract)}"
                ></ht-pandora-form-wrapper>
                <div class="buttons">
                    <button
                        class="ht-button ht-button-secondary"
                        type="button"
                        @click="${() => this._closeCancellation()}"
                    >
                        Back
                    </button>
                    <button
                        ?disabled="${!this._complete}"
                        class="ht-button"
                        type="button"
                        @click="${() => this._submitContractEdit()}"
                    >
                        Submit
                    </button>
                </div>
            </form>
        `
    }
}

window.customElements.define('ht-pandora-editable-contract-cancel', htPandoraEditableContractCancel)
