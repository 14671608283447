import { config } from '../../functions/config'
import { ROUTES } from '../routes'
import { getFieldsItem, getFields } from '../../functions/fieldProcessing'

export const FIELDS = {
    name: {
        key: 'name',
        label: 'Name',
        type: 'text',
        required: true,
        disabled: true,
        tinyView: true,
        width: '4',
    },
    public: {
        key: 'public',
        label: '',
        checkedLabel: 'Public Package',
        uncheckedLabel: 'Hidden Package',
        type: 'checkbox',
        required: false,
        disabled: false,
        width: '4',
    },
    description: {
        key: 'description',
        label: 'Description',
        type: 'textarea',
        required: false,
        disabled: false,
        width: '12',
    },
    meta_title: {
        key: 'meta_title',
        label: 'Meta Title',
        type: 'text',
        required: false,
        disabled: false,
        width: '12',
    },
    meta_description: {
        key: 'meta_description',
        label: 'Meta Description',
        type: 'textarea',
        required: false,
        disabled: false,
        width: '12',
    },
    type: {
        key: 'type_id',
        label: 'Type',
        type: 'select',
        required: true,
        disabled: true,
        width: '4',
        options: [
            { name: 'Landlord', value: '1' },
            { name: 'Homeowner', value: '2' },
        ],
    },
}

export const ITEM = getFieldsItem([
    {
        section_name: 'General',
        hideSectionName: true,
        fields: [
            FIELDS.name,
            FIELDS.public,
            FIELDS.type,
            FIELDS.description,
            FIELDS.meta_title,
            FIELDS.meta_description,
        ],
    },
])

export const QUICK_FIELDS = getFields([
    FIELDS.name,
    FIELDS.public,
    FIELDS.type,
    FIELDS.description,
    FIELDS.meta_title,
    FIELDS.meta_description,
])

export const TITLE_FIELDS = [FIELDS.name]

export const PAGE = {
    'list-url': ROUTES.PRODUCT_PACKAGES,
    'item-url': ROUTES.PRODUCT_PACKAGE,
    'new-url': ROUTES.PRODUCT_PACKAGE_NEW,
    endpoint: config.packageAPI,
    'item-view': ITEM,
    'quick-fields': QUICK_FIELDS,
    type: 'package',
    'list-page': {
        title: 'Product Packages',
        showAcitveFlag: true,
    },
    'item-page': {
        expansions: 'pricebook_entries,products',
    },
    'new-page': {
        title: 'New Product Package',
        action: '/create',
    },
}
