import { TITLE_FIELDS } from '../constants/fields';
import { formatDate } from '../functions/formatDate';

export const getTableTitle = (tableName, item) => {
    // Had to do this since `package` is a reserved word in strict mode JS
    const fields = TITLE_FIELDS[tableName === 'PACKAGE' ? 'PRODUCT_PACKAGE' : tableName];
    
    let text = "";
    
    fields.forEach(field => {
        text = `${text !== "" ? `${text},` : ''} ${_getFieldValue(item[field.key], field.type)}`;
    });

    if(text === "") {
        return tableName;
    }
    return text;
};

const _getFieldValue = (currentValue, type) => {
    if(type === "date") {
        return formatDate(currentValue, 'text');
    }

    return currentValue;
};
