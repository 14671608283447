import { config } from '../../functions/config';
import { ROUTES } from '../routes';
import { getFieldsItem, getFields } from '../../functions/fieldProcessing';

export const FIELDS = {
    "product_id": {
        key: 'product_id',
        label: 'Product',
        type: 'product_id',
        required: true,
        disabled: true,
        width: '12',
        linkedItem: 'product',
        linkedItemFieldsReference: 'PRODUCT',
        linkUrl: ROUTES.PRODUCT
    },
    "renewal_id": {
        key: 'renewal_id',
        label: 'Renewal',
        type: 'number',
        required: true,
        disabled: true,
        width: '12',
        linkedItem: 'renewal',
        linkedItemFieldsReference: 'RENEWAL',
        linkUrl: ROUTES.RENEWAL
    },
    "quantity": {
        key: 'quantity',
        label: 'Quantity',
        type: 'number',
        required: true,
        disabled: true,
        width: '12'
    }
};

export const ITEM = getFieldsItem([
    {
        section_name: "General",
        hideSectionName: true,
        fields: [
            FIELDS.product_id,
            FIELDS.renewal_id,
            FIELDS.quantity
        ]
    }]);

export const QUICK_FIELDS = getFields([
    FIELDS.product_id,
    FIELDS.renewal_id,
    FIELDS.quantity
]);

export const TITLE_FIELDS = [];

export const PAGE = {
    'item-url': ROUTES.PRODUCT_TO_RENEWAL,
    'new-url': ROUTES.PRODUCT_TO_RENEWAL_NEW,
    'endpoint': 'product_to_renewal',
    'item-view': ITEM,
    'quick-fields': QUICK_FIELDS,
    'type': 'product_to_renewal',
    'item-page': {
        'action': '',
        'expansions': 'product,renewal'
    }
};