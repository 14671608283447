import { LitElement, html, css } from 'lit-element'

import { htPandoraWarningItemsStyles } from './styles/ht-pandora-warning-items-styles'

import { changePage } from '../functions/changePage'

import { connect } from 'pwa-helpers'
import { store } from '../store.js'

class htPandoraWarningItems extends connect(store)(LitElement) {
    static get properties() {
        return {
            _list: { type: Array },
            _page: { type: String },
        }
    }

    constructor() {
        super()

        this._list = []
    }

    _stateChanged(state) {
        this._list = state.app.warningItems
        this._page = state.app.page
    }

    _changeToEdit(link, id) {
        changePage(`${link}/${id}`)
    }

    static get styles() {
        return css`
            ${htPandoraWarningItemsStyles}
        `
    }

    render() {
        return html`
            ${this._list.map((item) => {
                if (this._page === item.pageurl) return
                return html`<div class="item item-${item.type}">
                    <p class="table">${item.type}: ${item.table}</p>
                    <p class="title">${item.title}</p>
                    <button
                        class="ht-button ht-button-white-transparent"
                        @click="${() => this._changeToEdit(item.pageurl, item.id)}"
                    >
                        Edit
                    </button>
                </div>`
            })}
        `
    }
}

window.customElements.define('ht-pandora-warning-items', htPandoraWarningItems)
