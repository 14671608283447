import { LitElement, html, css } from 'lit-element';

/* Styles */
import { htPandoraFormItemHistoryStyles } from './styles/ht-pandora-form-item-history-styles';

/* Functions */
import { _formatCurrency } from '../functions/moneyHelper';
import { exportIcon } from './htPandoraIcons';
import { formatDate } from '../functions/formatDate';

import './htPandoraFormSearchItem';

class htPandoraFormItemHistory extends LitElement {
    static get properties() {
        return {
            history: { type: Array },
            options: { type: Array }
        };
    }

    constructor() {
        super();

        this.history = [];
        this.options = [];
    }

    _getFormatedDate(dateTime) {
        return formatDate(dateTime, 'dateTime');
    }

    _getValue(value, options) {
        if(!options || options.length === 0) {
            return value;
        } else {
            const valueObject = options.filter(item => item.id === value);
            if(valueObject.length > 0) {
                return valueObject[0].name;
            } else {
                return value;
            }
        }
    }

    static get styles() {
        return css`${htPandoraFormItemHistoryStyles}`;
    }

    render() {
        return html`
            ${this.history && this.history.length > 0 ? html`
                <input type="checkbox" id="history-checkbox" class="history-checkbox" name="history-checkbox" />
                <label class="history-label" for="history-checkbox">${exportIcon('history')}</label>
                <ul class="history-list">
                    ${this.history.map(item => html`
                            <li class="history-item ${item.current ? 'history-item-current' : ''}">
                                <div class="history-header">
                                    <p class="history-time">${this._getFormatedDate(item.date)}</p>
                                    <p class="history-author">${item.author}</p>
                                </div>
                                <p class="history-value">${this._getValue(item.value, this.options)}</p>
                            </li>
                        `)}
                </ul>
            ` : ''}

        `;
    }
}

window.customElements.define('ht-pandora-form-item-history', htPandoraFormItemHistory);
