import { config } from '../../functions/config'
import { ROUTES } from '../routes'
import { getFieldsItem, getFields } from '../../functions/fieldProcessing'

export const FIELDS = {
    name: {
        key: 'name',
        label: 'Name',
        type: 'text',
        required: true,
        disabled: false,
        width: '5',
        documentation:
            'https://app.nuclino.com/Hometree/General/Pricebook-77be4fc0-cbe4-4164-8a1a-d1a7d590c730',
    },
    id: {
        key: 'id',
        label: 'ID',
        type: 'number',
        required: true,
        disabled: true,
        width: '5',
    },
}

export const ITEM = getFieldsItem([
    {
        section_name: 'General',
        hideSectionName: true,
        fields: [FIELDS.name, FIELDS.id],
    },
])

export const QUICK_FIELDS = getFields([FIELDS.name, FIELDS.id])
export const TITLE_FIELDS = [FIELDS.name]

export const PAGE = {
    'list-url': ROUTES.PRICEBOOKS,
    'item-url': ROUTES.PRICEBOOK,
    'new-url': ROUTES.PRICEBOOK_NEW,
    endpoint: config.priceBookAPI,
    apiEndpoints: config.apiEndpoints,
    'item-view': ITEM,
    'quick-fields': QUICK_FIELDS,
    type: 'pricebook',
    'list-page': {
        title: 'Pricebooks',
        showAcitveFlag: true,
    },
    'item-page': {
        expansions: 'pricebook_entries,promocodes',
    },
    'new-page': {
        title: 'New Pricebook',
        action: '/create',
    },
}
