export const FIELDS = [
    {
        key: 'status',
        label: 'Status',
        type: 'text',
    },
    {
        key: 'amount',
        label: 'Amount',
        type: 'price',
    },
    {
        key: 'billing_type',
        label: 'Billing Type',
        type: 'text',
    },
    {
        key: 'start_date',
        label: 'Start Date',
        type: 'date',
    },
]
