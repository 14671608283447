import { getRequestHeaders } from '../functions/getRequestHeaders'

export const getDataFactory =
    ({ fetch, apiEndPoint }) =>
    (urlData = '') => {
        const headers = getRequestHeaders()
        if (!headers) return

        return fetch(`${apiEndPoint}${urlData}`, {
            headers,
            method: 'GET',
            mode: 'cors',
        })
    }
