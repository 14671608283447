import { config } from '../../functions/config';
import { ROUTES } from '../routes';
import { getFieldsItem, getFields } from '../../functions/fieldProcessing';

export const FIELDS = {
    "price_nocontribution": {
        key: 'price_nocontribution',
        label: '0 Contribution',
        type: 'price',
        required: false,
        disabled: false,
        width: '4'
    },
    "price_60contribution": {
        key: 'price_60contribution',
        label: '60 Contribution',
        type: 'price',
        required: false,
        disabled: false,
        width: '4'
    },
    "price_95contribution": {
        key: 'price_95contribution',
        label: '95 Contribution',
        type: 'price',
        required: false,
        disabled: false,
        width: '4'
    },
    "price_95contribution_minimum": {
        key: 'price_95contribution_minimum',
        label: '95 Contribution Minimum price',
        type: 'price',
        required: false,
        disabled: false,
        width: '4'
    },
    "product_id": {
        key: 'product_id',
        label: 'Product',
        type: 'product_id',
        required: false,
        disabled: false,
        width: '12',
        linkedItem: 'product',
        linkedItemFieldsReference: 'PRODUCT',
        linkUrl: ROUTES.PRODUCT
    },
    "package_id": {
        key: 'package_id',
        label: 'Package',
        type: 'package',
        required: false,
        disabled: false,
        width: '12',
        linkedItem: 'package',
        linkedItemFieldsReference: 'PRODUCT_PACKAGE',
        linkUrl: ROUTES.PRODUCT_PACKAGE
    },
    "pricebook_id": {
        key: 'pricebook_id',
        label: 'Pricebook',
        type: 'pricebook_id',
        required: true,
        disabled: false,
        width: '12',
        linkedItem: 'pricebook',
        linkedItemFieldsReference: 'PRICEBOOK',
        linkUrl: ROUTES.PRICEBOOK
    }
};

export const ITEM = getFieldsItem([
    {
        section_name: "Contribution",
        fields: [
            FIELDS.price_nocontribution,
            FIELDS.price_60contribution,
            FIELDS.price_95contribution
        ]
    },
    {
        section_name: "Minimum prices for renewals",
        fields: [
            FIELDS.price_95contribution_minimum
        ]
    },
    {
        section_name: "Product OR Package",
        fields: [
            FIELDS.product_id,
            FIELDS.package_id
        ]
    },
    {
        section_name: "Pricebook",
        fields: [
            FIELDS.pricebook_id
        ]
    }
]);

export const QUICK_FIELDS = getFields([
    FIELDS.price_nocontribution,
    FIELDS.price_60contribution,
    FIELDS.price_95contribution,
    FIELDS.product_id,
    FIELDS.package_id,
    FIELDS.pricebook_id
]);
export const TITLE_FIELDS = [];

export const PAGE = {
    'list-url': ROUTES.PRICEBOOK_ENTRIES,
    'item-url': ROUTES.PRICEBOOK_ENTRY,
    'new-url': ROUTES.PRICEBOOK_ENTRY_NEW,
    'endpoint': config.priceBookAPI,
    'item-view': ITEM,
    'quick-fields': QUICK_FIELDS,
    'type': 'pricebook_entry',
    'list-page': {
        'action': '/entries',
        'expansions': 'package,pricebook,product',
        'title': 'Pricebook Entries',
        'showAcitveFlag': true
    },
    'item-page': {
        'expansions': 'package,pricebook,product',
        'action': '/entries'
    },
    'new-page': {
        'title': 'New Pricebook Entry',
        'action': '/entries/create'
    }
};