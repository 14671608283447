import { config } from '../../functions/config';
import { ROUTES } from '../routes';
import { getFieldsItem, getFields } from '../../functions/fieldProcessing';

const implicitFields = {
    country: 'United Kingdom'
}

export const FIELDS = {
    address_line_1: {
        key: 'address_line_1',
        label: 'Address line 1',
        type: 'text',
        required: true,
        disabled: false,
        width: '6'
    },
    address_line_2: {
        key: 'address_line_2',
        label: 'Address line 2',
        type: 'text',
        required: false,
        disabled: false,
        width: '6'
    },
    address_line_3: {
        key: 'address_line_3',
        label: 'Address line 3',
        type: 'text',
        required: false,
        disabled: false,
        width: '6'
    },
    town: {
        key: 'town',
        label: 'Town',
        type: 'text',
        required: true,
        disabled: false,
        width: '6'
    },
    postcode: {
        key: 'postcode',
        label: 'Postcode',
        type: 'text',
        required: true,
        disabled: false,
        width: '6'
    },
    last_service: {
        key: 'last_service',
        label: 'Last Service',
        type: 'date',
        required: false,
        disabled: true,
        width: '6'
    },
    next_service: {
        key: 'next_service',
        label: 'Next Service',
        type: 'date',
        required: false,
        disabled: true,
        width: '6'
    },
    boiler_gc_number: {
        key: 'boiler_gc_number',
        label: 'Boiler GC Number',
        type: 'text',
        required: false,
        disabled: true,
        width: '6'
    },
    boiler_make: {
        key: 'boiler_make',
        label: 'Boiler Make',
        type: 'boiler_make',
        required: false,
        disabled: true,
        width: '6'
    },
    boiler_model: {
        key: 'boiler_model',
        label: 'Boiler Model',
        type: 'boiler_model',
        required: false,
        disabled: true,
        width: '6'
    },
    boiler_age: {
        key: 'boiler_age',
        label: 'Boiler Age',
        type: 'select',
        required: true,
        disabled: false,
        width: '6',
        options: [
            { name: '1-3 years', value: '1-3' },
            { name: '3-5 years', value: '3-5' },
            { name: '5-10 years', value: '5-10' },
            { name: '10+ years', value: '10+' },
            { name: 'Not sure', value: 'not sure' },
        ]
    },
    parking_permit_needed: {
        key: 'parking_permit_needed',
        label: 'Parking permit needed',
        type: 'select',
        required: false,
        disabled: false,
        width: '6',
        options: [
            { name: 'Yes', value: 1 },
            { name: 'No', value: 0 },
        ]
    }
};

export const ITEM = getFieldsItem([
    {
        section_name: 'General',
        hideSectionName: true,
        fields: [
            FIELDS.address_line_1,
            FIELDS.address_line_2,
            FIELDS.address_line_3,
            FIELDS.town,
            FIELDS.postcode,
            FIELDS.last_service,
            FIELDS.next_service,
            FIELDS.boiler_gc_number,
            FIELDS.boiler_make,
            FIELDS.boiler_model,
            FIELDS.boiler_age,
            FIELDS.parking_permit_needed,
        ]
    }]);

export const QUICK_FIELDS = getFields([
    FIELDS.address_line_1,
    FIELDS.town,
    FIELDS.postcode,
    FIELDS.last_service,
    FIELDS.next_service,
    FIELDS.boiler_age,
]);

export const TITLE_FIELDS = [
    FIELDS.address_line_1,
    FIELDS.postcode
];

export const PAGE = {
    'list-url': ROUTES.PROPERTIES,
    'item-url': ROUTES.PROPERTY,
    'new-url': ROUTES.PROPERTY_NEW,
    endpoint: config.propertyAPI,
    'implicit-fields': implicitFields,
    'item-view': ITEM,
    'quick-fields': QUICK_FIELDS,
    type: 'property',
    'list-page': {
        title: 'Properties',
        showAcitveFlag: true
    },
    'item-page': {
        expansions: 'contracts,accounts,notes'
    },
    'new-page': {
        title: 'New Property',
        action: '/create',
        documentation: 'https://app.nuclino.com/Hometree/General/Property-39d41775-c64a-4f6a-91cb-a6ed07e5433e'
    }
};
