import { getRequestHeaders } from '../functions/getRequestHeaders';

export const postDataFactory = ({ fetch, apiEndPoint, implicitFields = {} }) => (urlData, postData) => {
    const headers = getRequestHeaders();
    if(!headers) return;

    return fetch(`${apiEndPoint}${urlData}`, {
        method: 'POST',
        body: JSON.stringify({ ...postData, ...implicitFields }),
        headers,
        mode: 'cors'
    });
};