import { config } from '../../functions/config';
import { ROUTES } from '../routes';
import { getFieldsItem, getFields } from '../../functions/fieldProcessing';

export const FIELDS = {
    "code": {
        key: 'code',
        label: 'Code',
        type: 'text',
        required: true,
        disabled: false,
        width: '3'
    },
    "type": {
        key: 'type',
        label: 'Type',
        type: 'promocode_type',
        required: true,
        disabled: false,
        width: '3'
    },
    "value": {
        key: 'value',
        label: 'Value',
        type: 'number',
        required: false,
        disabled: false,
        width: '3'
    },
    "family": {
        key: 'family',
        label: 'Family',
        type: 'promocode_family',
        required: true,
        disabled: false,
        width: '3'
    },
    "description": {
        key: 'description',
        label: 'Description',
        type: 'textarea',
        required: true,
        disabled: false,
        width: '12'
    },
    "expiry_date": {
        key: 'expiry_date',
        label: 'Expiry Date',
        type: 'date',
        required: true,
        disabled: false,
        width: '4'
    },
    "max_uses": {
        key: 'max_uses',
        label: 'Max Uses',
        type: 'number',
        required: true,
        disabled: false,
        width: '4'
    },
    "uses": {
        key: 'uses',
        label: 'Uses',
        type: 'number',
        required: true,
        disabled: false,
        width: '4'
    }
};

export const ITEM = getFieldsItem([
    {
        section_name: "General",
        hideSectionName: true,
        fields: [
            FIELDS.code,
            FIELDS.type,
            FIELDS.value,
            FIELDS.family,
            FIELDS.description,
            FIELDS.expiry_date,
            FIELDS.max_uses,
            FIELDS.uses
        ]
    }
]);

export const QUICK_FIELDS = getFields([
    FIELDS.code,
    FIELDS.type,
    FIELDS.value,
    FIELDS.family,
    FIELDS.description,
    FIELDS.expiry_date,
    FIELDS.max_uses,
    FIELDS.uses
]);

export const TITLE_FIELDS = [
    FIELDS.family
];

export const PAGE = {
    'list-url': ROUTES.PROMO_CODES,
    'item-url': ROUTES.PROMO_CODE,
    'new-url': ROUTES.PROMO_CODE_NEW,
    'endpoint': config.promoCodeAPI,
    'item-view': ITEM,
    'quick-fields': QUICK_FIELDS,
    'type': 'promocode',
    'list-page': {
        'expansions': 'pricebook',
        'title': 'Promo Codes',
        'showAcitveFlag': true
    },
    'item-page': {
        'expansions': 'pricebook,product_to_promocode,package_to_promocode,contracts',
        'action': ''
    },
    'new-page': {
        'title': 'New Promo Code',
        'action': '/create'
    }
};