import { config } from '../../functions/config';
import { ROUTES } from '../routes';
import { getFieldsItem, getFields } from '../../functions/fieldProcessing';

export const FIELDS = {
    "name": {
        key: 'name',
        label: 'Name',
        type: 'text',
        required: true,
        disabled: false,
        width: '4'
    },
    "description": {
        key: 'description',
        label: 'Description',
        type: 'textarea',
        required: true,
        disabled: false,
        width: '12'
    }
};

export const ITEM = getFieldsItem([
    {
        section_name: "General",
        hideSectionName: true,
        fields: [
            FIELDS.name,
            FIELDS.description
        ]
    }]);

export const QUICK_FIELDS = getFields([
    FIELDS.name,
    FIELDS.description
]);

export const TITLE_FIELDS = [
    FIELDS.name
];

export const PAGE = {
    'list-url': ROUTES.PROMO_CODE_FAMILIES,
    'item-url': ROUTES.PROMO_CODE_FAMILY,
    'new-url': ROUTES.PROMO_CODE_FAMILY_NEW,
    'endpoint': config.promoCodeAPI,
    'item-view': ITEM,
    'quick-fields': QUICK_FIELDS,
    'type': 'promoCodeFamily',
    'list-page': {
        'action': '/families',
        'title': 'Promo Code Families',
        'showAcitveFlag': true
    },
    'item-page': {
        'expansions': 'promocodes',
        'action': '/families'
    },
    'new-page': {
        'title': 'New Promo Code',
        'action': '/families/create'
    }
};