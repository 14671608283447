import { html } from 'lit-element';

const _getValueKeyValue = (key, item) => {
    if(item && item[key]) {
        return item[key];
    } else {
        return null;
    }
};

const _getFieldSectionLink = (link, item) => {
    const value = _getValueKeyValue(link.show_field, item);
    let linkToUse = link.url;
    let nameToUse = link.name;
    if(link.no_value_url && !value) {
        linkToUse = link.no_value_url;
        nameToUse = link.no_value_name;
    } else if (value) {
        linkToUse += value;
    }
    return html`<a target="_blank" class="ht-button ht-button-secondary" href="${linkToUse}">${nameToUse}</a>`;
}

export const getFieldSectionLinks = (links, item) => {
    return html`<div class="form-section-title-buttons">${links.map(link => _getFieldSectionLink(link, item))}</div>`
};
