import { config } from '../../functions/config';
import { ROUTES } from '../routes';
import { getFieldsItem, getFields } from '../../functions/fieldProcessing';

export const FIELDS = {
    "recruit_account_id": {
        key: 'recruit_account_id',
        label: 'Recruit Account',
        type: 'account_id',
        required: true,
        disabled: false,
        width: '12',
        linkedItem: 'recruit_account',
        linkedItemFieldsReference: 'ACCOUNT',
        linkUrl: ROUTES.ACCOUNT
    },
    "recruit_contract_id": {
        key: 'recruit_contract_id',
        label: 'Recruit Contract',
        type: 'contract_id',
        required: true,
        disabled: false,
        width: '12',
        linkedItem: 'recruit_contract',
        linkedItemFieldsReference: 'CONTRACT',
        linkUrl: ROUTES.CONTRACT
    },
    "recruit_voucher_amount": {
        key: 'recruit_voucher_amount',
        label: 'Recruit Voucher Amount',
        type: 'price',
        required: true,
        disabled: false,
        width: '4',
    },
    "referrer_account_id": {
        key: 'referrer_account_id',
        label: 'Referrer Account',
        type: 'account_id',
        required: true,
        disabled: false,
        width: '12',
        linkedItem: 'referrer_account',
        linkedItemFieldsReference: 'ACCOUNT',
        linkUrl: ROUTES.ACCOUNT
    },
    "referrer_voucher_amount": {
        key: 'referrer_voucher_amount',
        label: 'Referrer Voucher Amount',
        type: 'price',
        required: true,
        disabled: false,
        width: '4',
    }
};

export const ITEM = getFieldsItem([
    {
        section_name: "Recruit",
        fields: [
            FIELDS.recruit_account_id,
            FIELDS.recruit_contract_id,
            FIELDS.recruit_voucher_amount
        ],
    },
    {
        section_name: "Referrer",
        fields: [
            FIELDS.referrer_account_id,
            FIELDS.referrer_voucher_amount
        ],
    }]);

export const QUICK_FIELDS = getFields([
    FIELDS.recruit_account_id,
    FIELDS.recruit_contract_id,
    FIELDS.recruit_voucher_amount,
    FIELDS.referrer_account_id,
    FIELDS.referrer_voucher_amount
]);
export const TITLE_FIELDS = [];

export const PAGE = {
    'list-url': ROUTES.REFERRALS,
    'item-url': ROUTES.REFERRAL,
    'new-url': ROUTES.REFERRAL_NEW,
    'endpoint': config.accountAPI,
    'item-view': ITEM,
    'quick-fields': QUICK_FIELDS,
    'type': 'referral',
    'list-page': {
        'action': '/referrals',
        'title': 'Referrals',
        'showAcitveFlag': true,
        'expansions': 'referrer_account,recruit_account,recruit_contract'
    },
    'item-page': {
        'action': '/referrals',
        'expansions': 'referrer_account,recruit_account,recruit_contract'
    },
    'new-page': {
        'title': 'New Referral',
        'action': '/referrals/create',
    }
};