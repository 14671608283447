import { LitElement, html, css } from 'lit-element'
import { connect } from 'pwa-helpers'

/* Styles */
import { htPandoraFormWrapperStyles } from './styles/ht-pandora-form-wrapper-styles'

/* Functions */

/* Components */
import './htPandoraFormItem'
import './htPandoraQuickView'
import { QUICK_FIELDS } from '../constants/fields'

import { store } from '../store'
import { OPTIONS } from '../constants/options'

class htPandoraFormWrapper extends connect(store)(LitElement) {
    static get properties() {
        return {
            fields: { type: Array },
            formData: {
                type: Object,
                reflect: true,
            },
            forceCheck: {
                type: Boolean,
                reflect: true,
            },
            complete: {
                type: Boolean,
                reflect: true,
            },
            requiredFields: {
                type: Array,
                reflect: true,
            },
            item: { type: Object },
            forceDisabled: { type: Boolean },
            history: { type: Object },
            mode: {
                type: String,
                reflect: true,
            },
            _storeOptions: { type: Object },
        }
    }

    constructor() {
        super()

        this.forceCheck = false
        this.formData = {}
        this.requiredFields = []
        this.complete = false
        this.fields = []
        this.history = {}
        this.forceDisabled = false
        this.mode = 'light'
        this._storeOptions = {}
    }

    _stateChanged(state) {
        this._storeOptions = state.app.fieldOptions
    }

    firstUpdated() {
        this._getFormItem()
        this._dispatchFormData()
        this._checkForm()
    }

    _updateFormObject(e) {
        if (!this.forceDisabled) {
            const value = e.detail.value
            const field = e.detail.field
            // eslint-disable-next-line no-unused-vars
            const valid = e.detail.valid

            const newFormData = { ...this.formData }
            newFormData[field] = value
            this.formData = newFormData
            this._checkForm()
            this._dispatchFormData()
        }
    }

    _checkForm() {
        const allRequiredFieldsAnswered = this._checkRequiredFields()
        if (allRequiredFieldsAnswered) {
            this.setAttribute('complete', '')
            this._dispatchFormStatus(true)
        } else {
            this.removeAttribute('complete')
            this._dispatchFormStatus(false)
        }
    }

    _dispatchFormStatus(complete) {
        const valueChangeEvent = new CustomEvent('form-complete', {
            detail: {
                complete,
            },
        })
        this.dispatchEvent(valueChangeEvent)
    }

    _dispatchFormData() {
        const formDataEvent = new CustomEvent('form-data', {
            detail: {
                formdata: this.formData,
            },
        })
        this.dispatchEvent(formDataEvent)
    }

    _getRequiredFields() {
        const requiredList = []
        const formItems = this.shadowRoot.querySelectorAll('ht-pandora-form-item')
        for (let i = 0; i < formItems.length; i++) {
            if (
                formItems[i].getAttribute('required') !== null
                && !formItems[i].hasAttribute('hidden')
            ) {
                requiredList.push(formItems[i].getAttribute('fieldid'))
            }
        }
        return requiredList
    }

    _checkRequiredFields() {
        const requiredFields = this._getRequiredFields()
        for (let i = 0; i < requiredFields.length; i++) {
            if (
                typeof this.formData[requiredFields[i]] === 'undefined'
                || this.formData[requiredFields[i]] === null
                || this.formData[requiredFields[i]] === ''
            ) {
                return false
            }
        }
        return true
    }

    _getFormattedValue(value, type) {
        switch (type) {
            case 'date':
                return value.replace('T00:00:00.000Z', '')
            case 'checkbox':
                return value === 1 || value === true || value === 'true'
            case 'price':
                return value / 100
            case 'price_report':
                return value
                    .replace(/Contribution/, 'Call-out Fee')
                    .replace(/Addons selected:\n/, '')
            default:
                return value
        }
    }

    _getSetupValue(formItem, formData) {
        const field = formItem.key

        if (typeof formData[field] !== 'undefined' && formData[field] !== null) {
            return this._getFormattedValue(formData[field], formItem.type)
        } else if (
            this.item
            && typeof this.item[field] !== 'undefined'
            && this.item[field] !== null
        ) {
            return this._getFormattedValue(this.item[field], formItem.type)
        } else if (typeof formItem.defaultValue !== 'undefined') {
            return this._getFormattedValue(formItem.defaultValue, formItem.type)
        }
        return ''
    }

    _getOriginalValue(field, type, defaultValue) {
        if (this.item && typeof this.item[field] !== 'undefined' && this.item[field] !== null) {
            return this._getFormattedValue(this.item[field], type)
        }

        return ''
    }

    _getFieldHistory(key) {
        if (key !== 'id' && this.history && this.history.length > 0) {
            let lastValue = ''
            const historyArray = []

            const wantedHistory = []
            this.history.forEach((item) => {
                if (lastValue !== item[key]) {
                    lastValue = item[key]
                    wantedHistory.push(item)
                }
            })

            wantedHistory.forEach((item) => {
                historyArray.push({
                    author: item.last_modified_by ? item.last_modified_by : item.author,
                    value: item[key],
                    date: item.date_modified,
                    current: false,
                })
            })

            if (historyArray.length > 0) {
                const lastHistoryItem = historyArray[historyArray.length - 1]
                if (lastHistoryItem.value !== this.item[key]) {
                    historyArray.push({
                        value: this.item[key],
                        author: this.item.last_modified_by,
                        date: this.item.date_modified,
                        current: true,
                    })
                } else if (historyArray.length === 1) {
                    return []
                } else {
                    historyArray[historyArray.length - 1].current = true
                }
                return historyArray.slice().reverse()
            }

            return []
        }

        return []
    }

    _resetFormWrapper(settingFormData = {}) {
        this.formData = settingFormData
        this.complete = false
        const checkBoxes = this.shadowRoot.querySelectorAll('ht-pandora-form-item[type="checkbox"]')
        checkBoxes.forEach((box) => {
            box._processValue()
        })
        this._checkForm()
    }

    updated(changedProps) {
        if (changedProps.get('item')) {
            // if a new item has been selected remove all form data information
            this._getFormItem()
            this._dispatchFormData()
        }
        this._checkForm()
    }

    _isShowValueIsValid(item, value) {
        if (Array.isArray(value)) {
            if (value.indexOf(this.formData[item.show_field]) === -1) return false // don't render form item
        }

        if (typeof item.show_field_exists === 'string' && !this.item[item.show_field_exists]) {
            return false
        }

        if (!Array.isArray(value) && this.formData[item.show_field]) {
            if (this.formData[item.show_field] !== value) return false // don't render form item
        } else if (!Array.isArray(value) && this.item[item.show_field] !== value) {
            return false // don't render form item
        }

        return true
    }

    _getFormItem() {
        const fieldItem = {}
        this.fields.forEach((element) => {
            if (this.item[element.key]) fieldItem[element.key] = this.item[element.key]
        })
        this.formData = fieldItem
    }

    getAllFormData() {
        const formItems = this.shadowRoot.querySelectorAll('ht-pandora-form-item')
        const formDataWanted = {}
        formItems.forEach((element) => {
            formDataWanted[element.getAttribute('fieldid')] = element._getTypedValue()
        })
        return formDataWanted
    }

    _renderFormItem(item) {
        const shouldShow = this._isShowValueIsValid(item, item.show_field_value);
        // disable smart cover packages editing
        const regex = /Smart\s?Cover/;
        const disabledCOF = this.item?.package?.name && regex.test(this.item.package.name) && item?.label === 'Call-out fee';
       
        // smart cover call out fees will differ from original options
        const contributionValues = OPTIONS.CONTRIBUTION.map(cont => cont.value);
        const includedFee = contributionValues.includes(`${this.item.contribution}`);
        if (item.key === 'contribution' && !includedFee && this.item.contribution !== undefined) {
            item.options = [{label: `${this.item.contribution / 100}`, value: `${this.item.contribution}` }];
        }
        return html`
            <ht-pandora-form-item
                ?hidden="${!shouldShow}"
                @value-change="${this._updateFormObject}"
                fieldid="${item.key}"
                type="${item.type}"
                name="${item.key}"
                label="${item.label}"
                checkedlabel="${item.checkedLabel}"
                uncheckedlabel="${item.uncheckedLabel}"
                ?required="${item.required}"
                pattern="${item.pattern ? item.pattern : ''}"
                errormessage="${item.errorMessage
        ? item.errorMessage
        : `"${item.label}" is not the correct pattern`}"
                missingmessage="${item.missingMessage
        ? item.missingMessage
        : `"${item.label}" is required`}"
                options=${item.options ? JSON.stringify(item.options) : '[]'}
                ?forcecheck="${this.forceCheck}"
                width="${item.width}"
                ?disabled="${item.disabled}"
                value="${this._getSetupValue(item, this.formData)}"
                originalvalue="${this._getOriginalValue(item.key, item.type, item.defaultValue)}"
                autocomplete="${item.autoComplete ? item.autoComplete : ''}"
                ?forceDisabled="${this.forceDisabled || disabledCOF}"
                history="${JSON.stringify(this._getFieldHistory(item.key))}"
                linkeditem="${this.item && item.linkedItem && this.item[item.linkedItem]
        ? JSON.stringify(this.item[item.linkedItem])
        : null}"
                linkedfields="${item.linkedItemFieldsReference
        ? JSON.stringify(QUICK_FIELDS[item.linkedItemFieldsReference])
        : null}"
                mode="${item.mode ? item.mode : ''}"
                documentation="${item.documentation ? item.documentation : ''}"
                min="${item.min}"
                max="${item.max}"
            ></ht-pandora-form-item>
        `
    }

    static get styles() {
        return css`
            ${htPandoraFormWrapperStyles}
        `
    }

    render() {
        return html`
            ${this.fields ? this.fields.map(item => html`${this._renderFormItem(item)}`) : ''}
        `
    }
}

window.customElements.define('ht-pandora-form-wrapper', htPandoraFormWrapper)
