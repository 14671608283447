import { config } from '../../functions/config';
import { ROUTES } from '../routes';
import { getFieldsItem, getFields } from '../../functions/fieldProcessing';

export const FIELDS = {
    "product_id": {
        key: 'product_id',
        label: 'Product',
        type: 'product_id',
        required: true,
        disabled: false,
        width: '12',
        linkedItem: 'product',
        linkedItemFieldsReference: 'PRODUCT'
    },
    "contract_id": {
        key: 'contract_id',
        label: 'Contract',
        type: 'contract_id',
        required: false,
        disabled: false,
        width: '12',
        linkedItem: 'contract',
        linkedItemFieldsReference: 'CONTRACT',
        linkUrl: ROUTES.CONTRACT
    },
    "quantity": {
        key: 'quantity',
        label: 'Quantity',
        type: 'number',
        required: true,
        disabled: false,
        width: '12'
    }
};

export const ITEM = getFieldsItem([
    {
        section_name: "General",
        hideSectionName: true,
        fields: [
            FIELDS.product_id,
            FIELDS.contract_id,
            FIELDS.quantity
        ]
    }]);

export const QUICK_FIELDS = getFields([
    FIELDS.product_id,
    FIELDS.contract_id,
    FIELDS.quantity
]);

export const TITLE_FIELDS = [];

export const PAGE = {
    'item-url': ROUTES.PRODUCT_TO_CONTRACT,
    'new-url': ROUTES.PRODUCT_TO_CONTRACT_NEW,
    'endpoint': config.productAPI,
    'item-view': ITEM,
    'quick-fields': QUICK_FIELDS,
    'type': 'product_to_contract',
    'item-page': {
        'action': '/contracts/attachments',
        'expansions': 'product,contract'
    },
    'new-page': {
        'title': 'New Contract',
        'action': '/contracts/attachments/create'
    }
};