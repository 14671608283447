import { LitElement, html, css } from 'lit-element';

// These are the shared styles needed by this element.
import { htPandoraEditableStyles } from './styles/ht-pandora-editable-styles';
import { ROUTES } from '../constants/routes';
import { UPDATE_WARNINGS } from '../constants/updateWarnings';
import { config } from '../functions/config';
import { postDataFactory } from '../functions/postDataFactory';
import { createNotificationObj } from '../functions/notificationFactory';
import { changePage } from '../functions/changePage';
import { getFieldSectionLinks } from '../functions/fieldSectionLink';
import { FIELDS } from '../constants/fields';
import { getDataFactory } from '../functions/getDataFactory';

import { connect } from 'pwa-helpers';
import { store } from '../store.js';
import {
    addNotification
} from '../actions/app.js';

import './htPandoraFormWrapper';
import './htPandoraTableList';

class htPandoraEditableRenewalChange extends connect(store)(LitElement) {
    static get properties() {
        return {
            renewal: { type: Object },
            fields: { type: Array },
            _selectedRenewal: { type: Object },
            _searching: { type: Boolean },
            _addons: { type: Array },
            _versions: { type: Array },
            _selectedVersion: { type: Object }
        };
    }

    constructor() {
        super();
        this.postData = postDataFactory({ fetch: window.fetch, apiEndPoint: config.renewalAPI });
        this.getData = getDataFactory({ fetch: window.fetch, apiEndPoint: config.renewalAPI });
        this._searching = false;
        this._addons = [];
        this._versions = [];
        this._selectedRenewal = {};
        this._selectedVersion = null;
    }

    firstUpdated() {
        this._selectedRenewal = this.renewal;
        this._searching = true;
        const getControllerData = getDataFactory({ fetch: window.fetch, apiEndPoint: config.controllerAPI })();
        const getProductsData = getDataFactory({ fetch: window.fetch, apiEndPoint: config.productAPI })();
        const getPricebookEntryData = getDataFactory({ fetch: window.fetch, apiEndPoint: `${config.priceBookAPI}/entries` })();

        return Promise.all([ getControllerData, getProductsData, getPricebookEntryData].map(promise => promise.then(response => response.json())))
        .then(returnValues => {
            const controllerData = returnValues[0].data[0];
            const productsData = returnValues[1].data;
            const pricebookEntryData = returnValues[2].data;

            const productPriceEntries = pricebookEntryData.filter(entry => entry.active && entry.product_id !== null && entry.pricebook_id === controllerData.default_pricebook_id);
            const productPriceEntryIds = productPriceEntries.map(entry => entry.product_id);
            const addonsAvailable = productsData.filter(product => productPriceEntryIds.includes(product.id)).map(product => {
                return {...productPriceEntries.find(entry => entry.product_id === product.id), ...product};
            });

            this._addons = addonsAvailable ? addonsAvailable : [];
            this._searching = false;
        }).catch(err => {
            this._searching = false;
        });
    }

    _stateChanged(state) {
    }

    _submitRenewalEdit() {
        if(this._selectedVersion) {
            const selectionCopy = { ...this._selectedVersion };

            const addons = [];

            Object.keys(selectionCopy).map(item => {
                if(item.includes('product_to_renewal-')) {
                    addons.push({
                        id: parseInt(item.substring(19)),
                        quantity: selectionCopy[item] ? selectionCopy[item] : 0
                    });

                    delete selectionCopy[item];
                }
            });

            selectionCopy.addons = addons;

            const confirmWarning = confirm(`Are you sure you want to modify this renewal?`);
            if(!confirmWarning) {
                return;
            }

            const confirmEmail = confirm(`Do you want to automatically send an email with the update to the customer?`);
            if(confirmEmail) {
                selectionCopy.sendEmail = true;
            }

            const sendUpdate = new CustomEvent('send-update', {
                detail: {
                    changes: selectionCopy,
                    table: 'renewal',
                    post: this.postData,
                    element: this
                }
            });
            this.dispatchEvent(sendUpdate);
        }
    }

    _sendLoadingRequest(loading) {
        const updateLoading = new CustomEvent('loading', {
            detail: {
                loading
            }
        });
        this.dispatchEvent(updateLoading);
    }

    shouldUpdate() {
        if(this.renewal !== null) return true;
        return false;
    }

    _cancelEdits() {
        this.shadowRoot.querySelector('ht-pandora-form-wrapper').setAttribute('formdata', '{}');
    }

    _cancelRenewalEdit() {
        this._versions = [];
        const closeEdit = new CustomEvent('close-edit', {
            detail: {
                element: this
            }
        });
        this.dispatchEvent(closeEdit);
    }

    _getFields(basicFields, addons, additionalFields = []) {
        const addonFields = addons.map(addon => {
            return {
                key: `product_to_renewal-${addon.id}`,
                label: addon.name,
                type: 'number',
                required: false,
                disabled: false,
                width: '3',
                min: 0,
                max: addon.limit_per_contract
            };
        });

        return [...additionalFields, ...basicFields, ...addonFields];
    }

    _getRenewalObject(renewal, addons) {
        const addonObject = this._getRenewalObjectWithAddons(renewal, addons);
        return this._getRenewalObjectWithPriceBreakdown(addonObject);
    }

    _getRenewalObjectWithAddons(renewal, addons) {
        if(addons.length === 0) return renewal;
        const newRenewalObject = {...renewal};
        addons.forEach(addon => {
            if(newRenewalObject[`product_to_renewal-${addon.id}`] === null) {
                newRenewalObject[`product_to_renewal-${addon.id}`] = 0;
            }
        });

        if(newRenewalObject.relations && newRenewalObject.relations.product_torenewal) {
            newRenewalObject.relations.product_torenewal.forEach(product => {
                newRenewalObject[`product_to_renewal-${product.product_id}`] = parseInt(product.quantity);
            });
        }

        return newRenewalObject;
    }

    _getRenewalObjectWithPriceBreakdown(renewal) {
        renewal.last_quoted_price_monthly = renewal.last_quoted_price;
        renewal.last_quoted_price_yearly = (renewal.last_quoted_price * 12).toFixed(2);
        renewal.last_quoted_price_quarterly = (renewal.last_quoted_price * 3).toFixed(2);
        return renewal;
    }

    _updateSelectedVersion(e) {
        this._selectedVersion = { ...e.detail.value, id: this.renewal.id };
    }

    _searchNewPrice() {
        this._searching = true;
        const searchData = this.shadowRoot.querySelector('ht-pandora-form-wrapper').getAllFormData();
        let requestUrl = `/price?contractId=${this.renewal.contract_id}&numberOfClaims=${searchData.claims_accounted_for || this.renewal.claims_accounted_for}&flatDiscount=${searchData.custom_renewal_discount}&selection=`;

        const addonProucts = [];

        Object.keys(searchData).forEach(item => {
            if(item.includes('product_to_renewal-')) {
                addonProucts.push({id: parseInt(item.substring(19)), quantity: searchData[item] ? parseInt(searchData[item]) : 0});
            }
        });

        const selection = {
            products: addonProucts,
            package_id: typeof searchData.package_id !== "undefined" ? searchData.package_id : this.renewal.package_id,
            contribution: typeof searchData.contribution !== "undefined" ? searchData.contribution : this.renewal.contribution,
            contractType: typeof searchData.type !== "undefined" ? searchData.type : this.renewal.type,
            billingType: typeof searchData.billing_type !== "undefined" ? searchData.billing_type : this.renewal.billing_type
        }

        const getPriceData = this.getData(`${requestUrl}${btoa(JSON.stringify(selection))}`);

        getPriceData.then(response => response.json()).then((data) => {
            if (data.data.errorMessage) {
                store.dispatch(addNotification(createNotificationObj(`${data.data.errorMessage}`, 'error', true, false)));
                this._searching = false;
            } else {
                searchData.last_quoted_price = data.data.pricePerMonth;
                this._versions.push(this._getRenewalObjectWithPriceBreakdown(searchData));
                this._selectedRenewal = searchData;
                this._searching = false;
            }
        }).catch((error) => {
            store.dispatch(addNotification(createNotificationObj(error, 'error', true, false)));
            this._searching = false;
        });
    }

    _getPriceBreakdownTableHeaders() {
        const renewalPriceMonthly = {...FIELDS.RENEWAL.last_quoted_price};
        const renewalPriceYearly = {...FIELDS.RENEWAL.last_quoted_price};
        const renewalPriceQuarterly = {...FIELDS.RENEWAL.last_quoted_price};

        renewalPriceMonthly.key = 'last_quoted_price_monthly';
        renewalPriceYearly.key = 'last_quoted_price_yearly';
        renewalPriceQuarterly.key = 'last_quoted_price_quarterly';

        renewalPriceMonthly.label = 'Monthly Price';
        renewalPriceYearly.label = 'Yearly Price';
        renewalPriceQuarterly.label = 'Quarterly Price';

        return [renewalPriceMonthly, renewalPriceQuarterly, renewalPriceYearly];
    }

    static get styles() {
        return css`${htPandoraEditableStyles}`;
    }

    render() {
        return html`
            <div class="form-section-title">
                <h2>Updating Renewal:${this.renewal.contract && this.renewal.contract.customer_facing_id ? ` ${this.renewal.contract.customer_facing_id}` : ''}${this.renewal.account && this.renewal.account.first_name ? ` - ${this.renewal.account.first_name} ${this.renewal.account.last_name }` : ''}</h2>
            </div>
            <form @submit="${e => e.preventDefault()}">
                <div class="section">
                    <ht-pandora-form-wrapper
                        fields="${JSON.stringify(this._getFields(this.fields, this._addons))}"
                        item="${JSON.stringify(this._getRenewalObject(this._selectedRenewal, this._addons))}"
                    ></ht-pandora-form-wrapper>
                    <div class="buttons">
                        <button class="ht-button" type="button" @click="${() => this._searchNewPrice()}">Search</button>
                    </div>
                    <ht-pandora-loading ?active="${this._searching}"></ht-pandora-loading>
                </div>

                <div class="section">
                    <h3>Current Renewal Details</h3>
                    <ht-pandora-table-list
                        list="${JSON.stringify([this._getRenewalObject(this.renewal, this._addons)])}"
                        tableheaders="${JSON.stringify(this._getFields(this.fields, this._addons, this._getPriceBreakdownTableHeaders()))}"
                        readonly
                        slimheaders
                        pagesize="100"
                    ></ht-pandora-table-list>
                </div>

                <div class="section">
                    <h3>Versions</h3>
                    ${this._versions.length > 0 ? html`
                        <p>Please select the version you want to change to</p>
                        <ht-pandora-table-list
                            list="${JSON.stringify(this._versions)}"
                            tableheaders="${JSON.stringify(this._getFields(this.fields, this._addons, this._getPriceBreakdownTableHeaders()))}"
                            @select-item="${this._updateSelectedVersion}"
                            isListSelector
                            slimheaders
                            pagesize="100"
                        ></ht-pandora-table-list>
                    ` : 'No versions availabe. Please search above.'}
                </div>


                <div class="buttons">
                    <button class="ht-button ht-button-secondary" type="button" @click="${() => this._cancelRenewalEdit()}">Back</button>
                    <button ?disabled="${this._selectedVersion === null}" class="ht-button" type="button" @click="${() => this._submitRenewalEdit()}">Submit</button>
                </div>
            </form>
        `;
    }
}

window.customElements.define('ht-pandora-editable-renewal-change', htPandoraEditableRenewalChange);
