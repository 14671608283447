import { config } from '../../functions/config'
import { ROUTES } from '../routes'
import { getFieldsItem, getFields } from '../../functions/fieldProcessing'

export const FIELDS = {
    name: {
        key: 'name',
        label: 'Name',
        type: 'text',
        required: true,
        disabled: false,
        width: '5',
    },
    description: {
        key: 'description',
        label: 'Description',
        type: 'textarea',
        required: true,
        disabled: false,
        width: '12',
    },
    featured: {
        key: 'featured',
        label: 'Featured',
        checkedLabel: 'Featured',
        uncheckedLabel: 'Not Featured',
        type: 'checkbox',
        required: false,
        disabled: false,
        width: '3',
    },
    website: {
        key: 'website',
        label: 'Website',
        checkedLabel: 'Shows on Website',
        uncheckedLabel: 'Hidden from Website',
        type: 'checkbox',
        required: false,
        disabled: false,
        width: '3',
    },
    position: {
        key: 'position',
        label: 'Position',
        type: 'number',
        required: false,
        disabled: false,
        width: '3',
    },
    landlord_only: {
        key: 'landlord_only',
        label: 'Landlord Only',
        checkedLabel: 'Is Landlord Only',
        uncheckedLabel: 'Is not Landlord Only',
        type: 'checkbox',
        required: false,
        disabled: false,
        width: '3',
    },
    developer_name: {
        key: 'developer_name',
        label: 'Developer Name',
        type: 'text',
        required: false,
        width: '4',
    },
    limit_per_contract: {
        key: 'limit_per_contract',
        label: 'Limit Per Contract',
        type: 'number',
        required: false,
        width: '4',
    },
}

export const ITEM = getFieldsItem([
    {
        section_name: 'General',
        hideSectionName: true,
        fields: [
            FIELDS.name,
            FIELDS.description,
            FIELDS.featured,
            FIELDS.website,
            FIELDS.position,
            FIELDS.landlord_only,
            FIELDS.developer_name,
            FIELDS.limit_per_contract,
        ],
    },
])

export const QUICK_FIELDS = getFields([
    FIELDS.name,
    FIELDS.description,
    FIELDS.featured,
    FIELDS.website,
    FIELDS.position,
    FIELDS.landlord_only,
    FIELDS.developer_name,
    FIELDS.limit_per_contract,
])
export const TITLE_FIELDS = [FIELDS.name]

export const PAGE = {
    'list-url': ROUTES.PRODUCTS,
    'item-url': ROUTES.PRODUCT,
    'new-url': ROUTES.PRODUCT_NEW,
    endpoint: config.productAPI,
    'item-view': ITEM,
    'quick-fields': QUICK_FIELDS,
    type: 'product',
    'list-page': {
        title: 'Products',
        showAcitveFlag: true,
    },
    'item-page': {
        expansions: 'packages',
    },
    'new-page': {
        title: 'New Product',
        action: '/create',
    },
}
