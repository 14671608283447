import { css } from 'lit-element';
    export const htPandoraFormSearchItemStyles = css `.icon{
  height:2.4rem;
  width:2.4rem; }

.icon .icon--stroke{
  fill:none; }

.icon .icon--fill{
  stroke:none; }

.icon,
.icon circle,
.icon line,
.icon g,
.icon path{
  -webkit-transition:stroke .4s, fill .4s;
  transition:stroke .4s, fill .4s; }

.icon--small{
  height:1.6rem;
  width:1.6rem; }

.icon--medium{
  height:2rem;
  width:2rem; }

.icon--large{
  height:3.2rem;
  width:3.2rem; }

.icon--xlarge{
  height:4.2rem;
  width:4.2rem; }
main{
  display:block; }

.main-content{
  min-height:100vh;
  background:#17171c;
  overflow:visible; }
  .main-content--is-loading{
    visibility:hidden; }

.page{
  display:none;
  height:auto;
  padding:14px 10px; }
  .page__profile{
    padding:0; }

.page[active]{
  display:block; }

button,
svg{
  outline:none; }
  button:active,
  svg:active{
    color:#ffbd00;
    fill:#ffbd00;
    stroke:#ffbd00; }
  @media (min-width: 768px){
    button:focus,
    svg:focus{
      color:#ffbd00;
      fill:#ffbd00;
      stroke:#ffbd00; } }

.floating-wrapper{
  -webkit-box-shadow:0 0 5px 0 rgba(0, 0, 0, 0.5);
          box-shadow:0 0 5px 0 rgba(0, 0, 0, 0.5);
  overflow:auto;
  background-color:#fff;
  position:relative;
  height:100%; }
  .floating-wrapper-form{
    background-color:#fafafa;
    position:relative; }

@media (min-width: 1200px){
  .page{
    padding:20px 30px; }
  .floating-wrapper{
    max-width:calc(100vw - 170px);
    margin-left:-170px; } }
html{
  font-size:62.5%; }

html,
*,
*:before,
*:after{
  -webkit-box-sizing:border-box;
          box-sizing:border-box; }

html{
  -webkit-text-size-adjust:100%;
     -moz-text-size-adjust:100%;
      -ms-text-size-adjust:100%;
          text-size-adjust:100%; }

body{
  font-family:"Hometree", "Arial Black", "sans serif";
  font-size:1.6rem;
  color:#fff;
  font-weight:400;
  text-rendering:optimizelegibility;
  -webkit-font-smoothing:antialiased;
  -moz-font-smoothing:antialiased; }

h1{
  font-size:3.2rem;
  line-height:4rem; }

p{
  font-family:"Hometree", "Arial Black", "sans serif";
  font-size:1.6rem;
  margin-top:1.6rem;
  margin-bottom:0; }

input, p, a, span, li, div, textarea, select{
  font-family:"Hometree", "Arial Black", "sans serif";
  font-size:1.6rem;
  line-height:2.4rem; }
h1,
h2,
h3,
h4,
h5,
h6,
.alpha,
.beta,
.gamma,
.delta,
.epsilon{
  margin:0;
  font-weight:500;
  margin-bottom:0; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small,
  .alpha small,
  .beta small,
  .gamma small,
  .delta small,
  .epsilon small{
    font-weight:normal; }
.text-centre,
.text-center{
  text-align:center; }

.text-left{
  text-align:left; }

.text-right{
  text-align:right; }

.loader{
  position:relative; }
  .loader:after{
    content:'';
    position:absolute;
    top:calc(50% - 2rem);
    left:calc(50% - 2rem);
    border-radius:100%;
    border-style:solid;
    border-width:.3rem;
    width:4rem;
    height:4rem;
    border-color:transparent #ffbd00 #ffbd00 transparent;
    -webkit-animation:spin 1s linear infinite;
            animation:spin 1s linear infinite; }
  .loader--fullscreen{
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background-color:rgba(255, 255, 255, 0.9);
    z-index:2; }

@-webkit-keyframes spin{
  from{
    -webkit-transform:rotate(0deg);
            transform:rotate(0deg); }
  to{
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg); } }

@keyframes spin{
  from{
    -webkit-transform:rotate(0deg);
            transform:rotate(0deg); }
  to{
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg); } }

:host{
  display:block;
  margin-bottom:22px;
  min-width:200px; }

:host([error]) .ht-form-input,
:host([missing]) .ht-form-input{
  background-color:#fff3f3;
  border-color:#a80000; }

:host([error]) .ht-form-label,
:host([missing]) .ht-form-label{
  color:#a80000; }

:host([error]) .ht-form-input-error-message{
  font-size:1.2rem;
  line-height:2.2rem;
  opacity:1; }

:host([missing]) .ht-form-input-missing-message{
  font-size:1.2rem;
  line-height:2.2rem;
  opacity:1; }

.ht-form-label{
  display:block;
  font-family:"Hometree", "Arial Black", "sans serif";
  margin-bottom:4px;
  text-transform:uppercase;
  -webkit-transition:color 0.4s;
  transition:color 0.4s; }

.ht-form-input{
  background-clip:padding-box;
  background-color:#fff;
  border:1px solid #ededed;
  border-radius:4px;
  -webkit-box-sizing:border-box;
          box-sizing:border-box;
  font-size:1.6rem;
  outline:none;
  padding:10px;
  -webkit-transition:background-color 0.4s, border-color 0.4s, left 0.4s;
  transition:background-color 0.4s, border-color 0.4s, left 0.4s;
  width:100%;
  position:relative;
  left:0;
  height:46px; }
  .ht-form-input-textarea{
    resize:vertical; }
  .ht-form-input.hidden{
    display:none; }
  .ht-form-input-error{
    color:#a80000;
    font-size:0;
    line-height:0rem;
    margin:0;
    opacity:0;
    -webkit-transition:font-size 0.4s, opacity 0.4s, line-height 0.4s;
    transition:font-size 0.4s, opacity 0.4s, line-height 0.4s; }
  .ht-form-input:focus, .ht-form-input--select:focus{
    border:1px solid #ffbd00; }

.ht-checkbox{
  display:none; }
  .ht-checkbox-label{
    cursor:pointer;
    margin-bottom:0;
    border-radius:4px;
    background-color:#fff;
    -webkit-transition:background 0.4s;
    transition:background 0.4s;
    padding:10px;
    display:-webkit-inline-box;
    display:-webkit-inline-flex;
    display:-ms-inline-flexbox;
    display:inline-flex;
    width:100%;
    min-height:46px;
    border:1px solid #ededed; }
    .ht-checkbox-label.ht-checkbox-label-on{
      background-color:#ffbd00; }
    .ht-checkbox-label .icon{
      margin-right:8px; }
      .ht-checkbox-label .icon--tick{
        stroke:#111; }
      .ht-checkbox-label .icon--cross{
        stroke:#ffbd00; }
  .ht-checkbox-marked-element{
    width:calc(100% - 30px); }
    .ht-checkbox-marked-element p{
      margin-top:0; }
  .ht-checkbox-text{
    display:inline-block;
    font-size:1.2rem;
    margin-left:7px;
    vertical-align:middle; }

.ht-form-input[disabled]{
  background-color:transparent;
  border-color:transparent; }

.ht-form-input[readonly]{
  background-color:#fafafa; }

.ht-select{
  -webkit-appearance:none;
  height:46px;
  width:100%; }

.ht-form-input.ht-form-input-textarea{
  height:auto; }

.ht-package-filter{
  max-width:22em; }

@media (min-width: 768px){
  .ht-form-input{
    font-size:1.8rem; }
  :host([width='1']){
    width:8.33%; }
  :host([width='2']){
    width:16.66%; }
  :host([width='3']){
    width:25%; }
  :host([width='4']){
    width:33.33%; }
  :host([width='5']){
    width:41.66%; }
  :host([width='6']){
    width:50%; }
  :host([width='7']){
    width:58.33%; }
  :host([width='8']){
    width:66.66%; }
  :host([width='9']){
    width:75%; }
  :host([width='10']){
    width:83.33%; }
  :host([width='11']){
    width:91.66%; }
  :host([width='12']){
    width:100%; } }

.results{
  color:#363b44;
  list-style:none;
  padding:0;
  margin:0; }

.result-item{
  background:#dbdbdb;
  padding:10px;
  cursor:pointer;
  border-bottom:1px solid #363b44; }
  .result-item:last-of-type{
    border-bottom:none; }
`;
