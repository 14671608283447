import { LitElement, html, css } from 'lit-element';

import { htPandoraLoadingStyles } from './styles/ht-pandora-loading-styles';

class htPandoraLoading extends LitElement {
    static get properties() {
        return {
            active: { type: Boolean }
        };
    }

    static get styles() {
        return css`${htPandoraLoadingStyles}`;
    }

    render() {
        return html`
            <div class="ht-pandora-loading ${(this.active === true ? 'active' : '')}">
                <div class="loader"></div>
            </div>
        `;
    }
}

window.customElements.define('ht-pandora-loading', htPandoraLoading);
