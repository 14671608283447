export const OPTIONS = {
    CONTRACT_STATUS: {
        CANCELLED: 8,
        ABANDONED_CLOSED: 7,
        EXPIRED: 6,
        ABANDONED: 5,
        LIVE: 4,
        FUTURE: 3,
        SIGNATURE_REQUIRED: 2,
        WEBSITE_PENDING: 1,
        EXPIRED_DUE_TO_PAYMENT_ISSUES: 10,
        LAPSED: 11,
        UNCONTACTABLE: 12,
        PENDING: 14,
    },
    RENEWAL_STATUS: {
        PENDING_PAYMENT_MISSING: 7,
        PENDING_MANDATE_FAILED: 6,
        PENDING_MANDATE_REQUIRED: 5,
        DRAFT: 4,
        RENEWED: 3,
        CANCELLED: 2,
        PENDING: 1,
    },
    CONTRIBUTION: [
        {
            label: '0',
            value: '0',
        },
        {
            label: '60',
            value: '6000',
        },
        {
            label: '95',
            value: '9500',
        },
    ],
    CANCEL_GOCARDLESS: [
        {
            label: 'Yes',
            value: 'true',
        },
        {
            label: 'No',
            value: 'false',
        },
    ],
    CANCEL_CHARGEBEE: [
        {
            label: 'Yes',
            value: 'true',
        },
        {
            label: 'No',
            value: 'false',
        },
    ],
}
