import { PAGE as account_to_property } from '../constants/tables/account_to_property';
import { PAGE as account } from '../constants/tables/account';
import { PAGE as contract } from '../constants/tables/contract';
import { PAGE as controller } from '../constants/tables/controller';
import { PAGE as note } from '../constants/tables/note';
import { PAGE as product_package } from '../constants/tables/product_package';
import { PAGE as pricebook_entry } from '../constants/tables/pricebook_entry';
import { PAGE as pricebook } from '../constants/tables/pricebook';
import { PAGE as product_to_contract } from '../constants/tables/product_to_contract';
import { PAGE as product_to_package } from '../constants/tables/product_to_package';
import { PAGE as product_to_promocode } from '../constants/tables/product_to_promocode';
import { PAGE as package_to_promocode } from '../constants/tables/package_to_promocode';
import { PAGE as product } from '../constants/tables/product';
import { PAGE as promocode_family } from '../constants/tables/promocode_family';
import { PAGE as promocode_type } from '../constants/tables/promocode_type';
import { PAGE as promocode } from '../constants/tables/promocode';
import { PAGE as property } from '../constants/tables/property';
import { PAGE as referral } from '../constants/tables/referral';
import { PAGE as renewal } from '../constants/tables/renewal';

export const PAGES = [
    account_to_property,
    account,
    contract,
    controller,
    note,
    product_package,
    pricebook_entry,
    pricebook,
    product_to_contract,
    product_to_package,
    product_to_promocode,
    package_to_promocode,
    product,
    promocode_family,
    promocode_type,
    promocode,
    property,
    referral,
    renewal
];