import { navigate } from '../actions/app';
import { store } from '../store';
import { config } from './config';

export const changePage = (url) => {
    window.history.pushState({}, '', url);
    store.dispatch(navigate(decodeURIComponent(`/${url}`), true));
};

export const changePageToReact = (link, id, env, path = '') => {
    let envPath = '';
    switch (env) {
        case 'dev':
            envPath = 'dev.';
            break;

        case 'stage':
            envPath = 'stage.';
            break;

        default:
            break;
    }

    if (env === 'local') {
        return `http://localhost:3000/${path}${id}`;
    }

    return `https://${link}.pandora.${envPath}hometree.co.uk/${path}${id || ''}`;
};

export const getClaimsLoginPageUrl = () => {
    const environment = config.environment;

    if (environment === 'local') {
        return `http://${environment}host:3000/login`;
    }

    return `https://claims.pandora.${
        environment === 'prod' ? '' : `${environment}.`
    }hometree.co.uk/login`;
};
