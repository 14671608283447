import { LitElement, html, css } from 'lit-element';
import { exportIcon } from './htPandoraIcons';

import { htPandoraNotificationsStyles } from './styles/ht-pandora-notifications-styles';

class htPandoraNotification extends LitElement {
    /**
     * Usage
     *
     * To dispatch and add a notification.
     *
     * this.dispatchEvent(new CustomEvent('ht-pandora-add-notification', createNotificationObj(text, state, persistence, persistOnPageChange)));
     *
     *
     * Where text is a string containing the text of the notification.
     *
     * Where persistence is a Boolean depending if
     * the notification should be persistent or not.
     * If not, the notification disappear after 5 seconds.
     *
     * Where state is either, success, info or error to show
     * differently according to the notification type.
     * [success is green, info is orange and error is red]
     *
     * Where persistOnPageChange is if the notification should remain
     * if the user navigates to a different tab/page or not.
     *
     *
     * This component should be reviewed and add animations and check
     * the weird behaviour of the timeout.
     */

    static get properties() {
        return {
            notificationId: { type: Number },
            persistence: { type: Boolean },
            state: { type: String },
            text: { type: String },
            button: { type: Object }
        };
    }

    constructor() {
        super();
        this.persistence = false;
        this.button = {};
    }

    updated() {
        this._timeOutNotifications();
    }

    _dismissNotifications(notificationId) {
        // TO DO: Find a way to add animations without the weird ass bug.
        this.dispatchEvent(new CustomEvent('remove',
            { detail: { id: notificationId } }));
    }


    _timeOutNotifications() {
        if (this.persistence === false) {
            // TODO: Fix bug that forces the notification to wait 5 seconds b4 new
            setTimeout(() => {
                this.dispatchEvent(new CustomEvent('remove',
                    { detail: { id: notificationId } }));
            }, 5000);
        }
    }

    static get styles() {
        return css`${htPandoraNotificationsStyles}`;
    }

    render() {
        return html`
            <div id=${this.notificationId} class="notifications active notifications--${this.state}">
                <p class="notifications__text">${this.text}</p>
                ${this.button && this.button.text ? html`<a @click="${() => this._dismissNotifications(this.notificationId)}" href="${this.button.url}" class="ht-button ht-button-white-transparent">${this.button.text}</a>` : ''}
                <div class="notifications__dismiss-icon" @click="${() => this._dismissNotifications(this.notificationId)}">${exportIcon('cross', 'small')}</div>
            </div>
        `;
    }
}

window.customElements.define('ht-pandora-notification', htPandoraNotification);
