export const FIELDS = {
    is_read_only: {
        key: 'relations',
        label: 'Read only',
        type: 'is_read_only',
        required: false,
        disabled: true,
    },
    active: {
        key: 'active',
        label: 'Active',
        checkedLabel: 'Active',
        uncheckedLabel: 'Inactive',
        type: 'checkbox',
        required: false,
        headerRequired: true,
        disabled: false,
        width: '12',
        mode: 'active',
        defaultValue: true,
    },
    date_created: {
        key: 'date_created',
        label: 'Created Date',
        type: 'date',
        required: false,
        headerRequired: true,
        disabled: true,
        width: '3',
        hideInForms: true,
    },
    date_modified: {
        key: 'date_modified',
        label: 'Modified Date',
        type: 'date',
        required: false,
        headerRequired: true,
        disabled: true,
        width: '3',
        hideInForms: true,
    },
    author: {
        key: 'author',
        label: 'Author',
        type: 'text',
        required: false,
        headerRequired: true,
        disabled: true,
        width: '3',
        hideInForms: true,
    },
};

export const CONSTANT_FIELDS = {
    PRE_TABLE: [FIELDS.active, FIELDS.is_read_only],
    POST_TABLE: [
        // FIELDS.date_created,
        FIELDS.date_modified,
        FIELDS.author,
        FIELDS.is_read_only,
    ],
};

export const CONSTANT_FIELDS_ITEM = {
    PRE_TABLE: [
        {
            formWrapperType: 'dark',
            formSectionStyle: 'active',
            hideTab: true,
            fields: [FIELDS.active, FIELDS.is_read_only],
        },
    ],
    POST_TABLE: [
        {
            section_name: 'Post Items',
            hideTab: true,
            fields: [FIELDS.date_created, FIELDS.date_modified, FIELDS.author],
        },
    ],
};
