import { LitElement, css, html } from 'lit-element';

/* Styles */
import { htPandoraFormSearchItemStyles } from './styles/ht-pandora-form-search-item-styles';

/* Functions */

import './htPandoraSearch';
import './htPandoraTableList';

class htPandoraFormSearchItem extends LitElement {
    static get properties() {
        return {
            type: { type: String },
            fields: { type: Array },
            results: { type: Array },
        };
    }

    constructor() {
        super();

        this.type = '';
        this.fields = [];
        this.results = [];
    }

    _addResults(e) {
        this.results = e.detail.value;
    }

    _selectedItem(item) {
        const selectedItemEvent = new CustomEvent('selected-search-item', {
            detail: { value: item },
        });
        this.dispatchEvent(selectedItemEvent);
        this.shadowRoot.querySelector('ht-pandora-search')._dismiss();
    }

    _selectResult(e) {
        this._selectedItem(e.detail.value);
    }

    static get styles() {
        return css`
            ${htPandoraFormSearchItemStyles}
        `;
    }

    render() {
        return html`
            <ht-pandora-search
                focus
                dark
                compact
                @results="${this._addResults}"
                action="${this.type}"
            ></ht-pandora-search>
            ${this.results && this.results.length > 0
                ? html`
                      <ht-pandora-table-list
                          slimheaders
                          list="${JSON.stringify(this.results.map((result) => result.entry))}"
                          tableheaders="${JSON.stringify(this.fields)}"
                          fireeventonclick
                          @select-item="${this._selectResult}"
                          pagesize="100"
                      ></ht-pandora-table-list>
                  `
                : ''}
        `;
    }
}

window.customElements.define('ht-pandora-form-search-item', htPandoraFormSearchItem);
