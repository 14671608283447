import { config } from '../../functions/config';
import { ROUTES } from '../routes';
import { getFieldsItem, getFields } from '../../functions/fieldProcessing';

export const FIELDS = {
    "default_pricebook_id": {
        key: 'default_pricebook_id',
        label: 'Default Pricebook',
        type: 'number', //pricebook_id
        required: true,
        disabled: false,
        width: '12',
        linkedItem: 'pricebook',
        linkedItemFieldsReference: 'PRICEBOOK',
        linkUrl: ROUTES.PRICEBOOK
    }
};

export const ITEM = getFieldsItem([
    {
        section_name: "Default Pricebook",
        hideSectionName: true,
        fields: [
            FIELDS.default_pricebook_id
        ]
    }]);

export const QUICK_FIELDS = getFields([
    FIELDS.default_pricebook_id
]);

export const TITLE_FIELDS = [];

export const PAGE = {
    'item-url': ROUTES.CONTROLLER,
    'endpoint': config.controllerAPI,
    'item-view': ITEM,
    'quick-fields': QUICK_FIELDS,
    'type': 'controller',
    'item-page': {
        'title': 'Controller',
        'action': '',
        'expansions': 'pricebook'
    },
};