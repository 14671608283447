import { config } from '../../functions/config';
import { getFields, getFieldsItem } from '../../functions/fieldProcessing';
import { ROUTES } from '../routes';

export const FIELDS = {
    note: {
        key: 'note',
        label: 'Note',
        type: 'textarea',
        required: true,
        disabled: false,
        width: '12',
    },
    account_id: {
        key: 'account_id',
        label: 'Account',
        type: 'account_id',
        required: true,
        disabled: false,
        width: '12',
        linkedItem: 'account',
        linkedItemFieldsReference: 'ACCOUNT',
        linkUrl: ROUTES.ACCOUNT,
    },
    property_id: {
        key: 'property_id',
        label: 'Property',
        type: 'property_id',
        required: false,
        disabled: false,
        width: '12',
        linkedItem: 'property',
        linkedItemFieldsReference: 'PROPERTY',
        linkUrl: ROUTES.PROPERTY,
    },
    contract_id: {
        key: 'contract_id',
        label: 'Contract',
        type: 'contract_id',
        required: false,
        disabled: false,
        width: '12',
        linkedItem: 'contract',
        linkedItemFieldsReference: 'CONTRACT',
        linkUrl: ROUTES.CONTRACT,
        linkreact: ROUTES.CLAIM_REACT,
    },
};

export const ITEM = getFieldsItem([
    {
        section_name: 'General',
        hideSectionName: true,
        fields: [FIELDS.note],
    },
    {
        section_name: 'Linked Items',
        hideSectionName: true,
        fields: [FIELDS.account_id, FIELDS.property_id, FIELDS.contract_id],
    },
]);

export const QUICK_FIELDS = getFields([
    FIELDS.note,
    FIELDS.account_id,
    FIELDS.property_id,
    FIELDS.contract_id,
]);

export const TITLE_FIELDS = [];

export const PAGE = {
    'list-url': ROUTES.NOTES,
    'item-url': ROUTES.NOTE,
    'new-url': ROUTES.NOTE_NEW,
    endpoint: config.accountAPI,
    'item-view': ITEM,
    'quick-fields': QUICK_FIELDS,
    type: 'note',
    'list-page': {
        action: '/notes',
        expansions: 'property,account,contract',
        title: 'Notes',
        showAcitveFlag: true,
    },
    'item-page': {
        action: '/notes',
        expansions: 'property,account,contract',
    },
    'new-page': {
        title: 'New Note',
        action: '/notes/create',
    },
};
